import { ArrowForward } from '@mui/icons-material';
import { CustomButton } from '@buttons/CustomButton';
import { useHistory } from 'react-router-dom';
import { usePlansStore, usePlansDataStore } from '@store/plansStore';
import DollarIcon from '@assets/images/dollar.svg';
import MedicineIcon from '@assets/images/icono-medical.svg';
import TuercaIcon from '@assets/images/tuerca.svg';

import React, { useEffect } from 'react';
import { useQuoteStore, useSelectedQuoteStore } from '@store/QuoteStore';

import { upCRM } from '@utils/CRM';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { usePolicyStore } from '@store/policyStore';
import { useCPStore } from '@store/cpStore';
import { getTracking } from '@api/getTracking';

import {
  Grid,
  Paper,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Grow,
} from '@mui/material';

import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';

interface PlanProps {
  plan: any;
  showToast?: boolean;
  setShowToast?: Function;
}

export const Plan: React.FC<PlanProps> = ({
  plan,
  showToast,
  setShowToast,
}) => {
  const { breakpoints } = useTheme();
  const { plans } = usePlansStore();
  const { setSelectedPlan } = usePlansDataStore();
  const { setSelectedQuote } = useSelectedQuoteStore();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const history = useHistory();

  const { token } = useTokenStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();

  const { car } = useCarStore();
  const { driver } = useDriverStore();

  useEffect(() => {
    (async () => {
      policy.stage = '1. Registro (Coche)';
      policy.sub_stage = '2. Datos Cotización';
      policy.state_code = 'Borrador';
      policy.status_code = 'En revisión';
      addPolicyInfo({ ...policy });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    /*@ts-ignore*/    
    plans[0].title === '' && setShowToast(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  const personalizar = () => {
    setSelectedPlan(plan);
    setSelectedQuote(plan);
    policy.stage = '1. Registro (Coche)';
    policy.sub_stage = '2. Datos Cotización';
    policy.state_code = 'Borrador';
    policy.status_code = 'En revisión';
    addPolicyInfo({ ...policy });
    let initialData = {
      id: tracking.id,
      business: 'coppel-hdi',
      data: {
        car: car,
        driver: driver,
        selectedQuote: selectedQuote,
        policy: policy,
        cpData: cpData
      },
    };
    getTracking(token, initialData);
    upCRM(
      token,
      car,
      driver,
      selectedQuote,
      policy,
      tracking,
      cpData,
      selectedQuote
    );
    history.push('/insurer-details');
  };

  const { isFetching, quoteList } = useQuoteStore();

  if (quoteList.length === 0 && !isFetching) {
    return (
      <Paper
        elevation={2}
        style={{
          borderRadius: 8,
          marginTop: 25,
          padding: 40,
        }}
      >
        <Box sx={{ color: 'red', fontSize: 20 }}>
          No hay cotizaciones para el Plan seleccionado.
        </Box>
      </Paper>
    );
  }
  return (
    <Grow
      in={true}
      style={{ transformOrigin: '0 0 0' }}
      {...(true ? { timeout: 1000 } : {})}
    >
      <Paper
        elevation={2}
        style={
          (plans.length < 3 && !isTablet) || (plans.length < 2 && isTablet)
            ? {
                borderRadius: 8,
                margin: 5,
                padding: 10,
              }
            : plans.find((i) => i.title === plan.title)
            ? {
                borderRadius: 8,
                margin: 5,
                padding: 10,
              }
            : {
                borderRadius: 8,
                margin: 5,
                padding: 10,
                opacity: '0.5',
              }
        }
      >
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              padding={2}
            >
              {plan.insurance === 'axa' ? (
                <Box
                  component={'img'}
                  src={plan.company_logo}
                  sx={{
                    height: 70,
                    width: 100,
                  }}
                  style={{ objectFit: 'contain' }}
                  alt={plan.title}
                />
              ) : (
                <Box
                  component={'img'}
                  src={plan.company_logo}
                  sx={{
                    height: 32,
                    width: 100,
                  }}
                  style={{ objectFit: 'contain' }}
                  alt={plan.title}
                />
              )}

              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'end'}
                paddingLeft={3}
                marginLeft={2}
                style={{
                  borderLeftStyle: 'dashed',
                  borderLeft: '1px dashed #c7c7c7',
                }}
              >
                <Typography
                  textAlign={'start'}
                  variant={'body2'}
                  fontSize={'13px'}
                  style={{ color: '#757575' }}
                >
                  {'Costo anual:'}
                </Typography>
                <Typography
                  textAlign={'start'}
                  fontWeight={'bold'}
                  fontSize={isMobile ? '20px' : '28px'}
                >
                  $
                  {parseFloat(
                    parseFloat(plan.insurance_premium.total.toString()).toFixed(
                      2
                    )
                  ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Box
              display={'flex'}
              flexDirection={isTablet ? 'column' : 'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexWrap={'wrap'}
              padding={2}
              borderRadius={2}
              style={{ backgroundColor: '#e3f2fd' }}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginTop={1}
                >
                  <Box
                    component="img"
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                    alt={'dollar icon'}
                    src={DollarIcon}
                  />
                  <Typography
                    textAlign={'start'}
                    fontWeight={'400'}
                    fontSize={14}
                    marginLeft={1}
                  >
                    {
                      'Por si ocasionas daños con tu vehículo, tienes $3,000,000'
                    }
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginTop={1}
                >
                  <Box
                    component="img"
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                    alt={'dollar icon'}
                    src={MedicineIcon}
                  />
                  <Typography
                    textAlign={'start'}
                    fontWeight={'400'}
                    fontSize={14}
                    marginLeft={1}
                  >
                    {'Por si visitas al médico por un accidente'}
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  marginTop={1}
                >
                  <Box
                    component="img"
                    sx={{
                      width: '20px',
                      height: '20px',
                    }}
                    alt={'Tuerca icon'}
                    src={TuercaIcon}
                  />
                  <Typography
                    textAlign={'start'}
                    fontWeight={'400'}
                    fontSize={14}
                    marginLeft={1}
                  >
                    {'Por si necesitas ayuda con tu vehículo o apoyo legal'}
                  </Typography>
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={isTablet ? 'row' : 'column'}
                alignItems={'center'}
                marginTop={isTablet ? 2 : 0}
                justifyContent={isTablet ? 'center' : 'space-between'}
                width={isTablet ? '100%' : '40%'}
              >
                <CustomButton
                  size={isTablet ? 'small' : 'medium'}
                  text={'¡LO QUIERO!'}
                  color={'primary'}
                  variant={'contained'}
                  endIcon={<ArrowForward />}
                  style={
                    isTablet
                      ? {
                          textTransform: 'none',
                          width: '220px',
                          height: '40px',
                        }
                      : {
                          textTransform: 'none',
                          width: '187.37px',
                          height: '56px',
                          fontSize: '16px',
                        }
                  }
                  onClick={personalizar}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grow>
  );
};
