import isEmail from 'validator/lib/isEmail';

export const validateEmail = (valor: string): boolean => {
  return isEmail(valor);
};

export const calculateAge = (birthday: any): number => {
    const fechaActual = new Date();
    const anoActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1;
    const diaActual = fechaActual.getDate();

    // 2016-07-11
    const anoNacimiento = parseInt(String(birthday).substring(0, 4));
    const mesNacimiento = parseInt(String(birthday).substring(5, 7));
    const diaNacimiento = parseInt(String(birthday).substring(8, 10));

    let edad = anoActual - anoNacimiento;
    if (mesActual < mesNacimiento) {
        edad--;
    } else if (mesActual === mesNacimiento) {
        if (diaActual < diaNacimiento) {
            edad--;
        }
    }
    return edad;
};

export const allEqual = (arr: string[]) => arr.every((val) => val === arr[0]);

export const isThereFourConsecutiveNumber = (numbers: string) => {
  if (/(^|(.)(?!\2))(\d)\3{4,}(?!\3)/.test(numbers)) {
    return true;
  }
  return false;
};
