import { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  useMediaQuery,
  useTheme,
  TextField,
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import { ArrowForward, Check, Error, Warning } from '@mui/icons-material';
import ReactTooltip from 'react-tooltip';

import { CustomButton } from '@buttons/CustomButton';
import { useStyles } from './useStyle';
import { useDriverStore, initialDriver } from '@store/driverInfoStore';
import { useSelectedQuoteStore, defaultQuote } from '@store/QuoteStore';
import { useCarStore, initialCarListData } from '@store/carInfoStore';
import {
  isThereFourConsecutiveNumber,
  allEqual,
} from '@utils/utilityFunctions';

import ReCAPTCHA from 'react-google-recaptcha';
import { InterInstance } from '@api/config';
import { useTokenStore } from '@store/TokenStore';
import { useIdStore } from '@store/UrlIdStore';
import { getToken } from '@api/getToken';
import { usePolicyStore } from '@store/policyStore';

interface Props {
  padding?: number;
  elevation?: number;
  isAbsolute: boolean;
  showInfo: Function;
}

export const NewQuotation = ({
  padding,
  elevation,
  isAbsolute,
  showInfo,
}: Props) => {
  // <--Style-->
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const classes = useStyles({ isMobile, isTablet, isDesktop, isAbsolute });
  const { id } = useIdStore();

  const { driver, setDriver } = useDriverStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const { token } = useTokenStore();
  const { setSelectedQuote } = useSelectedQuoteStore();
  const { setCar: setCarStore } = useCarStore();

  const [name, setName] = useState(driver.name || '');
  const [recaptcha, setRecaptcha] = useState('');
  const [email, setEmail] = useState(driver.email || '');
  const [coppelEmployeeId, setCoppelEmployeeId] = useState(driver.coppelEmployeeId || '');
  const [dataEmployee, setDataEmployee] = useState({
    name: "",
    employeeId: ""
  });
  const [errorName, setErrorName] = useState({
    msg: '',
    isError: false,
  });
  const [warningName, setWarningName] = useState({
    msg: '',
    isWarning: false,
  });
  const [errorTelefono, setErrorTelefono] = useState({
    msg: '',
    isError: false,
  });
  const [warningTelefono, setWarningTelefono] = useState({
    msg: '',
    isWarning: false,
  });
  const [errorEmail, setErrorEmail] = useState({
    msg: '',
    isError: false,
  });
  const [errorCheckbox, setErrorCheckbox] = useState({
    msg: '',
    isError: false,
  });
  const [coppelWarningEmployeeId, setCoppelWarningEmployeeId] = useState({
    msg: '',
    isWarning: false
  });
  const [coppelErrorEmployeeId, setCoppelErrorEmployeeId] = useState({
    msg: '',
    isError: false
  })
  const [validateFields, setValidateFields] = useState({
    coppelEmployeeId: false,
    name: false,
    phone: false,
    email: false
  })

  useEffect(()=>{
    setDriver({
      ...initialDriver,
      coppelEmployeeId: driver.coppelEmployeeId
    })
    sessionStorage.setItem('driverInfo-storate', JSON.stringify({
      ...initialDriver,
      coppelEmployeeId: driver.coppelEmployeeId
    }))
    setSelectedQuote(defaultQuote)
    sessionStorage.setItem('quote-storage', JSON.stringify(defaultQuote))
    setCarStore(initialCarListData)
    sessionStorage.setItem('carInfo-storage', JSON.stringify(initialCarListData))
  },[])

  const verifyCoppelEmployeeId = async (id: string) => {
    let tmpToken = token
    if(!tmpToken){
      tmpToken = await getToken()
    }
    const { data } = await InterInstance({
      method: 'GET',
      url: `/catalogs/coppel-workers/${id}`,
      headers: { Authorization: `Bearer ${tmpToken}` },
    }).catch(error => {
      console.log(error)
      return error
    });

    if(data && data.success && data.allowed){
      const { data: { employee } } = data
      setDataEmployee({
        name: employee.nombreempleado,
        employeeId: employee.numeroempleado
      })
      setCoppelEmployeeId(employee.numeroempleado)
      addPolicyInfo({
        ...policy,
        employee_id: employee.numeroempleado
      })
      return data.success
    }
    return false
  }

  useEffect(()=>{
    if(id)
      asyncLoad(id)
  },[])

  const asyncLoad = async (id: string) => {
    await verifyCoppelEmployeeId(id)
  }

  const validateEmail = (email: string) => {
    const beforeAtSign = email.split('@')[0]
    const afterAtSign = email.split('@')[1]
    if(
      !email.includes('@')
      || beforeAtSign.length < 4
      || !afterAtSign
      || afterAtSign.length < 4
      ){
      setErrorEmail({
        msg: 'El email que ingresaste no es correcto.',
        isError: true,
      });
      return false
    }
    setErrorEmail({
      msg: '',
      isError: false,
    });
    return true
  }

  const handleInputChange = async (event: any) => {
    let regex = /^[a-zA-Z]*$/;
    if (event.target.id === 'name') {
      setValidateFields({
        ...validateFields,
        name: true
      })
      
      if (event.target.value.trim() === '') {
        setErrorName({
          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
          isError: true,
        });
        setName('');
        setDriver({
          ...driver,
          name: event.target.value,
        });
        return;
      }
      if (regex.test(event.target.value)) {
        const nameText = event.target.value;
        setDriver({
          ...driver,
          name: nameText,
        });
        if (nameText.length <= 30) {
          if (nameText.length < 2) {
            setWarningName({
              msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2. ',
              isWarning: true,
            });
          } else {
            setWarningName({
              msg: '',
              isWarning: false,
            });
          }
          setName(nameText.trim());
        } else {
          setWarningName({
            msg: '¡Oops! Parece que estás superando el máximo de caracteres. Sólo puedes escribir 30 caracteres.',
            isWarning: true,
          });
        }
        setErrorName({
          msg: '',
          isError: false,
        });
      } else {
        setErrorName({
          msg: '¡Oops! Aquí sólo puedes escribir letras.',
          isError: true,
        });
        setWarningName({
          msg: '',
          isWarning: false,
        });
      }
      return;
    }

    regex = /^[0-9]*$/;
    const telefono = event.target.value;
    if (event.target.id === 'telefono') {
      setValidateFields({
        ...validateFields,
        phone: true
      })

      if ( telefono.length <= 10 && !regex.test(telefono)){
        setErrorTelefono({
          msg: 'Aquí sólo puedes escribir números.',
          isError: true,
        });
        setWarningTelefono({
          msg: '',
          isWarning: false,
        });
        return
      }

      if (telefono.length <= 10 && validateFields.phone) {
        setDriver({
          ...driver,
          telefono: telefono,
        });
        setErrorTelefono({
          msg: 'El teléfono no es válido.',
          isError: true,
        });
        setWarningTelefono({
          msg: '',
          isWarning: false,
        });
      }

      if(telefono.length === 10){
        setErrorTelefono({
          msg: '',
          isError: false,
        });
        setWarningTelefono({
          msg: '',
          isWarning: false,
        });
      }
      return;
    }

    if (event.target.id === 'email') {
      setValidateFields({
        ...validateFields,
        email: true
      })
      setDriver({
        ...driver,
        email: event.target.value,
      });
      setEmail(event.target.value);
      validateEmail(event.target.value);
    }

    if(event.target.id === 'employee_id'){
      regex = /^[a-zA-Z0-9]*$/;
      const employee = event.target.value
      if(!regex.test(employee))
        return
      
      setValidateFields({
        ...validateFields,
        coppelEmployeeId: true
      })
      setCoppelEmployeeId(employee)
      if(employee === ''){
        setCoppelErrorEmployeeId({
          msg: '',
          isError: false
        })
        setCoppelWarningEmployeeId({
          msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
          isWarning: true
        })
        return
      }

      if(await verifyCoppelEmployeeId(id)){
        setCoppelWarningEmployeeId({
          msg: '',
          isWarning: false
        })
        setCoppelErrorEmployeeId({
          msg: '',
          isError: false
        })
        return
      }
      setCoppelWarningEmployeeId({
        msg: '',
        isWarning: false
      })
      setCoppelErrorEmployeeId({
        msg: '',
        isError: false
      })
    }
  };

  const handleNextStep = () => {
    if(!coppelEmployeeId){
      setCoppelErrorEmployeeId({
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
        isError: true
      })
      return;
    }

    if (driver.name === '' || name === '') {
      setErrorName({
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      setWarningName({
        msg: '',
        isWarning: false,
      });
      return;
    }
    if (driver.name.length < 2) {
      setWarningName({
        msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 2. ',
        isWarning: true,
      });
      setErrorName({
        msg: '',
        isError: false,
      });
      return;
    }
    setErrorName({
      msg: '',
      isError: false,
    });
    setWarningName({
      msg: '',
      isWarning: false,
    });

    const telefonoArray = driver.telefono.split('');
    const isResultAllEquals = allEqual(telefonoArray);
    const isConsecutive = isThereFourConsecutiveNumber(driver.telefono);

    if (
      isResultAllEquals ||
      driver.telefono === '0123456789' ||
      isConsecutive
    ) {
      setErrorTelefono({
        msg: '¡Oops! Debes ingresar un teléfono válido.',
        isError: true,
      });
      return;
    }

    setErrorTelefono({
      msg: '',
      isError: false,
    });
    if (driver.telefono === '') {
      setErrorTelefono({
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      setWarningTelefono({
        msg: '',
        isWarning: false,
      });
      return;
    }
    if (driver.telefono.length < 10) {
      setWarningTelefono({
        msg: '¡Oops! Parece que te faltan caracteres. El mínimo de caracteres es de 10. ',
        isWarning: true,
      });
      setErrorTelefono({
        msg: '',
        isError: false,
      });
      return;
    }
    setWarningTelefono({
      msg: '',
      isWarning: false,
    });
    setErrorTelefono({
      msg: '',
      isError: false,
    });

    if (driver.email === '') {
      setErrorEmail({
        msg: '¡Oh, no! Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      return;
    }

    if (!validateEmail(driver.email)) {
      setErrorEmail({
        msg: '¡Oh, no! Debes ingresar un correo válido.',
        isError: true,
      });
      return;
    }
    if(recaptcha === ''){
      setErrorCheckbox({
        msg: '¡Oh, no! Te falta llenar el campo captcha para poder continuar.',
        isError: true,
      });
      return;
    }
    showInfo(false);
  };

  const colorNameAdornment = () => {
    if (warningName.isWarning) {
      return 'warning';
    }

    if (errorName.isError) {
      return 'error';
    }
  };

  const colorHelperText = () => {
    if (warningName.isWarning) {
      return '#FF9E1B';
    }

    if (errorName.isError) {
      return '#f44336';
    }
  };

  const coppelAdornment = () => {
    if (coppelWarningEmployeeId.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (coppelErrorEmployeeId.isError) {
      return <Warning color={'error'} />;
    }

    return <Check color={'primary'} />;
  };
  
  const colorCoppelAdornment = () => {
    if (coppelWarningEmployeeId.isWarning) {
      return 'warning';
    }

    if(coppelErrorEmployeeId.isError){
      return 'error';
    }
  }

  const coppelColorHelperText = () => {
    if(coppelWarningEmployeeId.isWarning){
      return '#FF9E1B';
    }

    if (coppelErrorEmployeeId.isError) {
      return '#f44336';
    }
  }

  const nameAdornment = () => {
    if (warningName.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (errorName.isError) {
      return <Error color={'error'} />;
    }

    return <Check color={'primary'} />;
  };

  const colorTelefonoAdornment = () => {
    if (warningTelefono.isWarning) {
      return 'warning';
    }

    if (errorTelefono.isError) {
      return 'error';
    }
  };

  const colorHelperTextTelefono = () => {
    if (warningTelefono.isWarning) {
      return '#FF9E1B';
    }

    if (errorTelefono.isError) {
      return '#f44336';
    }
  };

  const telefonoAdornment = () => {
    if (warningTelefono.isWarning) {
      return <Warning color={'warning'} />;
    }

    if (errorTelefono.isError) {
      return <Error color={'error'} />;
    }

    return <Check color={'primary'} />;
  };

  function onChange(value: any) {
    setRecaptcha(value);
  }

  const emailAdornment = () => {
    if (errorEmail.isError) {
      return <Error color={'error'} />;
    }

    return <Check color={'primary'} />;
  };

  return (
    <Paper
      elevation={elevation}
      style={{
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Box
        style={{
          paddingTop: 18,
          paddingLeft: 23,
          paddingRight: 23,
          paddingBottom: 18,
        }}
      >
        <label className={classes.firstText}>{`Bienvenid@ ${dataEmployee.name}`}</label>
        <div className={classes.blockSpace}>
          <FormControl fullWidth>
              <label className={classes.label}>
                {'Tu número de Colaborador Coppel'}
              </label>
              <TextField
                id="employee_id"
                sx={{
                  marginTop: 1,
                }}
                value={dataEmployee.employeeId || coppelEmployeeId.toUpperCase()}
                label="Número de colaborador"
                fullWidth
                variant="outlined"
                placeholder="Número de colaborador"
                onChange={handleInputChange}
                error={coppelErrorEmployeeId.isError || coppelWarningEmployeeId.isWarning}
                helperText={
                  <label style={{ color: coppelColorHelperText() }}>
                    {coppelErrorEmployeeId.msg || coppelWarningEmployeeId.msg}
                  </label>
                }
                color={colorCoppelAdornment()}
                InputProps={{
                  style: {
                    borderRadius: 8,
                  },
                  endAdornment: dataEmployee.employeeId && coppelAdornment(),
                }}
              />
          </FormControl>
          <Box style={{ marginBottom: 20 }} />
          <FormControl fullWidth>
            <ReactTooltip
              id="name-tooltip"
              type="light"
              place="bottom"
              effect="solid"
              backgroundColor="#EBF8FB"
            >
              <Typography width={330}>
              Es la persona que maneja de forma recurrente el vehículo a asegurar.
              </Typography>
            </ReactTooltip>
            <label className={classes.label}>
              {'Primer nombre del conductor'}
            </label>
            <TextField
              data-for="name-tooltip"
              data-tip={''}
              id="name"
              value={driver.name}
              sx={{
                marginTop: 1,
              }}
              label="Nombre"
              fullWidth
              variant="outlined"
              placeholder="Nombre"
              onChange={handleInputChange}
              error={errorName.isError}
              helperText={
                <label style={{ color: colorHelperText() }}>
                  {errorName.msg || warningName.msg}
                </label>
              }
              color={colorNameAdornment()}
              InputProps={{
                style: {
                  borderRadius: 8,
                },
                endAdornment: validateFields.name && nameAdornment(),
              }}
            />
          </FormControl>
          <Box style={{ marginBottom: 20 }} />
          <label className={classes.label}>
            {
              'Teléfono y Correo electrónico del conductor'
            }
          </label>
          <Box style={{ marginBottom: 10 }} />
          <TextField
            sx={{ marginTop: 1 }}
            name="telefono"
            value={driver.telefono}
            label="Telefono"
            id="telefono"
            fullWidth
            variant="outlined"
            placeholder="Telefono"
            onChange={handleInputChange}
            error={errorTelefono.isError}
            helperText={
              <label style={{ color: colorHelperTextTelefono() }}>
                {errorTelefono.msg || warningTelefono.msg}
              </label>
            }
            color={colorTelefonoAdornment()}
            InputProps={{
              style: {
                borderRadius: 8,
              },
              endAdornment: validateFields.phone && telefonoAdornment(),
            }}
          />
          <Box style={{ marginBottom: 20 }} />
          <TextField
            id="email"
            label="Correo electrónico"
            fullWidth
            variant="outlined"
            value={driver.email}
            placeholder="Correo electrónico"
            onChange={handleInputChange}
            error={errorEmail.isError}
            helperText={errorEmail.msg}
            color={errorEmail.isError ? 'error' : 'primary'}
            InputProps={{
              style: {
                borderRadius: 8,
              },
              endAdornment: validateFields.email && emailAdornment(),
            }}
          />
          <Box style={{ marginBottom: 20 }} />
          <FormHelperText error={errorCheckbox.isError}>
            {errorCheckbox.msg}
          </FormHelperText>
          <Box style={{ marginBottom: 20 }} />
          <ReCAPTCHA
            sitekey="6LdVLtUjAAAAAK63bwg6YPLQa1GV6_H1ibukGzz8"
            onChange={onChange}
            hl='es'
          />
          <Box style={{ marginBottom: 20 }} />
          <CustomButton
            text={'Siguiente'}
            color={'primary'}
            variant={'contained'}
            endIcon={<ArrowForward />}
            style={{ width: '100%', height: 56, textTransform: 'none' }}
            onClick={handleNextStep}
          />
          <Box style={{ marginBottom: 20 }} />
        </div>
      </Box>
    </Paper>
  );
};
