import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import recibida from "@assets/images/renovacion/recibida.png";

export const ApplicationInProcess : React.FC = () => {

    return (
        <Grid container spacing={0} columns={12} sx={{alignItems:'center',justifyContent:'center'}}>
            <Grid item xs={12} md={6} lg={6} xl={5}>
                <Box sx={{display:'flex',width:'100%',height:'700px',
                    flexDirection:'column',
                }}>
                    <Box
                        component={'img'}
                        src={recibida}
                        sx={{
                            objectFit:'cover',
                            width: '100%',
                            height: '100%',
                        }}
                        alt="recibida"
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={5}>
                <Box sx={{
                    display: 'flex', width: '100%', height: {xs:'100%',sm:'700px'},
                    flexDirection: 'column',
                    gap:'40px',
                    justifyContent:'center',
                    alignItems:'center',
                    backgroundColor: "#0043A5"
                }}>

                    <Box component={'span'} sx={{
                        padding: {xs: '40px 0px 0px 0px', sm: '30px 58px 0px 58px',md: '30px 58px 0px 58px',lg: '20px 58px 0px 58px'},
                        width:'80%',
                        color: "var(--White, #FFF)",
                        fontSize: "40px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "114%"}}>
                        {"Estamos trabajando en tu solicitud"}
                    </Box>

                    <Box sx={{
                        padding: {xs: '0px 38px 80px 38px', sm: '40px 58px 60px 58px',md: '0px 58px 60px 58px',lg: '0px 58px 40px 58px'},
                    }}>
                        <Typography
                            sx={{
                                width: '100%',
                            }}
                        dangerouslySetInnerHTML={{
                            __html: `<div style="width: 100%"><span style="color: white; font-size: 20px;  font-weight: 400; line-height: 30px; word-wrap: break-word">Ya recibimos una solicitud para tu póliza de AutoProtección Coppel, si tienes alguna duda comunícate por teléfono al: </span><span style="color: white; font-size: 20px;  font-weight: 700; line-height: 30px; word-wrap: break-word">55 8979 0980</span><span style="color: white; font-size: 20px;  font-weight: 400; line-height: 30px; word-wrap: break-word"> o si lo prefieres escríbenos al correo: </span><span style="color: white; font-size: 20px;  font-weight: 700; line-height: 30px; word-wrap: break-word">solicitudescoppel@inter.mx</span><span style="color: white; font-size: 20px;  font-weight: 400; line-height: 30px; word-wrap: break-word">. <br/><br/>Agradecemos tu confianza en nuestros servicios y queremos seguir siendo la opción de seguro favorita para tus vehículos y los de tus familiares. </span></div>`
                        }}/>
                    </Box>


                </Box>

            </Grid>

        </Grid>)
}
