import hdi from '@assets/images/hdi.png';
import { Typography } from '@mui/material';


const VehicleNotFound = () => {
  return (
    <div style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px'
        }}
    >
        <div style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          height: '150px'
        }}>
          <img 
              src={hdi}
              width="150px"
              alt="logo-hdi"
          />
        </div>
          
        <div style={{ maxWidth: '350px', textAlign: 'center' }}>
            <Typography style={{
              fontWeight: 'bold'
            }}>
            Por el momento este vehículo no se encuentra disponible con HDI, 
            Favor de comunicarse a: <span style={{textDecoration: 'underline', fontWeight: 'bold'}}>5589790980</span> para brindarle 
            una pronta solución.
            </Typography>
        </div>
    </div>
  )
}

export default VehicleNotFound