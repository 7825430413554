import React from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Modal, Paper, Box, Theme, Typography } from '@mui/material';
import { CustomButton } from '@buttons/CustomButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

type ModalShareProps = {
  onClose: () => void;
  foundPolicy: '';
  show: (component: React.ElementType, props: Object) => void;
  hide: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    top: '50%',
    left: '50%',
    width: 499,
    height: 364,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalNivRegister: React.FC<ModalShareProps> = ({ onClose }) => {
  const classes = useStyles();

  return (
    <Modal open>
      <Fade in>
        <Paper className={classes.root}>
          <HighlightOffIcon
            onClick={onClose}
            style={{
              height: '30px',
              width: '30px',
              color: '#212121',
              cursor: 'pointer',
              position: 'absolute',
              right: '48px',
              top: '24px',
            }}
          />
          <Box padding={3} paddingTop={7.2}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
            >
              <ReportProblemOutlinedIcon
                style={{
                  color: ' #CD0505',
                  width: '84.56px',
                  height: '68px',
                  margin: 'auto',
                }}
              />
            </Box>
            <Box width={'95%'} style={{ marginTop: '-10px' }}>
              <Typography
                variant={'h6'}
                fontFamily="Montserrat"
                fontSize={'16px'}
                fontWeight={400}
                marginTop={3}
                textAlign="center"
              >
                ¡Oopss!! Este vehículo ya está asegurado. Aquí sólo puedes
                agregar uno que no tenga seguro. Si tienes alguna duda échanos
                un telefonazo al <strong>55442 46837</strong>
              </Typography>
            </Box>
            <Box marginTop={2}>
              <CustomButton
                text={'¡Perfecto!'}
                onClick={onClose}
                color={'primary'}
                variant={'contained'}
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  fontWeight: 600,
                  height: 56,
                  lineHeight: '20px',
                  width: '100%',
                  textTransform: 'uppercase',
                }}
              />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
