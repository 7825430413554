import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface HeaderModalPayProps {
  onClose: () => void;
}

export const HeaderModalPay: React.FC<HeaderModalPayProps> = ({ onClose }) => {
  return (
    <>
      <Box
        marginBottom={2}
        alignItems={'center'}
      >
        <Box 
          marginTop="-8px"
          display="flex"
          justifyContent="flex-end"
        >
          <IconButton onClick={onClose}>
            <ClearIcon />
          </IconButton>
        </Box>
        <Box display="block">
          <Typography textAlign={"center"} fontSize={"16px"} fontWeight={'bold'}>
            Forma de pago
          </Typography>
        </Box>
      </Box>
    </>
  );
};
