import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';
import welcomeCheckGreen from '@assets/images/WelcomeCheckGreen.svg';
import payment from '@assets/images/payment.svg';
import { usePolicyStore } from '@store/policyStore';

interface Props {
  isZurich?: boolean;
}

export const ZurichWelcome = ({ isZurich }: Props) => {
  const { breakpoints, palette } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const { policy } = usePolicyStore();

  const handleRecibo = () => {
    if (policy.url_recibo) {
      window.open(policy.url_recibo, '_blank');
      window.open(policy.url_poliza, '_blank');
    }
  };

  return (
    <Box
      padding={`${isMobile ? '0px 20px' : '30px'}`}
      marginTop={isTablet ? 2 : 0}
      marginBottom="45px"
      width={isTablet ? 'auto' : '60%'}
      style={{
        backgroundColor: 'white',
        borderRadius: '10px',
        margin: `${isMobile ? '0px 0px 60px 0px' : '30px auto 50px auto'}`,
      }}
      textAlign="center"
    >
      <Box
        component={'img'}
        src={welcomeCheckGreen}
        sx={{
          width: 80,
        }}
        alt="Bienvenido"
        style={{ objectFit: 'contain' }}
        margin="25px 0px 20px 0px"
      />
      <Typography
        style={{ fontWeight: 700 }}
        fontSize={`${isDesktop ? '28px' : '20px'}`}
      >
        ¡Ya eres parte de Inter!
      </Typography>
      <Typography
        fontSize={`${isDesktop ? '16px' : '16px'}`}
        margin="-5px 0px 20px 0px"
        fontWeight="400"
      >
        Hoy sí duermes seguro, seguro
        <span role="img" aria-label="arrow">
          😴
        </span>{' '}
        <span role="img" aria-label="arrow">
          ✨
        </span>
      </Typography>
      <Typography
        style={{
          fontWeight: 700,
          maxWidth: '600px',
          wordSpacing: '2px',
        }}
        fontSize="16px"
        margin="40px auto  0px auto"
      >
        ¡Ya está lista tu póliza!
      </Typography>
      <Typography
        style={{ fontWeight: 400 }}
        fontSize="16px"
        marginBottom={'40px'}
      >
        Da click en el botón de descargar para obtener tú póliza, pero recuerda
        que para mantenerte bien protegid@,{' '}
        <strong>
          deberás realizar tu pago en los próximos 30 días. Si se te pasa,
          quedará cancelada
        </strong>
      </Typography>
      <Box
        sx={{
          backgroundColor: '#F2FAFC',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '38.5px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            component="img"
            src={payment}
            sx={{
              width: 110,
            }}
            alt="App Store"
            style={{ objectFit: 'contain' }}
            margin="20px 10px 6px"
          />
          <Box sx={{ margin: '32px 28.5px 0px 24px' }}>
            <Typography
              style={{
                fontWeight: 700,
                maxWidth: '600px',
                wordSpacing: '2px',
              }}
              fontSize="16px"
              textAlign={'left'}
            >
              Descarga tus recibos para pagar en el banco
            </Typography>
            <Typography
              style={{ fontWeight: 400 }}
              fontSize="16px"
              textAlign={'left'}
            >
              En el archivo vas a encontrar tus recibos según la forma de pago
              que hayas elegido, así como toda la info de los bancos en nuestro
              convenio.
            </Typography>
          </Box>
        </Box>
        <Box marginBottom={'20px'}>
          <Button
            variant="contained"
            onClick={handleRecibo}
            style={
              isDesktop
                ? {
                    width: '40%',
                    textTransform: 'none',
                    height: '56px',
                    marginTop: '45px',
                  }
                : {
                    width: '100%',
                    textTransform: 'none',
                    marginTop: '20px',
                    height: '56px',
                  }
            }
          >
            <Typography fontSize="16px" fontWeight={600}>
              ¡Descargar recibo de pago y póliza!
            </Typography>
          </Button>
        </Box>
      </Box>
      <Typography
        style={{ fontWeight: 400 }}
        fontSize="16px"
        margin="40px 20px 20px 20px"
        padding={isDesktop ? '0px 130px' : '0px'}
      >
        ¿Te confundiste o no sabes qué onda? Nosotros te ayudamos,
      </Typography>
      <Typography
        style={{ fontWeight: 400 }}
        fontSize="16px"
        margin="0px 20px 20px 20px"
        padding={isDesktop ? '0px 130px' : '0px'}
      >
        échanos un telefonazo al
        <CustomButton
          text="55 442 46837"
          onClick={() => null}
          size={isDesktop ? 'medium' : 'small'}
          variant={'text'}
          style={{
            color: palette.primary.main,
            fontSize: isMobile ? '0.64rem' : '16px',
            textTransform: 'none',
            textDecoration: 'underline',
            marginBottom: '5px',
          }}
        />
      </Typography>
    </Box>
  );
};
