import React from 'react';

import { Box, useTheme, useMediaQuery, Checkbox, Typography } from '@mui/material';
//import { Coupon } from './Coupon';
import { BrandConfirm } from './brand/BrandConfirm';
import { ButtonConfirm } from './ButtonConfirm';
import { DetailsConfirm } from './info/DetailsConfirm';
import { useGetQuotePayment } from '@store/QuoteData';

import Vigency from '@options/Vigency';

type Props = {
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;

}


export const RightCard: React.FC<Props> = ({ setShowToast }) => {
  const { breakpoints } = useTheme();
  //const { selectedPlan, paymentMode } = usePlansDataStore();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const [isChecked, setIsChecked] = React.useState(true);
  const { setPaymentOption } = useGetQuotePayment();

  function handleChangeCheckbox() {
    setIsChecked(!isChecked)
    setShowToast(isChecked)
    !isChecked && setPaymentOption('Anual', 'A');
    // setSelectedQuote("")
  }
  return (
    <Box>
      <Vigency />
      <Box
        padding={`${isMobile ? '0px' : '30px'}`}
        height={!isTablet ? 'inherit' : 950}
        width={isMobile ? '100%' : isTablet ? '90%' : '100%'}
        borderRadius={!isTablet ? '10px' : 2}
        style={{
          backgroundColor: 'white',
        }}
      >
        <DetailsConfirm />
        <Box
          borderRadius={2}
          marginTop={2}
          style={{
            backgroundColor: 'white',
          }}
        >
          <Box
            padding={isTablet ? 2 : 0}
            style={{
              borderRadius: '10px',
            }}
          >
            {
              /*<Coupon /> */
            }
            {
              /*<Collaborator /> */
            }
            <BrandConfirm />
            <ButtonConfirm />
          </Box>
        </Box>
      </Box>
    </Box>

  );
};
