import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

type PromotionProps = {
  promotion: string;
};

export const Promotion: React.FC<PromotionProps> = ({ promotion }) => {
  return (
    <Paper elevation={0} style={{ borderRadius: 0 }}>
      <Box padding={2}>
        <Box
          padding={1.2}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          style={{
            borderRadius: 8,
            backgroundColor: 'rgba(3, 158, 204, 0.1)',
          }}
        >
          <Typography variant={'caption'} marginBottom={0.4}>
            {'Promoción:'}
          </Typography>
          <Typography fontWeight={'600'} variant={'body2'}>
            {promotion}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
