import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';

import { Benefit } from '@models/ui/Benefit';
import { CustomButton } from '@buttons/CustomButton';
import { CompareRow } from '@screens/benefits/compare/CompareRow';

type CompareMiniProps = {
  selected: Benefit[];
};

export const CompareMini: React.FC<CompareMiniProps> = ({ selected }) => {
  return (
    <Grid item xs={12}>
      <Box display={'flex'} flexDirection={'column'}>
        <Box
          height={56}
          paddingX={2}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          style={{ backgroundColor: 'rgba(94, 106, 113, 0.1)' }}
        >
          <CustomButton
            disabled
            text={'Amplia'}
            variant={'contained'}
            onClick={() => null}
            style={{
              color: '#0C3771',
              textTransform: 'none',
              backgroundColor: '#F5F7F9',
            }}
          />
          <Typography fontSize={14} fontWeight={'600'}>
            {'Cobertura:'}
          </Typography>
          <CustomButton
            disabled
            text={'Amplia'}
            variant={'contained'}
            onClick={() => null}
            style={{
              color: '#0C3771',
              textTransform: 'none',

              backgroundColor: '#F5F7F9',
            }}
          />
        </Box>
        <CompareRow
          title={'Daños materiales'}
          leftValue={selected[0].materialDamage}
          rightValue={selected[1].materialDamage}
          subtitle={'Cubre los daños que le  puedan ocasionara tu vehículo'}
        />
        <Divider />
        <CompareRow
          title={'Robo total'}
          leftValue={selected[0].theft}
          rightValue={selected[1].theft}
          subtitle={'Cubre un porcentaje del valor total del vehículo'}
        />
        <Divider />
        <CompareRow
          title={'Responsabilidad civil'}
          leftValue={selected[0].civilResponsability}
          rightValue={selected[1].civilResponsability}
          subtitle={'Cubre los daños que puedas ocasionar a terceros'}
        />
        <Divider />
        <CompareRow
          title={'Gastos medicos'}
          leftValue={selected[0].medicalExpenses}
          rightValue={selected[1].medicalExpenses}
          subtitle={'Cubre los gastos médicos ocasionados por un incidente'}
        />
        <Divider />
        <CompareRow
          hasIcons
          title={'Defensa legal'}
          leftValue={selected[0].legalDefense}
          rightValue={selected[1].legalDefense}
          subtitle={'Cubre los gastos legales y pago de honorarios de abogados'}
        />
        <Divider />
        <CompareRow
          title={'Asistencia en viajes'}
          leftValue={selected[0].travelAssistance}
          rightValue={selected[1].travelAssistance}
          subtitle={'Cubre los gastos de asistencia vial del vehículo'}
        />
        <Divider />
      </Box>
    </Grid>
  );
};
