import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import {
  Grid,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';

export const ListCoverDetails: React.FC = () => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const [addValue1, setAddValue1] = useState(false);
  const [addValue2, setAddValue2] = useState(false);
  const [addValue3, setAddValue3] = useState(false);
  const [addValue4, setAddValue4] = useState(false);

  return (
    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={isMobile ? 12 : 7}>
        <Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              <Typography
                variant={'caption'}
                style={{ fontWeight: 'normal', color: '#424242' }}
                textAlign={'start'}
              >
                {'Autopartes'}
              </Typography>
              <Typography
                variant={'caption'}
                marginLeft={1}
                style={{ fontWeight: 'bold', color: '#424242' }}
                textAlign={'start'}
              >
                {`$2.100.02`}
              </Typography>
            </Box>
            <Box>
              {addValue1 ? (
                <CustomButton
                  text={'Añadir'}
                  onClick={() => setAddValue1(!addValue1)}
                  size={'medium'}
                  variant={'text'}
                  endIcon={<CheckIcon />}
                  style={{
                    backgroundColor: '#e8f5e9',
                    color: palette.success.main,
                    fontSize: isMobile ? '0.64rem' : '',
                    textTransform: 'none',
                    fontWeight: 'normal',
                  }}
                />
              ) : (
                <CustomButton
                  text={'Añadir'}
                  onClick={() => setAddValue1(!addValue1)}
                  size={'medium'}
                  variant={'text'}
                  endIcon={<AddIcon />}
                  style={{
                    color: palette.primary.main,
                    fontSize: isMobile ? '0.64rem' : '',
                    textTransform: 'none',
                    fontWeight: 'normal',
                  }}
                />
              )}
            </Box>
          </Box>
          <Box marginBottom={2}>
            <Typography
              variant={'caption'}
              textAlign={'start'}
              fontSize={isMobile ? '11px' : {}}
              style={{ fontWeight: 'normal', color: '#424242' }}
            >
              {
                'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto'
              }
            </Typography>
          </Box>
          <Divider />
        </Box>
        <Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              <Typography
                variant={'caption'}
                style={{ fontWeight: 'normal', color: '#424242' }}
                textAlign={'start'}
              >
                {'Autopartes'}
              </Typography>
              <Typography
                variant={'caption'}
                marginLeft={1}
                style={{ fontWeight: 'bold', color: '#424242' }}
                textAlign={'start'}
              >
                {`$2.100.02`}
              </Typography>
            </Box>
            <Box>
              {addValue2 ? (
                <CustomButton
                  text={'Añadir'}
                  onClick={() => setAddValue2(!addValue2)}
                  size={'medium'}
                  variant={'text'}
                  endIcon={<CheckIcon />}
                  style={{
                    backgroundColor: '#e8f5e9',
                    color: palette.success.main,
                    fontSize: isMobile ? '0.64rem' : '',
                    textTransform: 'none',
                    fontWeight: 'normal',
                  }}
                />
              ) : (
                <CustomButton
                  text={'Añadir'}
                  onClick={() => setAddValue2(!addValue2)}
                  size={'medium'}
                  variant={'text'}
                  endIcon={<AddIcon />}
                  style={{
                    color: palette.primary.main,
                    fontSize: isMobile ? '0.64rem' : '',
                    textTransform: 'none',
                    fontWeight: 'normal',
                  }}
                />
              )}
            </Box>
          </Box>
          <Box marginBottom={2}>
            <Typography
              variant={'caption'}
              textAlign={'start'}
              fontSize={isMobile ? '11px' : {}}
              style={{ fontWeight: 'normal', color: '#424242' }}
            >
              {
                'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto'
              }
            </Typography>
          </Box>
          <Divider />
        </Box>
        <Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              <Typography
                variant={'caption'}
                style={{ fontWeight: 'normal', color: '#424242' }}
                textAlign={'start'}
              >
                {'Autopartes'}
              </Typography>
              <Typography
                variant={'caption'}
                marginLeft={1}
                style={{ fontWeight: 'bold', color: '#424242' }}
                textAlign={'start'}
              >
                {`$2.100.02`}
              </Typography>
            </Box>
            <Box>
              {addValue3 ? (
                <CustomButton
                  text={'Añadir'}
                  onClick={() => setAddValue3(!addValue3)}
                  size={'medium'}
                  variant={'text'}
                  endIcon={<CheckIcon />}
                  style={{
                    backgroundColor: '#e8f5e9',
                    color: palette.success.main,
                    fontSize: isMobile ? '0.64rem' : '',
                    textTransform: 'none',
                    fontWeight: 'normal',
                  }}
                />
              ) : (
                <CustomButton
                  text={'Añadir'}
                  onClick={() => setAddValue3(!addValue3)}
                  size={'medium'}
                  variant={'text'}
                  endIcon={<AddIcon />}
                  style={{
                    color: palette.primary.main,
                    fontSize: isMobile ? '0.64rem' : '',
                    textTransform: 'none',
                    fontWeight: 'normal',
                  }}
                />
              )}
            </Box>
          </Box>
          <Box marginBottom={2}>
            <Typography
              variant={'caption'}
              textAlign={'start'}
              fontSize={isMobile ? '11px' : {}}
              style={{ fontWeight: 'normal', color: '#424242' }}
            >
              {
                'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto'
              }
            </Typography>
          </Box>
          <Divider />
        </Box>
        <Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              <Typography
                variant={'caption'}
                style={{ fontWeight: 'normal', color: '#424242' }}
                textAlign={'start'}
              >
                {'Autopartes'}
              </Typography>
              <Typography
                variant={'caption'}
                marginLeft={1}
                style={{ fontWeight: 'bold', color: '#424242' }}
                textAlign={'start'}
              >
                {`$2.100.02`}
              </Typography>
            </Box>
            <Box>
              {addValue4 ? (
                <CustomButton
                  text={'Añadir'}
                  onClick={() => setAddValue4(!addValue4)}
                  size={'medium'}
                  variant={'text'}
                  endIcon={<CheckIcon />}
                  style={{
                    backgroundColor: '#e8f5e9',
                    color: palette.success.main,
                    fontSize: isMobile ? '0.64rem' : '',
                    textTransform: 'none',
                    fontWeight: 'normal',
                  }}
                />
              ) : (
                <CustomButton
                  text={'Añadir'}
                  onClick={() => setAddValue4(!addValue4)}
                  size={'medium'}
                  variant={'text'}
                  endIcon={<AddIcon />}
                  style={{
                    color: palette.primary.main,
                    fontSize: isMobile ? '0.64rem' : '',
                    textTransform: 'none',
                    fontWeight: 'normal',
                  }}
                />
              )}
            </Box>
          </Box>
          <Box marginBottom={2}>
            <Typography
              variant={'caption'}
              textAlign={'start'}
              fontSize={isMobile ? '11px' : {}}
              style={{ fontWeight: 'normal', color: '#424242' }}
            >
              {
                'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto'
              }
            </Typography>
          </Box>
          <Divider />
        </Box>
      </Grid>
    </Grid>
  );
};
