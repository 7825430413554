import { Box } from '@mui/material';

import { useCarStore } from '@store/carInfoStore';
import { getVehicleType } from '@utils/vehicleType';

export const CodeHeader = () => {
  const { car } = useCarStore();

  const vehicletype = car.type ? car.type : '';

  return (
    <Box textAlign="center">
      <Box
        component={'img'}
        src={getVehicleType(vehicletype)}
        sx={{
          width: 148,
          height: 55.38,
        }}
        alt={vehicletype}
        marginTop="20px"
        marginBottom="36.62px"
        style={{ objectFit: 'contain' }}
      />
    </Box>
  );
};
