import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 600,
    },
    [theme.breakpoints.down('md')]: {
      height: 480,
    },
    height: 771,
  },
  info: {
    left: ({ isMobile, isTablet }: any) =>
      isMobile ? '4vw' : isTablet ? '8vw' : '10vw',
    top: ({ isMobile, isTablet, isDesktop }: any) => {
      return isMobile ? 110 : isTablet ? 110 : isDesktop ? 250 : 162;
    },
    right: ({ isMobile, isTablet, isDesktop }: any) => {
      return isMobile ? '4vw' : isTablet ? '8vw' : isDesktop ? '42vw' : '56vw';
    },
    position: 'absolute',
  },
  complement: {
    [theme.breakpoints.only('xl')]: {
      fontSize: 58,
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 52,
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 44,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 36,
    },
    fontSize: 32,
    fontWeight: 'bold',
  },
}));
