import { getQuoteCreated } from './getQuoteCreated';

export default async function getAllByPackets(
  token: string,
  id: string,
  driver: any,
  carData: any,
  cpData: any,
  Package: string,
  payment_option: string
) {

  const responses = await Promise.all(
    [
      getQuoteCreated(
        token,
        id,
        'hdi',
        driver,
        carData,
        cpData[0],
        Package,
        payment_option
      ),
    ].map((p) => p.catch((e) => e))
  );

  const result = responses
    .filter((result) => !(result instanceof Error))
    .map((result) => result.data.data)
    .sort(
      (a, b) =>
        parseFloat(a.insurance_premium.total) -
        parseFloat(b.insurance_premium.total)
    );
  return result;
}
