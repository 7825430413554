import React, { ReactNode } from 'react';

//Material UI
import { Container, Box, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

//Assets
import ImageBackground from '@assets/images/circless.png';
import ImageBackgroundM from '@assets/images/CircleMobile.png';

const useStyles = makeStyles({
  root: {
    background: '#f5f5f5',
  },
});

interface Props {
  children?: ReactNode;
  // any props that come into the component
}

export const Wrapper: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));

  return (
    <main className={classes.root}>
      <Box
        component={'img'}
        src={isTablet ? ImageBackgroundM : ImageBackground}
        sx={{
          width: '100%',
        }}
        position={'absolute'}
        marginTop={isTablet ? -20 : 20}
        alt={'Circle Background'}
        style={{
          objectFit: 'cover',
          borderRadius: 8,
        }}
      />

      <Container maxWidth={'lg'} style={{ paddingBottom: '100px' }}>
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: isTablet ? 120 : 90,
            minHeight: '73vh',
            position: 'relative',
          }}
        >
          {children}
        </Box>
      </Container>
    </main>
  );
};
