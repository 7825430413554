import React, { useEffect } from 'react';
import { themeOptions } from './theme/theme';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';

import { Navbar } from '@navigationMenu/Navbar';
import { Footer } from '@navigationMenu/Footer';
import { routes } from '@navigation/routes';
import { ZustandConsumer, ZustandProvider } from '@store/modalStore';
import { getToken } from '@api/getToken';
import { useTokenStore } from './store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';


let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);
const queryClient = new QueryClient();

const useStyles = makeStyles({
  content: {
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#F5F5F5',
  },
});

export const App: React.FC = () => {
  const classes = useStyles();

  const { setToken, token } = useTokenStore();
  const { setTracking, tracking, setDataTracking } = useTrackingStore();

  useEffect(() => {
    (async () => {
      await setToken(await getToken());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (tracking?.id === '0' || tracking?.id === undefined) {
        await setTracking(token);
      } else {
        await setDataTracking(tracking);
      }
    })();
  }, [token]);

  return (
    <QueryClientProvider client={queryClient}>
      <ZustandProvider>
        <Router>
          <div>
            <ThemeProvider theme={theme}>
              <Navbar />
              <ZustandConsumer />
              <main className={classes.content}>
                <Switch>
                  {routes.map(({ exact, path, component }, i) => (
                    <Route
                      key={i}
                      exact={exact}
                      path={path}
                      // @ts-ignore
                      component={component}
                    />
                  ))}
                  <Redirect to={'/'} />
                </Switch>
              </main>
              <Footer />
            </ThemeProvider>
          </div>
        </Router>
      </ZustandProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};
