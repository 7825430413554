import React from 'react';
import { useHistory } from 'react-router';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Grid,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';
import creditCard from '@assets/images/credit-card 1.svg';
import checkPayment from '@assets/images/checkPayment.svg';
import changePayment from '@assets/images/changePayment.svg';
import warningIcon from '@assets/images/WarningWelcome.svg';
import { ZustandContext } from '@store/modalStore';
import { ModalPay } from '@modals/ModalPay';
import { ModalHelp } from '@modals/ModalNeedHelp/ModalHelp';

export const FailWelcome: React.FC = () => {
  const { breakpoints, palette } = useTheme();
  const { show, hide } = React.useContext(ZustandContext);

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  const history = useHistory();
  return (
    <Box
      padding={`${isMobile ? '0px 20px' : '30px'}`}
      marginTop={isTablet ? 2 : 0}
      width={isTablet ? 'auto' : '60%'}
      style={{
        backgroundColor: 'white',
        borderRadius: '10px',
        margin: `${isMobile ? '0px 0px 60px 0px' : '30px auto 50px auto'}`,
      }}
      textAlign="center"
    >
      <Box
        component={'img'}
        src={warningIcon}
        sx={{
          width: 80,
        }}
        alt="Warning"
        style={{ objectFit: 'contain' }}
        margin="20px 0px 20px 0px"
      />
      <Typography
        style={{ fontWeight: 700 }}
        fontSize={`${isDesktop ? '28px' : '20px'}`}
      >
        ¡Oops! Algo no está bien
      </Typography>
      <Typography
        style={{ fontWeight: 700 }}
        fontSize="16px"
        margin="20px 0px 20px 0px"
      >
        Nos declinaron tu método de pago...{' '}
        <span role="img" aria-label="arrow">
          😬
        </span>
      </Typography>
      <Typography
        fontSize={`${isDesktop ? '16px' : '16px'}`}
        margin="8px 0px 20px 0px"
        fontWeight="400"
      >
        No te preocupes, aquí hay algunas opciones para ti:
      </Typography>
      <Grid
        container
        spacing={{ xs: 0, md: 0, sm: 0 }}
        columns={{ xs: 4, sm: 12, md: 12 }}
        gridTemplateRows="repeat(3, 1fr)"
        style={{ textAlign: 'center', margin: '55px 0px 20px' }}
      >
        <Grid
          item
          container
          direction={'column'}
          justifyContent="center"
          alignItems={'center'}
          xs={2}
          lg={4}
          md={4}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/payment')}
        >
          <Box
            component={'img'}
            src={checkPayment}
            sx={{
              width: 60,
            }}
            alt="Tarjeta de crédito"
            style={{ objectFit: 'contain' }}
            margin="0px 0px 20px 0px"
          />
          <Typography
            color={isMobile ? '#039ECC' : 'black'}
            fontSize="13px"
            style={{ textDecoration: 'underline', width: '144px' }}
          >
            ¿Ya checaste que tus datos estén bien?
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction={'column'}
          justifyContent="center"
          alignItems={'center'}
          xs={2}
          md={4}
          lg={4}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/payment')}
        >
          <Box
            component={'img'}
            src={creditCard}
            sx={{
              width: 60,
            }}
            alt="Tarjeta de crédito"
            style={{ objectFit: 'contain' }}
            margin="0px 0px 20px 0px"
          />
          <Typography
            color={isMobile ? '#039ECC' : 'black'}
            fontSize="13px"
            style={{ textDecoration: 'underline', width: '148px' }}
          >
            ¿Probamos con otra tarjeta?
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction={'column'}
          justifyContent="center"
          alignItems={'center'}
          xs={12}
          md={4}
          lg={4}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push('/confirm');
            show(ModalPay, { onClose: hide });
          }}
        >
          <Box
            component={'img'}
            src={changePayment}
            sx={{
              width: 60,
            }}
            alt="Tarjeta de crédito"
            style={{ objectFit: 'contain' }}
            margin="0px 0px 20px 0px"
          />
          <Typography
            color={isMobile ? '#039ECC' : 'black'}
            fontSize="13px"
            style={{ textDecoration: 'underline', width: '128px' }}
          >
            ¿Y si cambias tu método de pago?
          </Typography>
        </Grid>
      </Grid>
      <Typography
        fontSize={`${isDesktop ? '16px' : '16px'}`}
        margin={`${isDesktop ? '52px 0px 25px' : '35px 0px 0px'}`}
      >
        ¿Necesitas que te echemos una manita?
      </Typography>
      <Button
        variant="contained"
        style={
          isDesktop
            ? { width: '40%', textTransform: 'none', height: '56px' }
            : {
                width: '100%',
                textTransform: 'none',
                marginTop: '20px',
                height: '56px',
              }
        }
        onClick={() => show(ModalHelp, { onClose: hide })}
      >
        <Typography fontSize="16px" fontWeight={600}>
          ¡Contáctanos acá!
        </Typography>
      </Button>
      <Typography fontSize="16px" marginTop={`${isDesktop ? '10px' : '50px'}`}>
        o échanos un telefonazo al
        <a href="tel:+525544246837">
          <CustomButton
            text="55 4424 6837"
            onClick={() => null}
            size="small"
            color={'inherit'}
            variant={'text'}
            style={{
              color: palette.primary.main,
              textTransform: 'none',
              fontWeight: 'normal',
              textDecoration: 'underline',
              fontSize: '16px',
            }}
          />
        </a>
      </Typography>
    </Box>
  );
};
