import React, { useEffect, useState } from 'react';
import QuoteLoading from './QuoteLoading';
import { useQuoteList } from '@hooks/useQuoteList';
import { PlansLayout } from '../layout/PlansLayout';
import { useQuoteStore } from '@store/QuoteStore';
import { upCRM } from '@utils/CRM';
import { useTokenStore } from '@store/TokenStore';
import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useTrackingStore } from '@store/TrackingStore';
import { usePolicyStore } from '@store/policyStore';
import { useCPStore } from '@store/cpStore';


const QuoteContainer = () => {
  useQuoteList();
  const { isFetching, quoteList } = useQuoteStore();
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useTokenStore();
  const { car } = useCarStore();
  const { driver } = useDriverStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const { policy } = usePolicyStore();
  const { tracking } = useTrackingStore();
  const { cpData } = useCPStore();

  useEffect(() => {
    if (quoteList.length === 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    if (
      policy.order === 'Todas las opciones' ||
      policy.order === 'Las opciones más económicas'
    ) {
      quoteList.sort(
        (a, b) =>
          // @ts-ignore
          parseFloat(a.insurance_premium.total) -
          // @ts-ignore
          parseFloat(b.insurance_premium.total)
      );
    }
    if (policy.order === 'Las opciones más costosas') {
      quoteList.sort(
        (a, b) =>
          // @ts-ignore
          parseFloat(b.insurance_premium.total) -
          // @ts-ignore
          parseFloat(a.insurance_premium.total)
      );
    }
    if (!isFetching && !isLoading){
      quoteList.map((plan, i) => (
        upCRM(token, car, driver, selectedQuote, policy, tracking, cpData, plan)
      ));
    }
  }, [quoteList]);

  return (
    <>
      <PlansLayout />
      {isLoading || isFetching ? <QuoteLoading /> : null}
    </>
  );
};

export default QuoteContainer;
