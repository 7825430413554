import React, { useEffect, useState } from 'react';
import { Container, Box, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Header } from '@options/Header';
import { Form } from '@options/Form';
import { Brand } from '@options/Brand';
import circleBack1 from '@assets/images/Circlescut.png';
import circleBack3 from '@assets/images/Circles1.png';
import circleBack2 from '@assets/images/Circles2.png';
import { useDriverStore } from '@store/driverInfoStore';
import { useCarStore } from '@store/carInfoStore';
import { useHistory } from 'react-router-dom';
import { Toats } from '@components/alerts/Toats';

const useStyles = makeStyles({
  root: {
    background: '#f5f5f5',
    paddingBottom: '90px',
  },
});

export const Options: React.FC = () => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const classes = useStyles();
  const history = useHistory();
  const [showToast, setShowToast] = useState(true);

  const { driver } = useDriverStore();
  const { car } = useCarStore();

  if (driver.email === '' || !car || car.assembler === '') {
    history.push('/');
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Box
        component={'img'}
        src={circleBack2}
        left={0}
        sx={{
          width: '38%',
        }}
        position={'absolute'}
        top={0}
        alt={'Circle Background'}
      />
      <Box
        component={'img'}
        src={circleBack3}
        right={200}
        sx={{
          width: '40%',
        }}
        position={'absolute'}
        top={130}
        alt={'Circle Background'}
      />
      <Box
        component={'img'}
        src={circleBack1}
        left={110}
        sx={{
          width: '35%',
        }}
        position={'absolute'}
        zIndex={0}
        top={600}
        alt={'Circle Background'}
      />
      <Container maxWidth={'lg'}>
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: isTablet ? 140 : 100,
            minHeight: '70vh',
            position: 'relative',
          }}
          textAlign="center"
        >
          <Toats
            showToast={showToast}
            setShowToast={setShowToast}
            Text="Para contratar este producto tienes que tener una edad mínima de 18 años."
          />
          <Header isMobile={isMobile} />
          <Brand isMobile={isMobile} isTablet={isTablet} />
          <Form isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop} />
        </Box>
      </Container>
    </div>
  );
};
