import create from 'zustand';
import { persist } from 'zustand/middleware';

import { CarListData } from '@models/store/carListStore';

interface Car {
  car: CarListData;
  setCar: (carInfo: CarListData) => void;
}

export const initialCarListData: CarListData = {
  vehicle_id: '',
  description: '',
  assembler: '',
  model: '',
  type: '',
  insurers: ['hdi'],
};

export const useCarStore = create<Car>(
  persist(
    (set) => ({
      car: initialCarListData,
      setCar: (car) => {
        set({ car });
      },
    }),
    {
      name: 'carInfo-storage',
      getStorage: () => sessionStorage,
    }
  )
);
