import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Theme,
  Container,
  Box,
  useMediaQuery,
  useTheme,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CustomButton } from '@buttons/CustomButton';
import { FeacturesList } from '@screens/insurerDetails/FeacturesList';
import { Quotation } from '@screens/insurerDetails/Quotation';
import { useChangePrice } from '@hooks/useChangePrice';
import { useCarStore } from '@store/carInfoStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useSelectedQuoteStore, defaultQuote } from '@store/QuoteStore';
import { useGetQuotePayment } from '@store/QuoteData';

import Loading from '@components/Loading/Loading';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useCPStore } from '@store/cpStore';
import { getQuoteCreated } from '@api/getQuoteCreated';
import { usePolicyStore } from '@store/policyStore';
import { HeaderPlans } from '@screens/plans/HeaderPlans';
import DriverSelectInfo from '@screens/plans/form/DriverSelectInfo';
import CarSelectInfo from '@screens/plans/form/CarSelectInfo';
import VehicleNotFound from '@screens/hdi/VehicleNotFound';
import { Toats } from '@components/alerts/Toats';

// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(10px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  root: {
    background: '#f5f5f5',
    paddingBottom: '40px',
  },
  card: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    borderRadius: 8,

    '& .hidden': {
      display: 'none',
    },
    '&:hover .hidden': {
      display: 'block',
      animation: '$fadeIn .2s ease-in-out',
    },
  },
}));

export const InsurerDetails: React.FC = () => {
  const history = useHistory();
  const { selectedQuote, setSelectedQuote } = useSelectedQuoteStore();

  const classes = useStyles();
  const { breakpoints, palette } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  const [loading, setLoading] = React.useState(false);
  const { Packages, payment_option } = useGetQuotePayment();

  const { driver } = useDriverStore();
  const [zip, setZip] = useState<string>(driver.codigoPostal || '');
  const { policy, addPolicyInfo } = usePolicyStore();
  const { searchCP } = useCPStore();
  const [showToast, setShowToast] = React.useState(false);

  useChangePrice();

  useEffect(() => {
    window.scroll(0, 0);
    searchCP(zip, token);
  }, []);

  //Reqoute

  const { token } = useTokenStore();
  const {
    tracking: { id },
  } = useTrackingStore();
  const { car: carData } = useCarStore();
  const { cpData } = useCPStore();
  //Reqoute

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let data;
        const tmpData = await getQuoteCreated(
          token,
          id,
          'hdi',
          driver,
          carData,
          cpData[0],
          Packages.value,
          payment_option.value
        );
        if (tmpData && tmpData.data) {
          data = tmpData.data;
        } else {
          sessionStorage.removeItem('quote-storage');
          setLoading(false);
          return;
        }
        setSelectedQuote(data.data);
        policy.payment_new = payment_option.value;
        policy.package_new = Packages.value;

        if (payment_option.value === 'M') {
          policy.payment_label = 'Mensual';
        } else if (payment_option.value === '3M') {
          policy.payment_label = 'Trimestral';
        } else if (payment_option.value === '6M') {
          policy.payment_label = 'Semestral';
        } else if (payment_option.value === 'A') {
          policy.payment_label = 'Anual';
        }
        if (Packages.value === 'AMP') {
          policy.package_label = 'Plan Amplio';
        } else if (Packages.value === 'RC') {
          policy.package_label = 'Plan Basico';
        } else if (Packages.value === 'LIMIT') {
          policy.package_label = 'Plan Limitado';
        }
        policy.error = false;
        await addPolicyInfo({ ...policy });
        setLoading(false);
      } catch (error) {
        sessionStorage.removeItem('quote-storage');
        setSelectedQuote(defaultQuote);
        policy.error = true;
        policy.payment_new = policy.payment_anterior;
        policy.package_new = policy.package_anterior;
        if (policy.payment_anterior === 'M') {
          policy.payment_label = 'Mensual';
        } else if (policy.payment_anterior === '3M') {
          policy.payment_label = 'Trimestral';
        } else if (policy.payment_anterior === '6M') {
          policy.payment_label = 'Semestral';
        } else if (policy.payment_anterior === 'A') {
          policy.payment_label = 'Anual';
        }
        if (policy.package_anterior === 'AMP') {
          policy.package_label = 'Plan Amplio';
        } else if (policy.package_anterior === 'RC') {
          policy.package_label = 'Plan Basico';
        } else if (policy.package_anterior === 'LIMIT') {
          policy.package_label = 'Plan Limitado';
        }
        await addPolicyInfo({ ...policy });
        await setLoading(false);
      }
    })();
  }, [Packages, payment_option]);
  return (
    <div className={classes.root}>
      <Loading open={loading} handleClose={() => true} />
      <Container maxWidth={'lg'}>
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: isTablet ? 140 : 70,
            marginBottom: isTablet ? 140 : 70,
            minHeight: isDesktop ? '70vh' : isMobile ? '52vh' : '52vh',
            position: 'relative',
          }}
        >
          <Box
            sx={{ flexGrow: 1 }}
            display="block"
            flexDirection={'row'}
            justifyContent={'space-between'}
            marginTop={`${isMobile ? '10px' : '20px'}`}
          >
            <Box display={'flex'} flexDirection={'row'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyItems={'center'}
              >
                <CustomButton
                  text="Regresar"
                  onClick={() => history.push('/options')}
                  size={isDesktop ? 'medium' : 'small'}
                  variant={'text'}
                  startIcon={<ArrowBackIosIcon />}
                  style={{
                    marginTop: !isMobile ? '24PX' : 0,
                    color: palette.primary.main,
                    fontSize: isMobile ? '0.64rem' : '',
                    textTransform: 'none',
                    fontWeight: 'normal',
                    textDecoration: 'underline',
                  }}
                />
              </Box>
            </Box>
            <Box style={{ marginTop: 15 }} />
            <HeaderPlans />
            <Box style={{ marginTop: 25 }} />
            <Grid
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item xs={0} md={6}>
                <CarSelectInfo fullWidth={true} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DriverSelectInfo fullWidth={true} />
              </Grid>
            </Grid>
            {isTablet && <Box style={{ marginTop: 17 }} />}
            {!loading && (
              <Box display={`${!isMobile ? 'flex' : 'block'}`}>
                {!!selectedQuote.insurance ? (
                  <>
                    <FeacturesList
                      isMobile={isMobile}
                      isDesktop={isDesktop}
                      isTablet={isTablet}
                      setShowToast={setShowToast}
                    />
                    <Quotation isMobile={isMobile} isDesktop={isDesktop} />
                  </>
                ) : (
                  <VehicleNotFound />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
};
