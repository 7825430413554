import { InterInstance } from '@api/config';

//import { business } from '@utils/constants';

export const getPayment = async (token: string, quote: any) => {
  try {
    const { data } = await InterInstance({
      method: 'post',
      url: '/emision',
      headers: { Authorization: `Bearer ${token}` },
      data: quote,
    });
    return data;
  } catch (e) {
    return e;
  }
};
