import React from 'react';
import { makeStyles } from '@mui/styles';
import { Clear } from '@mui/icons-material';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
  IconButton,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';

type ModalResultProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 364,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalResult: React.FC<ModalResultProps> = ({ onClose }) => {
  const classes = useStyles();

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={2}>
            <Box
              marginBottom={2}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant={'h6'} fontWeight={'bold'}>
                {'Conductor asegurado'}
              </Typography>
              <IconButton onClick={onClose}>
                <Clear />
              </IconButton>
            </Box>
            <Typography marginBottom={4} variant={'subtitle2'}>
              {
                'La persona que estará manejando el vehículo la mayor parte del tiempo'
              }
            </Typography>
            <CustomButton
              text={'Entendido'}
              variant={'contained'}
              color={'primary'}
              onClick={onClose}
              style={{ width: '100%', height: 56 }}
            />
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
