import React from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import DigicertLogo from '@assets/images/image_53.png';

export const DigicertField: React.FC = () => {
  const location = useLocation();
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      paddingY={2}
      marginBottom={3}
      style={
        location.pathname === '/'
          ? { backgroundColor: '#f5f5f5' }
          : { backgroundColor: 'white' }
      }
    >
      {location.pathname !== '/' && location.pathname !== '/plans' ? (
        <Box
          component={'img'}
          src={DigicertLogo}
          sx={{
            width: 120,
          }}
          alt={DigicertLogo}
          style={{ objectFit: 'contain', margin: 'auto' }}
        />
      ) : null}
    </Box>
  );
};
