import create from 'zustand';
import { persist } from 'zustand/middleware';

import { plansData } from '@api/plans';
import { Benefit } from '@models/ui/Benefit';

interface Plan {
  plans: Benefit[];
  addPlan: (plan: Benefit) => void;
  removePlan: (title: string) => void;
}

const initialData: Benefit = {
  image: '',
  title: '',
  theft: '',
  promotion: '',
  totalPrice: '',
  materialDamage: '',
  civilResponsability: '',
  medicalExpenses: '',
  legalDefense: false,
  travelAssistance: '',
  plan: 'Plan amplio',
};

export const usePlansStore = create<Plan>(
  persist(
    (set, get) => ({
      plans: [initialData],
      addPlan: (plan) => {
        if (get().plans[0].title === '') {
          set({ plans: [{ ...plan }] });
        } else {
          set({ plans: [...get().plans, { ...plan }] });
        }
      },
      removePlan: (title) => {
        const newPlans = get().plans.filter((item) => item.title !== title);
        if (newPlans.length === 0) {
          set({ plans: [initialData] });
        } else {
          set({ plans: newPlans });
        }
      },
    }),
    {
      name: 'plans-storage',
      getStorage: () => sessionStorage,
    }
  )
);

interface paymentMode {
  type: string;
  total: string;
  plazos: string[];
}

interface Plans {
  plansData: Benefit[];
  getPlansData: () => void;
  selectedPlan: Benefit;
  setSelectedPlan: (selectedPlan: Benefit) => void;
  paymentMode: paymentMode;
  addPaymentMode: (paymentMode: paymentMode) => void;
}

export const usePlansDataStore = create<Plans>(
  persist(
    (set) => ({
      plansData: plansData,
      //@TODO falta inplementar logica para hacer fetch a la data del endpoint, se utilizará esta funcion para ese proceso
      getPlansData: () => {},
      selectedPlan: initialData,
      setSelectedPlan: (selectedPlan) => {
        set({ selectedPlan });
      },
      paymentMode: {
        total: '$0',
        plazos: ['$0'],
        type: 'De contado',
      },
      addPaymentMode: (paymentMode) => {
        set({ paymentMode });
      },
    }),
    {
      name: 'plansData-storage',
      getStorage: () => sessionStorage,
    }
  )
);
