const date = new Date();
export let years: number[] = [];
let year = date.getFullYear();
const current = () => {
  for (let i = year + 1; i >= year - 29; i--) {
    years.push(i);
  }
};
current();

export const cars = [
  {
    name: 'Gran Cherokee Overland 1',
    brand: 'Ford',
    traccion: '4x4',
    motor: 'v8',
    transmision: 'Automático',
    year: '2021',
    img: '../../assets/images/Jeep-Logo 1.png',
  },
  {
    name: 'Gran Cherokee Overland 2',
    brand: 'Ford',
    traccion: '4x4',
    motor: 'v7',
    transmision: 'Automático',
    year: '2020',
    img: '../../assets/images/Jeep-Logo 1.png',
  },
  {
    name: 'Gran Cherokee Overland 3',
    brand: 'Ford',
    traccion: '4x4',
    motor: 'v6',
    transmision: 'Automático',
    year: '2018',
    img: '../../assets/images/Jeep-Logo 1.png',
  },
  {
    name: 'Gran Cherokee Overland 4',
    brand: 'Ford',
    traccion: '4x4',
    motor: 'v8',
    transmision: 'Automático',
    year: '2018',
    img: '../../assets/images/Jeep-Logo 1.png',
  },
];
