import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import { SlideShowItem } from '@models/ui/SlideShowItem';
import FirstSlide from '@assets/images/first-slide.png';
import SecondSlide from '@assets/images/second-slide.png';
import { Plans } from '@screens/home/benefits/Plans';
import { Benefits } from '@screens/home/benefits/Benefits';
import { SlideShow } from '@screens/home-alt/slideshow/SlideShow';
import { BenefitHero } from '@screens/home/benefits/BenefitHero';
import { PartnersList } from '@screens/home/partners/PartnersList';
import { PartnerBanner } from '@screens/home/partners/PartnerBanner';
import { BenefitsAdditional } from '@screens/home/benefits/BenefitsAdditional';
import backgroundCircle from '@assets/images/circles.png';
import { useChangePrice } from '@hooks/useChangePrice';

const items: SlideShowItem[] = [
  {
    title: 'Compara y contrata tu seguro',
    image: FirstSlide,
    complement: ' en un solo lugar',
    subtitle: 'Fácil, rápido y seguro',
  },
  {
    title: 'Encuentra un seguro de auto, moto y pickup',
    image: SecondSlide,
  },
];

const useStyles = makeStyles({
  root: {
    paddingBottom: '40px',
    backgroundImage: `url(${backgroundCircle})`,
    backgroundSize: '20%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundPositionY: '35%',
  },
});

export const HomeAlt: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scroll(0,0);
  }, []);
  
  useChangePrice();

  return (
    <div className={classes.root}>
      <SlideShow items={items} />
      <PartnersList />
      <Benefits />
      <PartnerBanner />
      <Plans />
      <BenefitHero />
      <BenefitsAdditional />
    </div>
  );
};
