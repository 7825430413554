import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import LogoWhite from '@assets/images/LogoWhite.svg';
import { contactanos } from './data';

export const Footer: React.FC = () => (
  <Box
    component={'footer'}
    sx={{ bgcolor: '#282828' }}
    zIndex={10}
    position={'relative'}
  >
    <Container maxWidth={'xl'}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Box
            component={'img'}
            src={LogoWhite}
            sx={{
              width: { xs: 180, md: 160 },
              maxWidth: { xs: 180, md: 180 },
            }}
            alt={'Inter, seguro, seguro'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              variant={'subtitle1'}
              color={'white'}
              fontWeight={'bold'}
              style={{ cursor: 'pointer' }}
            >
              {'Contáctanos'}
            </Typography>
            <Box marginBottom={2} />
            {contactanos.map((item: string, index: number) =>
              index === 0 ? (
                <Link
                  href={`tel:${item.replaceAll(' ', '')}`}
                  target={'_blank'}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography
                    key={item}
                    variant={'subtitle1'}
                    color={'white'}
                    style={{ cursor: 'pointer' }}
                  >
                    {item}
                  </Typography>
                </Link>
              ) : (
                <Link
                  href={`mailto:${item.replaceAll(' ', '')}`}
                  target={'_blank'}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography
                    key={item}
                    variant={'subtitle1'}
                    color={'white'}
                    style={{ cursor: 'pointer' }}
                  >
                    {item}
                  </Typography>
                </Link>
              )
            )}
            <Box marginBottom={4} />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              variant={'subtitle1'}
              fontWeight={'bold'}
              color={'white'}
            >
              {'Clientes'}
            </Typography>
            <Link
              href={`https://inter.mx/aviso-de-privacidad`}
              target={'_blank'}
              style={{ textDecoration: 'none' }}
            >
              <Typography
                variant={'subtitle1'}
                color={'white'}
                style={{ cursor: 'pointer' }}
              >
                Avíso de privacidad
              </Typography>
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Box paddingY={2} />
      <Box
        paddingY={1}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography
          textAlign={'center'}
          variant={'caption'}
          color={'white'}
          style={{ fontWeight: 300 }}
        >
          {'Copyright 2021 ® Interprotección'}
        </Typography>
      </Box>
    </Container>
  </Box>
);
