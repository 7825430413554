import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Box, useMediaQuery, Theme } from '@mui/material';

import { Header } from '@navigationMenu/Header';
import { Benefits as BenefitOptions } from '@screens/benefits/options/Benefits';
import { useChangePrice } from '@hooks/useChangePrice';

const useStyles = makeStyles({
  root: {
    background: '#f5f5f5',
    paddingBottom: '40px',
  },
});

export const Benefits: React.FC = () => {
  const classes = useStyles();
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const [step, setStep] = useState<number>(0);

  const title = (): string => {
    if (step === 2) {
      return 'Compara sus beneficios';
    } else {
      if (isTablet) {
        return 'Selecciona hasta 2 opciones';
      }
      return 'Selecciona hasta 3 opciones';
    }
  };

  useEffect(() => {
    window.scroll(0,0);
  }, []);
  
  useChangePrice();

  return (
    <div className={classes.root}>
      <Container maxWidth={'xl'}>
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: isTablet ? 140 : 100,
            minHeight: '70vh',
            position: 'relative',
          }}
        >
          <Header
            title={title()}
            subtitle={step === 2 ? '' : 'y compara todos sus beneficios'}
          />
          <BenefitOptions step={step} setStep={setStep} />
        </Box>
      </Container>
    </div>
  );
};
