import React from 'react';
import {
  Container,
  Box,
  Paper,
  Grid,
  useTheme,
  useMediaQuery,
  Typography,
  Link
} from '@mui/material';

import Pet from '@assets/images/pet.png';
import Home from '@assets/images/home.png';
import Life from '@assets/images/life.png';
import Medic from '@assets/images/medic.png';

export const BenefitsAdditional: React.FC = () => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  return (
    <Container maxWidth={'lg'}>
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          marginTop: 50,
          marginBottom: 50,
          position: 'relative',
          minHeight: '100%',
        }}
      >
        <Paper elevation={0} style={{ borderRadius: 8 }}>
          <Box
            style={{
              paddingLeft: isTablet ? 16 : 20,
              paddingRight: isTablet ? 16 : 20,
              paddingTop: isTablet ? 16 : 32,
              paddingBottom: isTablet ? 16 : 32,
            }}
          >
            <Typography
              variant={'h4'}
              fontWeight={'bold'}
              style={{ marginBottom: 20 }}
              textAlign={'center'}
            >
              {'¿Buscas otro seguro?'}
            </Typography>
            {isTablet ? (
              <>
                <Typography variant={'subtitle1'} textAlign={'center'}>
                  {'Tenemos estos seguros'}
                </Typography>
                <Typography
                  variant={'subtitle1'}
                  textAlign={'center'}
                  marginBottom={2.6}
                >
                  {'pensados para ti'}
                </Typography>
              </>
            ) : (
              <Typography
                variant={'subtitle1'}
                textAlign={'center'}
                marginBottom={2.6}
              >
                {'Tenemos estos seguros pensados para ti'}
              </Typography>
            )}
            {!isTablet ? (
              <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="center"
                    spacing={isDesktop ? 12 : 8}
                  >
                    {[0, 1, 2, 3].map((_, i) => (
                      <Grid key={i} item>
                        <Box
                          style={{
                            borderRadius: 8,
                            border: '2px solid #039ECC',
                            width: 148,
                            height: 148,
                          }}
                        >
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            paddingTop={1}
                          >
                            <Box
                              component={'img'}
                              src={
                                i === 0
                                  ? Home
                                  : i === 1
                                  ? Medic
                                  : i === 2
                                  ? Life
                                  : Pet
                              }
                              sx={{
                                width: 64,
                                height: 64,
                              }}
                              alt={
                                i === 0
                                  ? 'Bien Seguro'
                                  : i === 1
                                  ? 'Médico Seguro'
                                  : i === 2
                                  ? 'Viaje Seguro'
                                  : 'Mascota Segura'
                              }
                            />
                            <Typography
                              marginY={1}
                              variant={'subtitle1'}
                              fontWeight={'bold'}
                              color={'#039ECC'}
                              marginX={4}
                              textAlign={'center'}
                            >
                              {i === 0
                                ? <Link
                href="https://productos.inter.mx/bien-seguro/"
                target="_blank"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                Bien Seguro
              </Link>
                                : i === 1
                                ? <Link
                href="https://medicoseguro.inter.mx/inicio"
                target="_blank"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                Médico Seguro
              </Link>
                                : i === 2
                                ? <Link
                href="https://vigo.inter.mx/landing"
                target="_blank"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                Viaje Seguro
              </Link>
                                : <Link
                href="https://mascotasegura.inter.mx/seguro-mascota/landing"
                target="_blank"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                Mascota Segura
              </Link>}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={4}>
                {[0, 1, 2, 3].map((_, i) => (
                  <Grid key={i} item xs={6}>
                    <Box
                      style={{
                        borderRadius: 8,
                        border: '2px solid #039ECC',
                        width: '100%',
                        height: 148,
                      }}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        paddingTop={2.5}
                      >
                        <Box
                          component={'img'}
                          src={
                            i === 0
                              ? Home
                              : i === 1
                              ? Medic
                              : i === 2
                              ? Life
                              : Pet
                          }
                          sx={{
                            width: 64,
                            height: 64,
                          }}
                          alt={
                            i === 0
                              ? 'Bien Seguro'
                              : i === 1
                              ? 'Médico Seguro'
                              : i === 2
                              ? 'Viaje Seguro'
                              : 'Mascota Segura'
                          }
                        />
                        <Typography
                          marginY={0.6}
                          variant={'subtitle1'}
                          fontWeight={'bold'}
                          color={'#039ECC'}
                          marginX={2}
                          lineHeight={1.4}
                          textAlign={'center'}
                        >
                          {i === 0
                                ? <Link
                href="https://productos.inter.mx/bien-seguro/"
                target="_blank"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                Bien Seguro
              </Link>
                                : i === 1
                                ? <Link
                href="https://medicoseguro.inter.mx/inicio"
                target="_blank"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                Médico Seguro
              </Link>
                                : i === 2
                                ? <Link
                href="https://vigo.inter.mx/landing"
                target="_blank"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                Viaje Seguro
              </Link>
                                : <Link
                href="https://mascotasegura.inter.mx/seguro-mascota/landing"
                target="_blank"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                Mascota Segura
              </Link>}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};
