interface FeacturesItems {
    logo: string;
    title: string;
    descripcion: string;
    titleTicket: string;
    numberTicket: string;
  }
  
interface SelectedInsurer{
    name: string;
    logo: string;
    description: string;
    features: FeacturesItems[];
}

export const selectedInsurer: SelectedInsurer = {
    name: 'ABA Seguros',
    logo: 'https://i.postimg.cc/q7sLCZSJ/Logos-Aseguradoras.png',
    description: 'ABA Seguros',
    features: [
      {
        logo: 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/car.png',
        title: 'Daños materiales',
        descripcion:
          'Cubre los daños que le puedan ocasionar a tu coche asegurado por accidentes viales, disturbios sociales o desastres naturales.',
        titleTicket: 'DEDUCIBLE',
        numberTicket: '10%',
      },
      {
        logo: 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/road.png',
        title: 'Responsabilidad civil',
        descripcion:
          'Cubre los daños que ocaciones con tu coche a otras personas y sus bienes en caso de un accidente vial.',
        titleTicket: 'COBERTURA DE HASTA',
        numberTicket: '$3,000,000.00',
      },
      {
        logo: 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/healt.png',
        title: 'Gastos médicos',
        descripcion:
          'Cubre los gastos médicos ocasionados por un incidente vial o robo total.',
        titleTicket: 'COBERTURA DE HASTA',
        numberTicket: '$1,000,000',
      },
      {
        logo: 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/shield.png',
        title: 'Defensa legal',
        descripcion: 'Cubre los gastos legales y pago de honorarios de abogados.',
        titleTicket: 'COBERTURA DE HASTA',
        numberTicket: '$500,000',
      },
      {
        logo: 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/planer.png',
        title: 'Asistencia en viajes',
        descripcion: 'Cubre los gastos de asistencia vial al coche asegurado.',
        titleTicket: 'COBERTURA DE HASTA',
        numberTicket: '$500,000',
      },
      {
        logo: 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/car.png',
        title: 'Robo total',
        descripcion:
          'Cubre el valor comercial o factura del coche en caso de que te lo roben, según la antiguedad del mismo.',
        titleTicket: 'DEDUCIBLE',
        numberTicket: '10%',
      },
    ]
  }
  