import { Box, Typography } from '@mui/material';
import abaSegurosLogo from '@assets/images/aba.png';
import { usePlansDataStore } from '@store/plansStore';
import { numberWithCommas } from '@utils/comparationUtils';
import {
  useQuoteStore,
  useSelectedQuoteStore,
} from '../../../store/QuoteStore';

export const PriceTablet = () => {
  const { selectedPlan, paymentMode } = usePlansDataStore();
  const { selectedQuote } = useSelectedQuoteStore();

  return (
    <Box
      borderRadius={2}
      marginTop={2}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      padding={2}
      style={{ backgroundColor: '#e3f2fd' }}
    >
      <Box
        component={'img'}
        src={selectedQuote.company_logo}
        alt={selectedQuote.company_name}
        sx={{
          width: 157,
        }}
        style={{ objectFit: 'contain' }}
      />
      <Box
        width={'90%'}
        borderRadius={2}
        marginTop={2}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        padding={1}
        style={{ backgroundColor: '#e8eaf6' }}
      >
        <Typography color="#8F8F8F" fontSize={13}>
          Costo anual
        </Typography>
        <Typography style={{ fontWeight: 'bold' }} fontSize={28}>
          $
          {parseFloat(
            parseFloat(selectedQuote.insurance_premium.net_premium).toFixed(2)
          ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}
        </Typography>
        <Typography fontSize={14} style={{ color: '#8F8F8F' }}>
        </Typography>
      </Box>
    </Box>
  );
};
