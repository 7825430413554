import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';

import { Header } from '@navigationMenu/Header';

import { useDriverStore } from '@store/driverInfoStore';
import { PolicyNumber } from '@screens/hiring/confirm/policyNumber/PolicyNumber';

export const HeaderPlans = () => {
  const { driver } = useDriverStore();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));

  return (
    <Box sx={{ display: isTablet ? 'block' : 'flex', justifyContent: 'space-between' }}>
      <Box>
        <Header
          title={
            '¡Todo listo!'
          }
          subtitle={
            'Aquí están todas las opciones que tenemos para ti. ¡Elige tu favorita!'
          }
        />
      </Box>
       {isTablet && <Box style={{marginTop: 10}} /> }
      <PolicyNumber />
    </Box>
  );
};
