import React, { useState } from 'react';
import { Box } from '@mui/material';

import { Information } from './Information';
import { FormInformation } from './FormInformation';

interface Formprops {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const Form: React.FC<Formprops> = ({
  isMobile,
  isTablet,
  isDesktop,
}) => {
  const [messageComponent, setMessageComponent] = useState<boolean>(false);
  const [driverMessage, setDriverMessage] = useState<boolean>(false);
  const [genderMessage, setGenderMessage] = useState<boolean>(false);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        marginBottom="10px"
        position="relative"
      >
        {messageComponent ? (
          <Information
            setMessageComponent={setMessageComponent}
            setDriverMessage={setDriverMessage}
          />
        ) : (
          <FormInformation
            setMessageComponent={setMessageComponent}
            isMobile={isMobile}
            isTablet={isTablet}
            isDesktop={isDesktop}
            setDriverMessage={setDriverMessage}
            driverMessage={driverMessage}
            setGenderMessage={setGenderMessage}
            genderMessage={genderMessage}
          />
        )}
      </Box>
    </>
  );
};
