import React from 'react';
import { Box, useTheme, Typography, useMediaQuery } from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';
import { Link } from 'react-router-dom';

//falta implementar el aviso de privacidad
interface InformationProps {
  setMessageComponent: (value: boolean) => void;
  setDriverMessage: (value: boolean) => void;
}

export const Information: React.FC<InformationProps> = ({
  setMessageComponent,
  setDriverMessage,
}) => {
  const { breakpoints, palette } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const box = {
    padding: '46px 16px 16px 16px',
    backgroundColor: '#EBF8FB',
    marginBottom: '215px',
  };

  return (
    <Box width={isMobile ? '325px' : '340px'} style={box}>
      <Typography
        style={{
          fontSize: '16px',
          lineHeight: '24px',
          marginBottom: '30px',
          textAlign: 'left',
          padding: '0px',
          letterSpacing: '0.5px',
        }}
      >
        A veces el costo de un seguro puede variar dependiendo de los datos del
        conductor asegurado. Esto es algo que determina cada aseguradora.
      </Typography>
      <Typography
        style={{
          fontSize: '16px',
          lineHeight: '24px',
          marginBottom: '30px',
          textAlign: 'left',
          padding: '0px',
          letterSpacing: '0.5px',
        }}
      >
        En Inter queremos ser cada vez más inclusivos, y esperamos algún día
        poder cambiar las reglas de las aseguradoras.
      </Typography>
      <Typography
        style={{
          fontSize: '16px',
          lineHeight: '24px',
          marginBottom: '30px',
          textAlign: 'left',
          padding: '0px',
          letterSpacing: '0.5px',
        }}
      >
        Igual que a ti, en Inter nos encanta la privacidad. Consulta nuestro
        <Link
          to={{
            pathname: 'https://inter.mx/aviso-de-privacidad',
          }}
          target="_blank"
        >
          <CustomButton
            text="Aviso de Privacidad."
            onClick={() => {
              setMessageComponent(true);
              setDriverMessage(false);
            }}
            size={isDesktop ? 'medium' : 'small'}
            variant={'text'}
            style={{
              color: palette.primary.main,
              fontSize: '16px',
              textTransform: 'none',
              textDecoration: 'none',
              padding: '0px',
            }}
          />
        </Link>
      </Typography>
      <CustomButton
        text={'Bueno, ¡sigamos!'}
        color={'secondary'}
        variant={'contained'}
        style={{
          width: '100%',
          height: '56px',
          marginBottom: '10px',
          textTransform: 'none',
          border: '1px solid #039ECC',
          color: palette.primary.main,
        }}
        onClick={() => {
          setMessageComponent(false);
        }}
      />
    </Box>
  );
};
