import create from 'zustand';

interface Data {
  [x: string]: any;
  modelo: string;
  anio: string;
  marca: string;
}
interface Car {
  car: Data;
  setCar: (carInfo: Data) => void;
}

export const initialCarData: Data = {
  modelo: '',
  anio: '',
  marca: '',
};

export const useCarModalHomeStore = create<Car>((set) => ({
  car: initialCarData,
  setCar: (car) => {
    set({ car });
  },
}));
