import React from 'react';
import { Grid, Paper, Box, Typography } from '@mui/material';

import { Benefit as BenefitModel } from '@models/ui/Benefit';

type BenefitProps = {
  selected?: boolean;
  onClick: () => void;
  benefit: BenefitModel;
};

export const Benefit: React.FC<BenefitProps> = ({
  benefit,
  onClick,
  selected = false,
}) => {
  return (
    <Grid item xs={12} md={6}>
      <Paper
        onClick={onClick}
        elevation={0}
        style={{
          borderRadius: 8,
          cursor: 'pointer',
          border: selected ? '2px solid #039ECC' : '2px solid #C7C7C7',
        }}
      >
        <Box
          display={'flex'}
          padding={2}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box
            component={'img'}
            src={benefit.image}
            sx={{
              height: 32,
            }}
            style={{ objectFit: 'contain' }}
            alt={benefit.title}
          />
          <Box display={'flex'} flexDirection={'column'} justifyContent={'end'}>
            <Typography textAlign={'end'} variant={'body2'} marginBottom={1}>
              {'Costo anual:'}
            </Typography>
            <Typography
              textAlign={'end'}
              fontWeight={'bold'}
              fontSize={'1.26rem'}
            >
              {benefit.totalPrice}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};
