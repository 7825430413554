import React from 'react';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import { ArrowForward, ArrowBack } from '@mui/icons-material';

import { CustomButton } from '@buttons/CustomButton';

type BenefitsActionsProps = {
  step: number;
  disabled: boolean;
  onNext: () => void;
  onPrev: () => void;
};

export const BenefitsActions: React.FC<BenefitsActionsProps> = ({
  step,
  onNext,
  onPrev,
  disabled,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  return (
    <Grid
      container
      paddingY={2}
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      direction={{
        xs: 'column-reverse',
        sm: 'column-reverse',
        md: 'row',
        lg: 'row',
      }}
    >
      <Grid item lg={6} md={step === 2 ? 3 : 6} xs={12}>
        <CustomButton
          onClick={onPrev}
          style={{
            height: 56,
            textTransform: 'none',
            width: isDesktop ? '100%' : '',
          }}
          color={'primary'}
          variant={'text'}
          startIcon={<ArrowBack />}
          text={step === 0 ? 'Regresar al cotizador' : 'Volver'}
        />
      </Grid>
      {step !== 2 && (
        <Grid item lg={6} md={6} xs={12}>
          <CustomButton
            onClick={onNext}
            color={'primary'}
            disabled={!disabled}
            variant={'contained'}
            endIcon={<ArrowForward />}
            text={step === 1 ? 'Comparar' : 'Continuar'}
            style={{ height: 56, width: '100%', textTransform: 'none' }}
          />
        </Grid>
      )}
    </Grid>
  );
};
