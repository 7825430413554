import React from 'react';
import {
  Container,
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { Plan } from '@screens/home/benefits/Plan';

interface PlanModel {
  title: string;
  details: string[];
}

const plans: PlanModel[] = [
  {
    title: 'Amplia',
    details: [
      'Daños materiales',
      'Robo Total',
      'Responsabilidad civil',
      'Gastos Médicos Ocupantes',
      'Asistencia Legal y Jurídica',
      'Asistencia Vial',
      'Responsabilidad Civil USA y Canadá',
      'Muerte Accidental del Conductor',
    ],
  },
  {
    title: 'Limitado',
    details: [
      'Robo Total',
      'Responsabilidad civil',
      'Gastos Médicos Ocupantes',
      'Asistencia Legal y Jurídica',
      'Asistencia Vial',
      'Responsabilidad Civil USA y Canadá',
      'Muerte Accidental del Conductor',
    ],
  },
  {
    title: 'Responsabilidad Civil',
    details: [
      'Responsabilidad civil',
      'Gastos Médicos Ocupantes',
      'Asistencia Legal y Jurídica',
      'Asistencia Vial',
      'Responsabilidad Civil USA y Canadá',
      'Muerte Accidental del Conductor',
    ],
  },
];

export const Plans: React.FC = () => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  return (
    <Container maxWidth={'lg'}>
      <Box sx={{ flexGrow: 1 }} style={{ marginTop: 50, marginBottom: 50 }}>
        <Typography
          variant={'h3'}
          fontWeight={'bold'}
          style={{ marginBottom: 50 }}
          textAlign={isTablet ? 'start' : 'center'}
        >
          {'Elije el plan a tu medida'}
        </Typography>
        <Grid container spacing={4} alignItems={'stretch'}>
          {plans.map((plan, i) => (
            <Plan key={i} plan={plan} />
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
