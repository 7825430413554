import { Benefit } from '@models/ui/Benefit';
import ABA from '@assets/images/aba.png';
import Primero from '@assets/images/primero.png';
import Qualitas from '@assets/images/qualitas.png';
import Mapfre from '@assets/images/mapfre.png';
import GMX from '@assets/images/gmx.png';
import HDI from '@assets/images/hdi.png';
import Afirme from '@assets/images/afirme.png';
import GNP from '@assets/images/gnp.png';

export const plansData: Benefit[] = [
  {
    image: ABA,
    title: 'ABA Seguros',
    theft: 'Valor comercial 10%',
    promotion: 'Meses sin intereses con tarjetas BBVA',
    totalPrice: '$12,462.12',
    materialDamage: 'Valor comercial 5%',
    civilResponsability: '$ 3,000,000',
    medicalExpenses: '$ 300,000',
    legalDefense: true,
    travelAssistance: '3 Eventos al año',
    plan: 'Plan básico',
  },
  {
    image: Primero,
    title: 'Primero Seguros',
    theft: 'Valor comercial 10%',
    promotion: 'Meses sin intereses con tarjetas BBVA',
    totalPrice: '$14,463.12',
    materialDamage: 'Valor comercial 5%',
    civilResponsability: '$ 3,000,000',
    medicalExpenses: '$ 300,000',
    legalDefense: true,
    travelAssistance: '3 Eventos al año',
    plan: 'Plan amplio',
  },
  {
    image: Qualitas,
    title: 'Qualitas',
    theft: 'Valor comercial 10%',
    promotion: 'Meses sin intereses con tarjetas BBVA',
    totalPrice: '$30,463.12',
    materialDamage: 'Valor comercial 5%',
    civilResponsability: '$ 3,000,000',
    medicalExpenses: '$ 300,000',
    legalDefense: false,
    travelAssistance: '3 Eventos al año',
    plan: 'Plan limitado',
  },
  {
    image: Mapfre,
    title: 'Mapfre',
    theft: 'Valor comercial 10%',
    promotion: 'Meses sin intereses con tarjetas BBVA',
    totalPrice: '$25,463.12',
    materialDamage: 'Valor comercial 5%',
    civilResponsability: '$ 3,000,000',
    medicalExpenses: '$ 300,000',
    legalDefense: true,
    travelAssistance: '3 Eventos al año',
    plan: 'Plan básico',
  },
  {
    image: GMX,
    title: 'GMX',
    theft: 'Valor comercial 10%',
    promotion: 'Meses sin intereses con tarjetas BBVA',
    totalPrice: '$8,463.12',
    materialDamage: 'Valor comercial 5%',
    civilResponsability: '$ 3,000,000',
    medicalExpenses: '$ 300,000',
    legalDefense: true,
    travelAssistance: '3 Eventos al año',
    plan: 'Plan amplio',
  },
  {
    image: HDI,
    title: 'HDI',
    theft: 'Valor comercial 10%',
    promotion: 'Meses sin intereses con tarjetas BBVA',
    totalPrice: '$40,463.12',
    materialDamage: 'Valor comercial 5%',
    civilResponsability: '$ 3,000,000',
    medicalExpenses: '$ 300,000',
    legalDefense: true,
    travelAssistance: '3 Eventos al año',
    plan: 'Plan limitado',
  },
  {
    image: Afirme,
    title: 'Afirme',
    theft: 'Valor comercial 10%',
    promotion: 'Meses sin intereses con tarjetas BBVA',
    totalPrice: '$8,463.12',
    materialDamage: 'Valor comercial 5%',
    civilResponsability: '$ 3,000,000',
    medicalExpenses: '$ 300,000',
    legalDefense: true,
    travelAssistance: '3 Eventos al año',
    plan: 'Plan básico',
  },
  {
    image: GNP,
    title: 'GNP',
    theft: 'Valor comercial 10%',
    promotion: 'Meses sin intereses con tarjetas BBVA',
    totalPrice: '$10,463.12',
    materialDamage: 'Valor comercial 5%',
    civilResponsability: '$ 3,000,000',
    medicalExpenses: '$ 300,000',
    legalDefense: true,
    travelAssistance: '3 Eventos al año',
    plan: 'Plan amplio',
  },
];
