import { InterInstance } from '@api/config';

//import { business } from '@utils/constants';

export const getTracking = async (token: string, initialData: any) => {
  try {
    const { data } = await InterInstance({
      method: 'post',
      url: '/tracking',
      headers: { Authorization: `Bearer ${token}` },
      data: initialData,
    });
    return data;
  } catch (e) {
    return e;
  }
};

export const getDataTracking = async (token: string, tracking_id: string, ) => {
  try {
    const { data } = await InterInstance({
      method: 'get',
      url: '/tracking/coppel-hdi/' + tracking_id,
      headers: { Authorization: `Bearer ${token}` }
    });
    return data;
  } catch (e) {
    return e;
  }
};
