import React, { useContext, useState, useEffect } from 'react';
import validator from 'validator';
import { Check, Info } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Fade,
  Grid,
  Paper,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useForm } from '@hooks/useForm';
import { ModalResult } from '@modals/ModalResult';
import { CustomButton } from '@buttons/CustomButton';
import { ZustandContext } from '@store/modalStore';

export const UserForm: React.FC = () => {
  const history = useHistory();
  const [show, setShow] = useState<boolean>(false);
  const [selector, setSelector] = useState<string>('Mujer');
  const { show: open, hide } = useContext(ZustandContext);

  const [formState, setFormState] = useForm({
    zip: '',
    age: '',
  });

  const { zip, age } = formState;

  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setSelector(newAlignment === null ? '' : newAlignment);
  };

  const colorToggle = (attribute: string, value: string): string => {
    return attribute === value ? '#039ECC' : '#424242';
  };

  const backgroundToggle = (attribute: string, value: string): string => {
    return attribute === value ? '#F5FBFD' : 'transparent';
  };

  useEffect(() => {
    if (isTablet) setShow(false);
  }, [isTablet]);

  const isOlder = (value: string): boolean => {
    if (parseInt(value) >= 18) {
      if (parseInt(value) > 99) {
        return false;
      }
      return true;
    }
    return false;
  };

  const isValidForm = (): boolean => {
    if (!validator.isPostalCode(zip, 'MX')) {
      return false;
    } else if (!validator.isNumeric(age)) {
      return false;
    } else if (validator.isEmpty(selector)) {
      return false;
    } else if (!isOlder(age)) {
      return false;
    } else if (!(age.length <= 2)) {
      return false;
    } else if (validator.isEmpty(zip)) {
      return false;
    } else if (validator.isEmpty(age)) {
      return false;
    }
    return true;
  };

  return (
    <Paper elevation={0} style={{ borderRadius: 8 }}>
      <Box padding={2}>
        <Grid container direction={'row'} spacing={{ xs: 2, md: 3 }}>
          <Grid item md={8} lg={8} xs={12}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography fontWeight={'400'} fontSize={16}>
                {
                  'Ingresa los datos del conductor asegurado para mostrar las mejores'
                }
              </Typography>
              <Info
                color="primary"
                onClick={() =>
                  !isTablet
                    ? setShow(!show)
                    : open(ModalResult, { onClose: hide })
                }
                sx={{ cursor: 'pointer' }}
              />
            </Box>
            <Grid container paddingY={4} spacing={{ xs: 2, md: 3 }}>
              {Object.keys(formState).map((key, i) => (
                <Grid key={i} item xs={i === 0 ? 7 : 5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name={key}
                    value={formState[key]}
                    onChange={(e) => setFormState(e)}
                    InputProps={{
                      style: {
                        borderRadius: 8,
                      },
                      endAdornment:
                        key === 'zip' &&
                        validator.isPostalCode(formState[key], 'MX') ? (
                          <Check color={'primary'} />
                        ) : key === 'age' &&
                          validator.isNumeric(formState[key]) &&
                          isOlder(formState[key]) &&
                          formState[key].length <= 2 ? (
                          <Check color={'primary'} />
                        ) : key === 'zip' &&
                          !validator.isPostalCode(formState[key], 'MX') &&
                          !validator.isEmpty(formState[key]) ? (
                          <Info color={'error'} />
                        ) : (key === 'age' &&
                            !validator.isNumeric(formState[key]) &&
                            !validator.isEmpty(formState[key])) ||
                          (key === 'age' &&
                            validator.isNumeric(formState[key]) &&
                            !validator.isEmpty(formState[key]) &&
                            !isOlder(formState[key])) ? (
                          <Info color={'error'} />
                        ) : null,
                    }}
                    color={
                      (key === 'zip' &&
                        validator.isPostalCode(formState[key], 'MX')) ||
                      (key === 'age' &&
                        validator.isNumeric(formState[key]) &&
                        isOlder(formState[key]) &&
                        formState[key].length <= 2)
                        ? 'primary'
                        : (key === 'zip' &&
                            !validator.isPostalCode(formState[key], 'MX')) ||
                          (key === 'age' &&
                            !validator.isNumeric(formState[key])) ||
                          (validator.isNumeric(formState[key]) &&
                            !isOlder(formState[key]) &&
                            !validator.isEmpty(formState[key]))
                        ? 'error'
                        : 'primary'
                    }
                    label={key === 'zip' ? 'Código postal' : 'Edad'}
                  />
                </Grid>
              ))}
            </Grid>
            <Typography fontWeight={'400'} fontSize={14} marginBottom={1}>
              {'Sexo de nacimiento'}
            </Typography>
            <ToggleButtonGroup
              fullWidth
              sx={{
                height: 56,
                marginBottom: '56px',
                backgroundColor: '#F4F4F4',
              }}
              exclusive
              size={'medium'}
              value={selector}
              onChange={handleChange}
              style={{ borderRadius: 8, padding: 3 }}
            >
              <ToggleButton
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#fafafa',
                }}
                value="Mujer"
                style={{
                  fontWeight: 600,
                  borderRadius: 8,
                  border: 'none',
                  textTransform: 'none',
                  color: colorToggle(selector, 'Mujer'),
                  backgroundColor: backgroundToggle(selector, 'Mujer'),
                }}
              >
                Mujer
              </ToggleButton>
              <ToggleButton
                value="Hombre"
                style={{
                  fontWeight: 600,
                  borderRadius: 8,
                  border: 'none',
                  textTransform: 'none',
                  color: colorToggle(selector, 'Hombre'),
                  backgroundColor: backgroundToggle(selector, 'Hombre'),
                }}
              >
                Hombre
              </ToggleButton>
            </ToggleButtonGroup>
            <CustomButton
              color={'primary'}
              variant={'contained'}
              text={'Mostrar ahora'}
              disabled={!isValidForm()}
              onClick={() => history.push('/plans')}
              style={{ width: '100%', height: 56, textTransform: 'none' }}
            />
          </Grid>
          {!isTablet && show && (
            <Grid item lg={4} md={4} xs={12}>
              <Fade in>
                <Box
                  padding={1.2}
                  display={'flex'}
                  flexDirection={'column'}
                  style={{
                    borderRadius: 8,
                    backgroundColor: 'rgba(3, 158, 204, 0.1)',
                  }}
                >
                  <Typography fontWeight={'600'} fontSize={16} marginBottom={1}>
                    {'Conductor asegurado'}
                  </Typography>
                  <Typography fontWeight={'400'} fontSize={13}>
                    {
                      'La persona que estará manejando el vehículo la mayor parte del tiempo'
                    }
                  </Typography>
                </Box>
              </Fade>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};
