import { InterInstance } from '@api/config';

export const getCRM = async (folio: string, email: string, token: string) => {
  try {
    const { data } = await InterInstance.get(`/crm/update/${folio}/${email}?business=coppel-hdi`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data.data;
  } catch (e) {
    return e;
  }
};
