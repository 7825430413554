import { Box, Typography, Button } from '@mui/material';

import { ModalHelp } from '@modals/ModalNeedHelp/ModalHelp';
//import { CarListData } from '@models/store/carListStore';

interface Props {
  //carList: CarListData[];
  //data: CarListData[];
  show: (component: React.ElementType, props: Object) => void;
  hide: () => void;
}

const FoundSearchText = ({ /*carList, data */ show, hide }: Props) => (
  <Box
    display={'flex'}
    flexDirection={'row'}
    justifyContent={'space-between'}
    alignItems={'center'}
  >
    {/*<Typography fontSize={14}>{data.length} resultados</Typography>*/}
    <Button
      onClick={() => show(ModalHelp, { onClose: hide })}
      variant={'text'}
      color={'primary'}
      //disabled={carList[0].description !== '' ? false : true}
      style={{
        padding: 0,
        width: '100%',
        fontSize: 14,
        fontWeight: 400,
        textTransform: 'none',
        textDecoration: 'underline',
      }}
    >
      {'No encuentro mi vehículo'}
    </Button>
  </Box>
);

export default FoundSearchText;
