import React from 'react';
import { makeStyles } from '@mui/styles';
import { Clear } from '@mui/icons-material';
import { ExpandMore } from '@mui/icons-material';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { CustomButton } from '@buttons/CustomButton';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useGetQuotePayment } from '@store/QuoteData';
import { getQuoteCreated } from '@api/getQuoteCreated';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useCarStore } from '@store/carInfoStore';
import { useCPStore } from '@store/cpStore';
import { useDriverStore } from '@store/driverInfoStore';
import { LoadingButton } from '@mui/lab';

type ModalConfirmDetailsProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 364,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

const paymentOptionsHDI = [
    {
      value: 'Mensual',
      label: 'M',
    },
    {
      value: 'Trimestral',
      label: '3M',
    },
    {
      value: 'Semestral',
      label: '6M',
    },
    {
      value: 'Anual',
      label: 'A',
    },
  ];
const paymentOptionsAll = [
    {
      value: 'Mensual',
      label: 'M',
    },
    {
      value: 'Trimestral',
      label: '3M',
    },
    {
      value: 'Semestral',
      label: '6M',
    },
    {
      value: 'Anual',
      label: 'A',
    },
  ];

export const ModalConfirmDetails: React.FC<ModalConfirmDetailsProps> = ({
  onClose,
}) => {
  const classes = useStyles();

  const { selectedQuote, setSelectedQuote } = useSelectedQuoteStore();
  const { Packages, payment_option, setPackage, setPaymentOption } =
    useGetQuotePayment();
  const { token } = useTokenStore();
  const {
    tracking: { id },
  } = useTrackingStore();
  const { car: carData } = useCarStore();
  const { cpData } = useCPStore();
  const { driver } = useDriverStore();

  const [payment, setPayment] = React.useState(payment_option.value);
  const [packageId, setPackageId] = React.useState(Packages.value);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleCreateCover = async () => {
    setIsLoading(true);
    try {
      let data
      const tmpData = await getQuoteCreated(
        token,
        id,
        selectedQuote.insurance,
        driver,
        carData,
        cpData[0],
        packageId,
        payment
      );
      if(tmpData && tmpData.data){
        data = tmpData.data
      }
      setSelectedQuote(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    window.location.replace('');
    onClose();
  };

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={2}>
            <Box 
              marginTop="-8px"
              display="flex"
              justifyContent="flex-end"
            >
              <IconButton onClick={onClose}>
                <Clear />
              </IconButton>
            </Box>
            <Box
              marginBottom={2}
              alignItems={'center'}
              // marginTop="-5px"
            >
              <Typography textAlign={"center"} fontSize={"16px"} fontWeight={'bold'}>
                {'Datos del Plan'}
              </Typography>
              
            </Box>
            <Box padding={1}>
              <FormControl fullWidth>
                <InputLabel>Plan</InputLabel>
                <Select
                  value={packageId}
                  label="Cobertura"
                  size={'medium'}
                  onChange={(e) => {
                    setPackageId(e.target.value);
                    if (e.target.value === 'AMP') {
                      setPackage('Plan Amplio', 'AMP');
                      return;
                    } else if (e.target.value === 'RC') {
                      setPackage('Plan Basico', 'RC');
                      return;
                    } else if (e.target.value === 'LIMIT') {
                      setPackage('Plan Limitado', 'LIMIT');
                      return;
                    }
                  }}
                  style={{ borderRadius: 8, marginBottom: "20px" }}
                  IconComponent={(props) => (
                    <ExpandMore style={{ color: '#039ECC' }} {...props} />
                  )}
                >
                  {[
                    { label: 'Amplio', value: 'AMP' },
                    { label: 'Limitado', value: 'LIMIT' },
                    {
                      label: 'Responsabilidad Civil',
                      value: 'RC',
                    },
                  ].map(({ value, label }, i) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box padding={1} marginBottom={2}>
              <FormControl fullWidth>
                <InputLabel>Modalidad de pago</InputLabel>
                <Select
                  value={payment}
                  label="Modalidad de pago"
                  size={'medium'}
                  onChange={(e) => {
                    setPayment(e.target.value);
                    if (e.target.value === 'M') {
                      setPaymentOption('Mensual', 'M');
                      return;
                    } else if (e.target.value === '3M') {
                      setPaymentOption('Trimestral', '3M');
                      return;
                    } else if (e.target.value === '6M') {
                      setPaymentOption('Semestral', '6M');
                      return;
                    } else if (e.target.value === 'A') {
                      setPaymentOption('Anual', 'A');
                      return;
                    }
                  }}
                  style={{ borderRadius: 8, marginBottom: "10px" }}
                  IconComponent={(props) => (
                    <ExpandMore style={{ color: '#039ECC' }} {...props} />
                  )}
                >
                  { selectedQuote.insurance === 'hdi' &&
                    paymentOptionsHDI.map((value: any, i: number) => (
                      <MenuItem key={i} value={value.label}>
                        {value.value}
                      </MenuItem>
                    ))
                  }
                  { selectedQuote.insurance !== 'hdi' &&
                    paymentOptionsAll.map((value: any, i: number) => (
                      <MenuItem key={i} value={value.label}>
                        {value.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
            {isLoading ? (
              <LoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              >
                Modificar
              </LoadingButton>
            ) : (
              <CustomButton
                text={'Modificar'}
                onClick={handleCreateCover}
                color={'primary'}
                variant={'contained'}
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              />
            )}
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
