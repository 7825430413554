import React from 'react';
import { Grid, Paper, Box, Typography, useTheme } from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';

import { Benefit } from '@models/ui/Benefit';

type CompareProps = {
  benefit: Benefit;
  style?: React.CSSProperties | undefined;
};

export const Compare: React.FC<CompareProps> = ({
  benefit,
  style = undefined,
}) => {
  const { palette } = useTheme();
  return (
    <Grid item xs={12} md={3}>
      <Paper style={style} elevation={0}>
        <Box
          height={64}
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          style={{ backgroundColor: 'rgba(94, 106, 113, 0.1)' }}
        >
          <Typography textAlign={'center'} variant={'h5'} fontWeight={'bold'}>
            {'Amplia'}
          </Typography>
        </Box>
        <Box
          paddingY={2}
          paddingX={2.6}
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Box paddingY={2}>
            <Typography fontWeight={'bold'} variant={'subtitle2'}>
              {benefit.materialDamage}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <Typography fontWeight={'bold'} variant={'subtitle2'}>
              {benefit.theft}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <Typography fontWeight={'bold'} variant={'subtitle2'}>
              {benefit.civilResponsability}
            </Typography>
          </Box>
          <Box paddingY={2}>
            <Typography fontWeight={'bold'} variant={'subtitle2'}>
              {benefit.medicalExpenses}
            </Typography>
          </Box>
          <Box paddingY={2}>
            {benefit.legalDefense ? (
              <CheckCircle
                sx={{ fontSize: 33 }}
                style={{ color: palette.success.main }}
              />
            ) : (
              <Cancel
                sx={{ fontSize: 33 }}
                style={{ color: palette.error.main }}
              />
            )}
          </Box>
          <Box paddingY={2}>
            <Typography fontWeight={'bold'} variant={'subtitle2'}>
              {benefit.travelAssistance}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};
