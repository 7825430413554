import React from 'react';
import { Container, Box, Grid, useTheme, useMediaQuery } from '@mui/material';

import { BenefitCard } from '@screens/home/benefits/BenefitCard';
import Clock from '@assets/images/clock.png';
import Phone from '@assets/images/phone.png';
import CreditCard from '@assets/images/credit-card.png';
import carRepair from '@assets/images/carRepair.svg';
import clipboard from '@assets/images/clipboard.svg';
import hand from '@assets/images/hand.svg';


interface Item {
  title: string;
  subtitle: string;
  image: string;
  items?: string[];
  extras: number;
}

const items: Item[] = [
  {
    title: 'Elige el plan que necesites ',
    image: carRepair,
    subtitle: 'De acuerdo a tus necesidades, puedes seleccionar el mejor plan para tí.',
    extras: 0,
  },
  {
    title: 'Modalidades de pago',
    image: hand,
    subtitle: 'Puedes contratar tu seguro en pagos mensuales, trimestrales, semestrales o de contado.',
    extras: 0,
  },
  {
    title: 'Estamos contigo en cada momento',
    image: clipboard,
    subtitle: '¿Necesitas ayuda con algún trámite con la aseguradora o tienes  dudas? Te ayudamos  para resolver lo que necesites.',
    extras: 0,
  },
];

export const Benefits: React.FC = () => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  return (
    <Container maxWidth={'lg'}>
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <Grid 
          container 
          justifyContent='center' 
          alignItems='center' 
          spacing={{ xs: 0, sm: 0, md: 2 }}
        >
          {items.map((item, i) => (
            <BenefitCard
              key={i}
              item={item}
              hasDivider={isTablet && i !== 3}
              style={{
                borderTopLeftRadius: i === 0 ? 8 : 0,
                borderTopRightRadius: i === 0 ? 8 : 0,
                borderBottomLeftRadius: i === 3 ? 8 : 0,
                borderBottomRightRadius: i === 3 ? 8 : 0,
              }}
            />
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
