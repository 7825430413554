import create from 'zustand';
import { persist } from 'zustand/middleware';

interface SearchCarFields {
  marcaModelo: string;
  anio: string;
  errorMsg: string;
  isError: boolean;
}

interface SearchCar {
  searchCarFields: SearchCarFields;
  setMarcaModeloState: (marcaModelo: string) => void;
  setAnioState: (anio: string) => void;
  setIsError: (errorMsg: string, isError: boolean) => void;
}

const initialData = {
  marcaModelo: '',
  anio: '',
  errorMsg: '',
  isError: false,
};

export const useSearchCarStore = create<SearchCar>((set, get) => ({
  searchCarFields: initialData,
  setMarcaModeloState: (marcaModelo) => {
    const newState = { ...get().searchCarFields, marcaModelo };
    set({ searchCarFields: newState });
  },
  setAnioState: (anio) => {
    const newState = { ...get().searchCarFields, anio };
    set({ searchCarFields: newState });
  },
  setIsError: (errorMsg, isError) => {
    const newState = { ...get().searchCarFields, errorMsg, isError };
    set({ searchCarFields: newState });
  },
}));
