import React from 'react';
import { Grid, Paper, Box, Button, Divider, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useHistory } from 'react-router-dom';

import { comparation, plazos } from '@utils/comparationUtils';

type cardProps = {
  data: any;
  pay: string;
};
export const CardCompareMobile: React.FC<cardProps> = ({ data, pay }) => {
  const history = useHistory();

  const calulateTotal = (item: any) => {
    let payResult;
    if (pay === 'De contado') {
      payResult = comparation(
        +item.totalPrice.replace(',', '').replace('$', ''),
        plazos.pagoUnico
      );
    }
    if (pay === 'Semestral') {
      payResult = comparation(
        +item.totalPrice.replace(',', '').replace('$', ''),
        plazos.semestral
      );
    }
    if (pay === 'Trimestral') {
      payResult = comparation(
        +item.totalPrice.replace(',', '').replace('$', ''),
        plazos.trimestral
      );
    }
    if (pay === 'Mensual') {
      payResult = comparation(
        +item.totalPrice.replace(',', '').replace('$', ''),
        plazos.mensual
      );
    }

    const totalPlazos =
      payResult?.plazos.length && payResult?.plazos.length - 1;
    return totalPlazos;
  };

  return (
    <Box width={'100%'}>
      <Box>
        <Paper
          style={{
            padding: '20px',
            borderRadius: '10px',
          }}
        >
          <Grid container>
            <Grid xs={12}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                {data.map((i: any) => (
                  <Box
                    component={'img'}
                    src={i.image}
                    sx={{
                      height: 32,
                      width: 100,
                    }}
                    style={{ objectFit: 'contain' }}
                    alt={i.title}
                  />
                ))}
              </Box>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Button
                  onClick={() => history.push('/plans')}
                  variant={'text'}
                  style={{
                    textDecoration: 'underline',
                    textTransform: 'none',
                  }}
                >
                  Cambiar
                </Button>

                <Button
                  variant={'text'}
                  style={{
                    textDecoration: 'underline',
                    textTransform: 'none',
                  }}
                >
                  Cambiar
                </Button>
              </Box>
              <Divider />
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                marginTop={2}
              >
                {data.map((i: any) => (
                  <>
                    <Box display={'flex'} flexDirection={'column'}>
                      <Typography
                        textAlign={'center'}
                        variant={'caption'}
                        fontSize={'13px'}
                        style={{ color: '#757575' }}
                      >
                        {pay !== 'De contado'
                          ? 'Primer pago de:'
                          : 'Costo anual:'}
                      </Typography>
                      <Typography
                        textAlign={'center'}
                        variant={'caption'}
                        fontSize={'16px'}
                        fontWeight={'bold'}
                      >
                        {i.totalPrice}
                      </Typography>
                      {pay !== 'De contado' ? (
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'center'}
                        >
                          <Typography
                            textAlign={'center'}
                            variant={'caption'}
                            fontSize={'13px'}
                            style={{ color: '#757575' }}
                          >
                            {pay === 'Semestral'
                              ? 'Más un pago de:'
                              : `Más ${calulateTotal(i)} pagos de:`}
                          </Typography>
                          <Typography
                            textAlign={'center'}
                            variant={'caption'}
                            fontWeight={'BOLD'}
                            fontSize={'13px'}
                            style={{ color: '#757575' }}
                          >
                            {i.totalPrice}
                          </Typography>
                        </Box>
                      ) : null}
                      <Box marginTop={1}>
                        <Button
                          onClick={() => history.push('/insurer-details')}
                          variant={'contained'}
                          size={'medium'}
                          style={{ textTransform: 'none' }}
                        >
                          ¡Lo quiero!
                        </Button>
                      </Box>
                    </Box>
                  </>
                ))}
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                marginTop={2}
                padding={1}
                borderRadius={2}
                style={{ background: 'rgba(3, 158, 204, 0.08)' }}
              >
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontSize={'13px'}
                  style={{ color: '#757575' }}
                >
                  {'Promoción'}
                </Typography>
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontWeight={'bold'}
                  fontSize={'13px'}
                  style={{ color: '#757575' }}
                >
                  {'Meses sin intereses con tarjetas BBVA'}
                </Typography>
                <Divider />
              </Box>
              <Box marginTop={2}>
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      textAlign={'start'}
                      variant={'caption'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                    >
                      {data[0].materialDamage}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                      style={{ color: '#757575' }}
                    >
                      {'Daños materiales'}
                    </Typography>
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'normal'}
                      fontSize={'12px'}
                      style={{ color: '#757575' }}
                    >
                      {'Cubre los daños que le  puedan ocasionar a tu vehículo'}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      variant={'caption'}
                      textAlign={'end'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                    >
                      {data[1].materialDamage}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box marginTop={2}>
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      textAlign={'start'}
                      variant={'caption'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                    >
                      {data[0].theft}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                      style={{ color: '#757575' }}
                    >
                      {'Robo total'}
                    </Typography>
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'normal'}
                      fontSize={'12px'}
                      style={{ color: '#757575' }}
                    >
                      {'Cubre un porcentaje del valor total del vehículo'}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      variant={'caption'}
                      textAlign={'end'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                    >
                      {data[1].theft}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box marginTop={2}>
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      textAlign={'start'}
                      variant={'caption'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                      marginRight={2}
                    >
                      {data[0].civilResponsability}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                      style={{ color: '#757575' }}
                    >
                      {'Responsabilidad civil'}
                    </Typography>
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'normal'}
                      fontSize={'12px'}
                      style={{ color: '#757575' }}
                    >
                      {'Cubre los daños que puedas ocasionar a terceros'}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      variant={'caption'}
                      textAlign={'end'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                      marginLeft={2}
                    >
                      {data[1].civilResponsability}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box marginTop={2}>
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      textAlign={'start'}
                      variant={'caption'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                    >
                      {data[0].medicalExpenses}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                      style={{ color: '#757575' }}
                    >
                      {'Gastos médicos'}
                    </Typography>
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'normal'}
                      fontSize={'12px'}
                      style={{ color: '#757575' }}
                    >
                      {'Cubre los gastos médicos ocasionados por un incidente'}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      variant={'caption'}
                      textAlign={'end'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                    >
                      {data[1].medicalExpenses}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box marginTop={2}>
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Box marginTop={2}>
                      <CheckCircleIcon fontSize={'large'} color={'success'} />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                      style={{ color: '#757575' }}
                    >
                      {'Defensa legal'}
                    </Typography>
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'normal'}
                      fontSize={'12px'}
                      style={{ color: '#757575' }}
                    >
                      {
                        'Cubre los gastos legales y pago de honorarios de abogados'
                      }
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Box marginTop={2}>
                      <CheckCircleIcon fontSize={'large'} color={'success'} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box marginTop={2}>
                <Grid container>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      textAlign={'start'}
                      variant={'caption'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                    >
                      {data[0].travelAssistance}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                      style={{ color: '#757575' }}
                    >
                      {'Asistencia en viajes'}
                    </Typography>
                    <Typography
                      textAlign={'center'}
                      variant={'caption'}
                      fontWeight={'normal'}
                      fontSize={'12px'}
                      style={{ color: '#757575' }}
                    >
                      {'Cubre los gastos de asistencia vial del vehículo'}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                  >
                    <Typography
                      variant={'caption'}
                      textAlign={'end'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                    >
                      {data[1].travelAssistance}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          style={{
            padding: '20px',
            borderRadius: '10px',
            marginTop: '20px',
          }}
        >
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography
                textAlign={'center'}
                variant={'caption'}
                fontWeight={'bold'}
                fontSize={'20px'}
              >
                {'Coberturas Extras'}
              </Typography>
              <InfoIcon color={'primary'} style={{ marginLeft: '10px' }} />
            </Box>
            <Box marginTop={2}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    textAlign={'start'}
                    variant={'caption'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                  >
                    {'$1,964.00'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    textAlign={'center'}
                    variant={'caption'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                    style={{ color: '#757575' }}
                  >
                    {'Autopartes'}
                  </Typography>
                  <Typography
                    textAlign={'center'}
                    variant={'caption'}
                    fontWeight={'normal'}
                    fontSize={'12px'}
                    style={{ color: '#757575' }}
                  >
                    {
                      'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto.'
                    }
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    variant={'caption'}
                    textAlign={'end'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                  >
                    {'$1,964.00'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    textAlign={'start'}
                    variant={'caption'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                  >
                    {'$2,593.00'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    textAlign={'center'}
                    variant={'caption'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                    style={{ color: '#757575' }}
                  >
                    {'Rines'}
                  </Typography>
                  <Typography
                    textAlign={'center'}
                    variant={'caption'}
                    fontWeight={'normal'}
                    fontSize={'12px'}
                    style={{ color: '#757575' }}
                  >
                    {
                      'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto.'
                    }
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    variant={'caption'}
                    textAlign={'end'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                  >
                    {'$2,593.00'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    textAlign={'start'}
                    variant={'caption'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                  >
                    {'$2,593.00'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    textAlign={'center'}
                    variant={'caption'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                    style={{ color: '#757575' }}
                  >
                    {'Vidrios'}
                  </Typography>
                  <Typography
                    textAlign={'center'}
                    variant={'caption'}
                    fontWeight={'normal'}
                    fontSize={'12px'}
                    style={{ color: '#757575' }}
                  >
                    {
                      'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto.'
                    }
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    variant={'caption'}
                    textAlign={'end'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                  >
                    {'$2,593.00'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box marginTop={2}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    textAlign={'start'}
                    variant={'caption'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                  >
                    {'$2,593.00'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    textAlign={'center'}
                    variant={'caption'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                    style={{ color: '#757575' }}
                  >
                    {'Auto sustituto'}
                  </Typography>
                  <Typography
                    textAlign={'center'}
                    variant={'caption'}
                    fontWeight={'normal'}
                    fontSize={'12px'}
                    style={{ color: '#757575' }}
                  >
                    {
                      'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto.'
                    }
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Typography
                    variant={'caption'}
                    textAlign={'end'}
                    fontWeight={'bold'}
                    fontSize={'13px'}
                  >
                    {'$2,593.00'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};
