import RfcFacil from 'rfc-facil';

interface User {
  birthday: string;
  name: string;
  lastName: string;
  secondName: string;
}

export const normalizeString = (str = '') =>
  `${str}`.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/**
 * @typedef {Object} NormalPersonData
 * @property {string} name name or names from the given person
 * @property {string} lastName first last name
 * @property {string} [secondName] second last name
 * @property {string} birthday a string that matches the format 'YYYY-MM-DD'
 */

/**
 * Creates the RFC for a given user
 * @param {NormalPersonData} user logged user
 * @returns the RFC
 */
export const createRFC = (user: User) => {
  try {
    const [year, month, day] = user.birthday.split('-');
    const rfcData = {
      name: normalizeString(user.name),
      firstLastName: normalizeString(user.lastName),
      secondLastName: normalizeString(user.secondName),
      day,
      month,
      year,
    };
    //@ts-ignore
    return RfcFacil.forNaturalPerson(rfcData);
  } catch (error) {
    console.error(error);
    return '';
  }
};
