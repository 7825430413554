import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Clear, Check, Warning, Error } from '@mui/icons-material';
import validator from 'validator';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
  IconButton,
  TextField,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';
import { usePolicyStore } from '@store/policyStore';
import { NivCustomMask } from '@inputCustomMasks/NivCustomMasks';
import { PlatesCustomMask } from '@inputCustomMasks/PlatesCustomMasks';
import { useCarStore } from '@store/carInfoStore';

type ModalConfirmVehicleProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 364,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalCofirmVehicle: React.FC<ModalConfirmVehicleProps> = ({
  onClose,
}) => {
  const classes = useStyles();
  const { car } = useCarStore();
  const { policy, addPolicyInfo } = usePolicyStore();
  const vehicletype = car.type ? car.type : '';

  const [niv, setNiv] = useState(policy.Niv);
  const [errorVIN, setErrorVIN] = useState({ msg: '', isError: false });
  const [warningVIN, setWarningVIN] = useState({ msg: '', isWarning: false });
  const [plates, setPlates] = useState(policy.plate);
  const [errorPlates, setErrorPlates] = useState({
    msg: '',
    isError: false,
  });
  const [errorNiv, setErrorNiv] = useState({
    msg: '',
    isError: false,
  });

  useEffect(() => {
    if (niv.length < 10) {
      setErrorNiv({ msg: 'Debe ser de 10 a 17 caractéres', isError: true });
    } else if (!validator.isAlphanumeric(niv)) {
      setErrorNiv({
        msg: 'Deben ser solo caractéres alfanuméricos',
        isError: true,
      });
    } else {
      setErrorNiv({ msg: '', isError: false });
    }
  }, [niv]);

  useEffect(() => {}, [plates]);

  const update = () => {
    if (errorNiv.isError === false && errorPlates.isError === false) {
      if (plates.length < 1) {
        (async () => {
          if (vehicletype === 'MOTO') {
            await setPlates('00000');
            await addPolicyInfo({ ...policy, Niv: niv, plate: '00000' });
          } else {
            await setPlates('000000');
            await addPolicyInfo({ ...policy, Niv: niv, plate: '000000' });
          }
          await onClose();
        })();
      } else {
        addPolicyInfo({ ...policy, Niv: niv, plate: plates });
        onClose();
      }
    }
  };

  const validateVIN = (value: string) => {
    if (value.length >= 10) {
      setWarningVIN({ msg: '', isWarning: false });
      setErrorVIN({ msg: '', isError: false });
    }
    if (value.length === 0) {
      setErrorVIN({
        msg: 'Te falta llenar este campo para poder continuar.',
        isError: true,
      });
      setWarningVIN({ msg: '', isWarning: false });
    }
    if (value.length === 1) {
      setWarningVIN({
        msg: 'Parece que te faltan caracteres. El mínimo de caracteres es de 10. ',
        isWarning: true,
      });
      setErrorVIN({ msg: '', isError: false });
    }
    if (value.length < 10) {
      setWarningVIN({
        msg: 'Parece que te faltan caracteres. El mínimo de caracteres es de 10. ',
        isWarning: true,
      });
    }
  };

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={2}>
            <Box 
                marginTop="-8px"
                display="flex"
                justifyContent="flex-end"
              >
                <IconButton onClick={onClose}>
                  <Clear />
                </IconButton>
            </Box>
            <Box
              marginBottom={2}
              alignItems={'center'}
            >
              <Typography textAlign={"center"} fontSize={"16px"} fontWeight={'bold'}>
                {'Datos del vehículo'}
              </Typography>
            </Box>
            <Box padding={1}>
              <TextField
                fullWidth
                value={niv}
                name={'niv'}
                onChange={(e: any) => {
                  if (e.target.value.length <= 17) {
                    setNiv(e.target.value);
                    validateVIN(e.target.value);
                  }
                }}
                onBlur={() => {
                  validateVIN(niv);
                }}
                error={errorVIN.isError}
                helperText={
                  <label
                    style={{
                      color: errorVIN.isError ? '#f44336' : '#FF9E1B',
                    }}
                  >
                    {errorVIN.msg || warningVIN.msg}
                  </label>
                }
                label="Número de serie (VIN)*"
                variant="outlined"
                maxRows={4}
                style={{ marginBottom: '24px' }}
                color={
                  niv.length >= 10
                    ? 'primary'
                    : !warningVIN.isWarning
                    ? 'primary'
                    : niv.length < 10
                    ? 'warning'
                    : 'primary'
                }
                InputProps={{
                  inputComponent: NivCustomMask as any,
                  style: {
                    textTransform: 'capitalize',
                    borderRadius: 8,
                  },
                  endAdornment:
                    niv.length >= 10 ? (
                      <Check color={'primary'} />
                    ) : niv.length < 17 && niv.length > 0 ? (
                      <Warning color={'warning'} />
                    ) : errorVIN.isError ? (
                      <Error color={'error'} />
                    ) : null,
                }}
              />
            </Box>
            <Box padding={1} marginTop={1} marginBottom={1}>
              <TextField
                fullWidth
                error={errorPlates.isError}
                helperText={errorPlates.msg}
                value={plates}
                name={'plates'}
                onChange={(e) => {
                  setPlates(e.target.value);
                }}
                label="Número de placas (opcional)"
                variant="outlined"
                maxRows={4}
                style={{ marginBottom: '24px' }}
                color={
                  plates.length >= 6 && validator.isAlphanumeric(plates)
                    ? 'primary'
                    : 'primary'
                }
                InputProps={{
                  inputComponent: PlatesCustomMask as any,
                  style: {
                    borderRadius: 8,
                  },
                  endAdornment:
                    plates.length >= 6 && validator.isAlphanumeric(plates) ? (
                      <Check color={'primary'} />
                    ) : null,
                }}
              />
            </Box>
            <CustomButton
              text={'Modificar'}
              onClick={update}
              color={'primary'}
              variant={'contained'}
              style={{ height: 56, width: '100%', textTransform: 'none' }}
              disabled={errorVIN.isError || warningVIN.isWarning}
            />
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
