import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      top: 50,
      left: 10,
      right: 0,
      width: '95%',
      height: '85%',
      transform: 'translate(0%, 0%)',
    },
    top: '50%',
    left: '50%',
    width: 420,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  tabsRoot: {
    width: '100%',
  },
  tabsIndicator: {
    display: 'none',
  },
  tabsCentered: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabItemRoot: {
    height: 36,
    transition: 'all .5s',
    [theme.breakpoints.up('md')]: {
      minWidth: 120,
    },
    '&$selected': {
      borderRadius: 8,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  selected: {
    '&.css-1dn0bvo-MuiButtonBase-root-MuiTab-root.Mui-selected': {
      color: theme.palette.common.white,
    },
  },
  wrapped: {
    lineHeight: '24px',
    textTransform: 'uppercase',
    fontSize: '12px',
    position: 'relative',
    display: 'block',
    color: 'inherit',
  },
  delegate: {
    paddingTop: 16,
    paddingRight: 16,
    paddingLeft: 16,
    backgroundColor: '#F5F5F5',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  listItem: {
    backgroundColor: 'white',
    borderRadius: '10px',
    marginTop: '10px',
  },
}));
