import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowForm from '@assets/images/TicketForm.svg';

type DataPriceProps = {
  logo: string;
  title: string;
  desc: string;
  titleTicket: string;
  numberTicket: string;
  feature: any;
};

const DescripcionItems: { [key: string]: string } = {
  DM: 'Te cubre lo que le pueda pasar a tu vehículo por accidentes viales o desastres naturales.',
  RC: 'Te cubre si ocasionas algún daño con tu vehículo a otras personas y sus bienes en un accidente vial.',
  RT: 'Te cubre el valor comercial o de la factura del vehículo según su antigüedad si te lo llegan a robar.',
  GM: 'Te cubre gastos médicos si tienes algún incidente vial o robo.',
  DL: 'Te cubre los gastos legales y el pago de honorarios de abogados si los llegas a necesitar.',
};

const findLogo = (title: string) => {
  switch (title) {
    case 'DM':
      return 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/car.png';
    case 'RT':
      return 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/car.png';
    case 'RC':
      return 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/road.png';
    case 'GM':
      return 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/healt.png';
    case 'RC en el extranjero':
      return 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/car.png';
    case 'AV':
      return 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/planer.png';
    case 'DL':
      return 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/shield.png';
    default:
      return 'https://storage.googleapis.com/inter-public-cloudstorage-prod/cocheseguro/static/icons/car.png';
  }
};

export const DataPrice: React.FC<DataPriceProps> = ({
  numberTicket,
  feature,
}) => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  return (
    <Box
      display={!isMobile ? 'flex' : 'block'}
      width="100%"
      style={{ backgroundColor: '#FAFBFC' }}
      margin={!isMobile ? '10px 0px 0px' : '10px 0px 80px'}
      height="80px"
    >
      <Box
        display={!isMobile ? 'block' : 'flex'}
        width={isMobile ? '100%' : '60px'}
      >
        {isMobile ? (
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Box
              component="img"
              sx={{
                width: '18px',
              }}
              style={{
                margin: !isMobile ? '19px 30px' : '19px 14px 19px 30px',
              }}
              src={findLogo(feature.code)}
            />
            {isMobile ? (
              <Typography
                fontWeight="700"
                fontSize="14px"
                margin="0px"
                width="200px"
              >
                {feature.name}
              </Typography>
            ) : null}
          </Box>
        ) : (
          <Box>
            <Box
              component="img"
              sx={{
                width: '18px',
              }}
              style={{
                margin: !isMobile ? '19px 30px' : '19px 14px 19px 30px',
              }}
              src={findLogo(feature.code)}
            />
            {isMobile ? (
              <Typography
                fontWeight="700"
                fontSize="14px"
                margin="0px"
                width="200px"
              >
                {feature.name}
              </Typography>
            ) : null}
          </Box>
        )}
      </Box>
      <Box width="100%">
        {!isMobile ? (
          <Typography fontWeight="700" fontSize="14px" margin="15px 0px 0px">
            {feature.name}
          </Typography>
        ) : null}
        <Typography
          fontSize="11.1px"
          fontWeight="400"
          margin="0px 0px 15px"
          padding={!isMobile ? 0 : '0px 29px'}
        >
          {feature.descripcion || feature.description}
        </Typography>

        {isMobile ? (
          <Box display={'flex'} justifyContent={'center'}>
            <NumberTicketDesktop
              numberTicket={
                feature.deductive === 0
                  ? (feature.sum_assured === 'A' || feature.sum_assured === 'Muerte accidental del conductor')
                    ? 'AMPARADA'
                    : `$${parseFloat(
                        parseFloat(feature.sum_assured).toFixed(2)
                      ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}`
                  : `${numberTicket}%`
              }
              titleTicket={
                feature.deductive === 0
                  ? (feature.sum_assured === 'A' || feature.sum_assured === 'Muerte accidental del conductor')
                    ? ''
                    : 'COBERTURA DE HASTA'
                  : 'DEDUCIBLE'
              }
            />
          </Box>
        ) : null}
      </Box>
      {isDesktop && (
        <NumberTicketDesktop
          numberTicket={
            feature.deductive === 0
              ? (feature.sum_assured === 'A' || feature.sum_assured === 'Muerte accidental del conductor')
                ? 'AMPARADA'
                : `$${parseFloat(
                    parseFloat(feature.sum_assured).toFixed(2)
                  ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}`
              : `${numberTicket}%`
          }
          titleTicket={
            feature.deductive === 0
              ? (feature.sum_assured === 'A' || feature.sum_assured === 'Muerte accidental del conductor')
                ? ''
                : 'COBERTURA DE HASTA'
              : 'DEDUCIBLE'
          }
        />
      )}
    </Box>
  );
};

interface TicketInfo {
  numberTicket: string;
  titleTicket: string;
}

export const NumberTicketMobile: React.FC<TicketInfo> = ({
  numberTicket,
  titleTicket,
}) => {
  return (
    <Box
      style={{ backgroundColor: '#EEF1F5' }}
      width={'80%'}
      padding={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      margin="0px 20px"
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
        width={'100%'}
      >
        <Typography fontSize="12.5px" fontWeight="400">
          {titleTicket}
        </Typography>
        <Typography fontSize="15.5px" fontWeight="700" textAlign="center">
          {numberTicket}
        </Typography>
      </Box>
    </Box>
  );
};

export const NumberTicketDesktop: React.FC<TicketInfo> = ({
  numberTicket,
  titleTicket,
}) => {
  return (
    <Box
      style={{ backgroundColor: '#EEF1F5' }}
      width="225px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <TicketDecorator />
      <Box marginLeft={1}>
        <Typography fontSize={'10px'} fontWeight="400" textAlign="center">
          {titleTicket}
        </Typography>
        <Typography fontSize={'16px'} fontWeight="700" textAlign="center">
          {numberTicket}
        </Typography>
      </Box>
    </Box>
  );
};

export const TicketDecorator = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      position={'absolute'}
      left={0}
    >
      <Box component="img" width={'19px'} src={ArrowForm} />
    </Box>
  );
};
