import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
} from '@mui/material';

import { ZustandContext } from '@store/modalStore';
import { CustomButton } from '@buttons/CustomButton';
import { ModalDriverToSave } from './ModalDriverToSave';
import { ModalConfirmDetails } from '@modals/ModalConfirmDetails';

type ModalShareProps = {
  onClose: () => void;
  isDetail?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 360,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalChangeCP: React.FC<ModalShareProps> = ({
  onClose,
  isDetail = false,
}) => {
  const classes = useStyles();
  const { show, hide } = useContext(ZustandContext);
  
  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={2}>
            <Box width={'100%'} style={{ marginTop: '10px' }}>
              <Typography textAlign={"center"} variant={'h6'} fontSize={'20px'} fontWeight={'bold'}>
                {'Si cambias tus datos, se borrarán estos resultados'}
              </Typography>
            </Box>
            <Box marginTop={2}>
              <CustomButton
                text={'Cancelar'}
                onClick={onClose}
                color={'primary'}
                variant={'contained'}
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              />
            </Box>
            <Box marginTop={2}>
              <CustomButton
                text={'Modificar'}
                onClick={() =>
                  show(isDetail ? ModalConfirmDetails : ModalDriverToSave, {
                    onClose: hide,
                  })
                }
                color={'primary'}
                variant={'outlined'}
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
