import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Paper,
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { HeroActionSimple } from '@screens/home/hero/HeroActionSimple';
import Circles from '@assets/images/circles.png';
import CropCircles from '@assets/images/crop-circles.png';
import { HeroAction } from '@screens/home-alt/hero/HeroAction';

export const BenefitHero: React.FC = () => {
  const { breakpoints } = useTheme();
  const { pathname } = useLocation();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  return (
    <Container maxWidth={'lg'}>
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          marginTop: 50,
          marginBottom: 50,
          position: 'relative',
          minHeight: '100%',
        }}
      >
        <Box
          component={'img'}
          src={isTablet ? CropCircles : Circles}
          sx={{
            height: isTablet ? '14vh' : '100%',
          }}
          alt={'Circulos'}
          style={{
            opacity: 0.4,
            objectFit: 'contain',
            position: 'absolute',
            top: 0,
            right: isTablet ? 0 : '',
            bottom: !isTablet ? 0 : '',
            transform: isTablet ? '' : 'rotate(180deg)',
          }}
        />
        <Paper elevation={0} style={{ borderRadius: 8 }}>
          <Grid
            container
            style={{
              paddingLeft: isTablet ? 16 : isDesktop ? 82 : 24,
              paddingRight: isTablet ? 16 : isDesktop ? 82 : 24,
              paddingTop: isTablet ? 16 : 52,
              paddingBottom: isTablet ? 16 : 52,
            }}
          >
            <Grid item xs={12} md={8} alignSelf="center" style={{ zIndex: 1 }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
              >
                <Typography
                  variant={'h4'}
                  fontWeight={'bold'}
                  style={{ marginRight: isTablet ? 72 : 32 }}
                >
                  {'Ahorra hasta un 30% en tu seguro'}
                </Typography>
              </Box>
              {isTablet && <Box style={{ marginBottom: 40 }} />}
            </Grid>
            <Grid item xs={12} md={4} alignSelf="center">
              {pathname === '/' ? (
                <HeroActionSimple
                  title={'¿Qué vehículo quieres asegurar?'}
                  elevation={0}
                />
              ) : (
                <HeroAction title={'¿Cómo te llamas?'} elevation={0} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};
