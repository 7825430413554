import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EditIcon from '@mui/icons-material/Edit';
import {useLocation, useHistory, useRouteMatch} from 'react-router-dom';
import {
  AppBar,
  Link,
  Toolbar,
  Box,
  useTheme,
  useMediaQuery,
  Container,
  IconButton,
  useScrollTrigger,
  Theme,
  Menu,
  Fade,
  Divider,
  Typography,
  Button,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';
import { ModalHelp } from '@modals/ModalNeedHelp/ModalHelp';
import { ZustandContext } from '@store/modalStore';
import { ModalContact } from '@modals/ModalNeedHelp/ModalContact';
import { useCarStore, initialCarListData } from '@store/carInfoStore';
import { useSelectedQuoteStore, defaultQuote } from '@store/QuoteStore';
import { useDriverStore, initialDriver } from '@store/driverInfoStore';
import { ModalChangeVehicle } from '@modals/modalChangeVehicle';
import logoClubProteccionNavbar from '@assets/images/logoClubProteccionNavbar.svg';
import logoClubProteccionNavbarNew from '@assets/images/LogoNew.svg';
import logoCoppel from '@assets/images/LOGO_COPPEL.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: ({links}: any) => links ? 'space-around' : "space-between",
  },
  spacer: {
    marginLeft: theme.spacing(2),
  },
  menu: {
    padding: 0,
  },
  popOverPaper: {
    width: '100%',
    marginTop: 40,
  },
}));

export interface ILink {
  text: string;
  url: string;
}

export const Navbar: React.FC = () => {
  const { show, hide } = React.useContext(ZustandContext);
  const isRenovacionesRoute = useRouteMatch("/Renovaciones/:id");
  const [links, setLinks] = useState<Array<ILink> | []>([]);
  const classes = useStyles({links: links.length});
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { breakpoints, palette } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const { car } = useCarStore();
  const { driver, setDriver } = useDriverStore();
  const trigger = useScrollTrigger({
    threshold: 80,
    disableHysteresis: true,
  });
  const { setSelectedQuote } = useSelectedQuoteStore();
  const { setCar: setCarStore } = useCarStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElMobile, setAnchorElMobile] = useState<null | HTMLElement>(
    null
  );

  const goToHome = () => {
    setDriver({
      ...initialDriver,
      coppelEmployeeId: driver.coppelEmployeeId
    })
    sessionStorage.setItem('driverInfo-storate', JSON.stringify({
      ...initialDriver,
      coppelEmployeeId: driver.coppelEmployeeId
    }))
    setSelectedQuote(defaultQuote)
    sessionStorage.setItem('quote-storage', JSON.stringify(defaultQuote))
    setCarStore(initialCarListData)
    sessionStorage.setItem('carInfo-storage', JSON.stringify(initialCarListData))
    push('/')
  }

  useEffect(()=>{
    asyncLoad()
  },[])

  const asyncLoad = async () => {
    // const { data } =  axios.get('links')
    let tmpLinks = JSON.parse(sessionStorage.getItem('navBarLinks') + "");
    if(tmpLinks === "" || !tmpLinks)
      tmpLinks = []

    setLinks(tmpLinks)
  }

  const open = Boolean(anchorEl);
  const openMobile = Boolean(anchorElMobile);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMobile = () => setAnchorElMobile(null);
  const handleClickMobile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMobile(event.currentTarget);
  };

  const availablePath = (): boolean => {
    if (pathname === '/') {
      return false;
    } else if (pathname === '/hiring') {
      return true;
    } else if (pathname === '/confirm') {
      return false;
    } else if (pathname === '/payment') {
      return true;
    } else if (pathname === '/welcome') {
      return false;
    } else if (pathname === '/home-alt') {
      return false;
    } else if (pathname === '/quotation') {
      return false;
    }
    return true;
  };

  const isCurrentHome = (): boolean => {
    if (pathname === '/') {
      return false;
    } else if (pathname === '/home-alt') {
      return false;
    } else if (pathname === '/quotation') {
      return false;
    }
    return true;
  };
  return (
    <div className={classes.root} >
      <AppBar
        elevation={trigger || isCurrentHome() ? 4 : 0}
        position={'fixed'}
        style={{ background: '#FFFFFF' }}
      >
        {
          isRenovacionesRoute ?

          <Container maxWidth={'xl'}>
            <Toolbar
                style={{
                  paddingLeft: isMobile ? 0 : 16,
                  paddingRight: isMobile ? 0 : 16,
                  paddingBottom:8,
                  paddingTop: 8,

                  width: '100%',
                }}
            >
                  <div className={classes.logo}>
                    <Box
                        component={'img'}
                        src={logoClubProteccionNavbarNew}
                        sx={{
                          maxWidth: { xs: 80,sm:150, md: 200 },
                        }}
                        style={{
                          objectFit: 'contain',
                          cursor: 'pointer',
                        }}
                        alt={'Inter, seguro, seguro'}
                        onClick={goToHome}
                    />

                    <Box
                        component={'img'}
                        src={logoCoppel}
                        sx={{
                          maxWidth: { xs: 150,sm:200 },
                        }}
                        style={{
                          objectFit: 'contain',
                          cursor: 'pointer',
                        }}
                        alt={'Inter, seguro, seguro'}
                        onClick={goToHome}
                    />
                  </div>
            </Toolbar>

          </Container>

          :
          <Container maxWidth={'xl'}>
        <Toolbar
            style={{
              paddingLeft: isMobile ? 0 : 16,
              paddingRight: isMobile ? 0 : 16,
              width: '100%',
            }}
        >
          {/*start code for laptop ++ */}
          {!isTablet ? (
              <div className={classes.logo}>
                <Box
                  component={'img'}
                  src={logoClubProteccionNavbar}
                  sx={{
                    maxWidth: { xs: 130, md: 130 },
                  }}
                  style={{
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                  alt={'Inter, seguro, seguro'}
                  onClick={goToHome}
                />
                {/*Menu for navbar for laptop ++ ************************/}
                {!isTablet &&
                  links.map((link, i) => (
                    <div key={i}>
                      <CustomButton
                        text={link.text}
                        //@ts-ignore
                        onClick={(_) => {window.open(link.url, '_blank')}}
                        size={isDesktop ? 'medium' : 'small'}
                        style={{
                          color: palette.primary.main,
                          textTransform: 'none',
                          marginLeft: `${i === 0 && '303px'}`,
                          fontWeight: 600,
                          fontSize: '16px',
                          height: '75px',
                        }}
                        color={'inherit'}
                        variant={'text'}
                      />
                      {/* menu list for link insurances (seguros)********************/}
                      {/* <Menu
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px 15px',
                          }}
                        >
                          <Link
                            target="_blank"
                            href="https://medicoseguro.inter.mx/inicio"
                            style={{
                              textDecoration: 'none',
                              padding: '5px',
                              color: 'black',
                            }}
                          >
                            Médico Seguro
                          </Link>
                          <Link
                            target="_blank"
                            href="https://mascotasegura.inter.mx/seguro-mascota/landing"
                            style={{
                              textDecoration: 'none',
                              padding: '5px',
                              color: 'black',
                            }}
                          >
                            Mascota Segura
                          </Link>
                          <Link
                            target="_blank"
                            href="https://productos.inter.mx/bien-seguro"
                            style={{
                              textDecoration: 'none',
                              padding: '5px',
                              color: 'black',
                            }}
                          >
                            Bien Seguro
                          </Link>
                          <Link
                            target="_blank"
                            href="https://inter.mx/viaje-seguro"
                            style={{
                              textDecoration: 'none',
                              padding: '5px',
                              color: 'black',
                            }}
                          >
                            Viaja Seguro
                          </Link>
                        </Box>
                      </Menu> */}
                        </div>
                    ))}
                {/*Button need help for home*******************/}
                {!isCurrentHome() ? (
                    <CustomButton
                        text={'¿Necesitas ayuda?'}
                        onClick={() => {
                          if (pathname === '/') {
                            show(ModalHelp, { onClose: hide });
                            return;
                          }
                          show(ModalContact, { onClose: hide });
                        }}
                        size={isDesktop ? 'medium' : 'small'}
                        variant={'text'}
                        endIcon={<PhoneEnabledIcon />}
                        style={{
                          color: palette.primary.main,
                          fontSize: isMobile ? '0.64rem' : '',
                          textTransform: 'none',
                          fontWeight: 'normal',
                          textDecoration: 'underline',
                        }}
                    />
                ) : (
                    //Button need help diferent home*********************
                    <CustomButton
                        text={'¿Necesitas ayuda?'}
                        onClick={() => {

                          show(ModalContact, { onClose: hide });
                        }}
                        size={isDesktop ? 'medium' : 'small'}
                        variant={'contained'}
                        color={'primary'}
                        endIcon={<PhoneEnabledIcon />}
                        style={{
                          textTransform: 'capitalize',
                          fontWeight: 600,
                          fontSize: '13px',
                          padding: ' 13px 18.5px',
                          height: '50px',
                          alignSelf: 'center',
                        }}
                    />
                )}
              </div>
          ) : (
              //start code for tablet**************************
              <div className={classes.logo}>
                <Box
                    component={'img'}
                    src={logoClubProteccionNavbar}
                    sx={{
                      maxWidth: { xs: 130, md: 130 },
                    }}
                    style={{
                      objectFit: 'contain',
                      cursor: 'pointer',
                    }}
                    alt={'Inter, seguro, seguro'}
                    onClick={goToHome}
                />
                {/*Button need help for mobile and if is home */}
                {!isTablet || !isCurrentHome() ? (
                    <Box>
                      <Button
                          onClick={() => {
                            if (pathname === '/') {
                              show(ModalHelp, { onClose: hide });
                              return;
                            }
                            show(ModalContact, { onClose: hide });
                          }}
                          size={'small'}
                          color={'inherit'}
                          variant={'text'}
                          style={{
                            color: palette.primary.main,
                            fontSize: isMobile ? '0.74rem' : '',
                            textTransform: 'none',
                            fontWeight: 400,
                            textDecoration: 'underline',
                            height: '55px',
                          }}
                      >
                        {'¿Necesitas ayuda?'}
                      </Button>
                      {
                          links.length &&
                          <IconButton
                              edge="start"
                              aria-label="menu"
                              onClick={(e) => handleClickMobile(e)}
                              style={{
                                color: palette.primary.main,
                                marginLeft: 8,
                                padding: 0,
                              }}
                          >
                            {!openMobile ? <MenuIcon /> : <ClearIcon />}
                          </IconButton>
                      }

                    </Box>
                ) : availablePath() && pathname !== '/hiring' ? (
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                    >
                      <Box display={'flex'} flexDirection={'column'}>
                        <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'bold', color: '#424242' }}
                        >
                          {`${car.assembler}`}
                        </Typography>
                        <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'normal', color: '#424242' }}
                        >
                          {`${car.description}`}
                        </Typography>
                      </Box>
                      <Box>
                        <EditIcon
                            onClick={() =>
                                show(ModalChangeVehicle, { onClose: hide })
                            }
                            style={{ color: '#039ECC' }}
                        />
                      </Box>
                    </Box>
                ) : null}
                <Menu
                    open={openMobile}
                    anchorEl={anchorElMobile}
                    onClose={handleCloseMobile}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    MenuListProps={{
                      disablePadding: true,
                    }}
                    PaperProps={{
                      square: true,
                      elevation: 0,
                      style: {
                        borderRadius: 0,
                      },
                    }}
                    PopoverClasses={{
                      paper: classes.popOverPaper,
                    }}
                >
                  <Box padding={2}>
                    {links.map((link, i) => (
                        <div key={i}>
                          <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <CustomButton
                                text={link.text}
                                //@ts-ignore
                                onClick={(_) => {window.open(link.url, '_blank');}}
                                size={'medium'}
                                color={i === 0 ? 'inherit' : 'inherit'}
                                style={{
                                  width: '100%',
                                  textTransform: 'none',
                                  height: i === 0 ? undefined : undefined,
                                }}
                                variant={i === 0 ? 'text' : 'text'}
                            />
                            <Menu
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                            >
                              <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '10px 15px',
                                  }}
                              >
                                <Link
                                    target="_blank"
                                    href="https://medicoseguro.inter.mx/inicio"
                                    style={{
                                      textDecoration: 'none',
                                      padding: '5px',
                                      color: 'black',
                                    }}
                                >
                                  Médico Seguro
                                </Link>
                                <Link
                                    target="_blank"
                                    href="https://mascotasegura.inter.mx/seguro-mascota/landing"
                                    style={{
                                      textDecoration: 'none',
                                      padding: '5px',
                                      color: 'black',
                                    }}
                                >
                                  Mascota Segura
                                </Link>
                                <Link
                                    target="_blank"
                                    href="https://productos.inter.mx/bien-seguro"
                                    style={{
                                      textDecoration: 'none',
                                      padding: '5px',
                                      color: 'black',
                                    }}
                                >
                                  Bien Seguro
                                </Link>
                                <Link
                                    target="_blank"
                                    href="https://inter.mx/viaje-seguro"
                                    style={{
                                      textDecoration: 'none',
                                      padding: '5px',
                                      color: 'black',
                                    }}
                                >
                                  Viaja Seguro
                                </Link>
                              </Box>
                            </Menu>
                          </div>
                          <Divider />
                        </div>
                    ))}
                  </Box>
                </Menu>
              </div>
          )}
        </Toolbar>
        {/*Button need help for tablet*/}
        {availablePath() && isTablet && pathname !== '/payment' ? (
            <Box
                width={'100%'}
                display={'flex'}
                height={46}
                justifyContent={'center'}
                flexDirection={'column'}
                alignItems={'center'}
                borderTop={'1px solid #c7c7c7'}
            >
              <CustomButton
                  text={'¿Necesitas ayuda?'}
                  onClick={() => {
                    if (pathname === '/' || pathname === '/options') {
                      show(ModalHelp, { onClose: hide });
                      return;
                    }
                    show(ModalContact, { onClose: hide });
                  }}
                  size={isDesktop ? 'medium' : 'small'}
                  variant={'text'}
                  endIcon={<PhoneEnabledIcon />}
                  style={{
                    color: palette.primary.main,
                    fontSize: isMobile ? '0.64rem' : '',
                    textTransform: 'none',
                    fontWeight: 'normal',
                    textDecoration: 'underline',
                  }}
              />
            </Box>
        ) : null}
      </Container>
        }
      </AppBar>
    </div>
  );
};
