import create from 'zustand';
import { persist } from 'zustand/middleware';

import { business } from '../utils/constants';
import { getTracking } from '@api/getTracking';

interface Tracking {
  id: string;
  business: string;
  data: {
    demo: number;
  };
}

interface TrackingStore {
  tracking: Tracking;
  setTracking: (token: string) => Promise<void>;
  setDataTracking: (data: any) => Promise<void>;
}

const initialData = {
  id: '0',
  business,
  data: {
    demo: 1,
  },
};

export const useTrackingStore = create<TrackingStore>(
  persist(
    (set) => ({
      tracking: initialData,
      setTracking: async (token) => {
        let dataInicial = { business: 'coppel-hdi' };
        const { data } = await getTracking(token, dataInicial);
        set({ tracking: data });
      },
      setDataTracking: async (data) => {
        set({ tracking: data });
      },
    }),
    {
      name: 'tracking-storage',
      getStorage: () => sessionStorage,
    }
  )
);
