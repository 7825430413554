import React from 'react';
import { Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

interface ButtonModalPayProps {
  handleGoPayment: () => void;
  isValidForm: Function;
}

export const ButtonModalPay: React.FC<ButtonModalPayProps> = ({
  handleGoPayment,
  isValidForm,
}) => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  return (
    <Box width="100%" marginTop="25px">
      <Button
        variant="contained"
        size={'large'}
        disabled={!isValidForm()}
        onClick={handleGoPayment}
        style={
          isDesktop
            ? { width: '100%', textTransform: 'none' }
            : { width: '100%', textTransform: 'none' }
        }
      >
        <span style={{ marginRight: '7px' }}>Siguiente</span>
        <ArrowForward />
      </Button>
    </Box>
  );
};
