import create from 'zustand';
import { persist } from 'zustand/middleware';

interface Driver {
  firstName: string;
  middleName: string;
  lastName: string;
  RFC?: string;
  colonia?: string;
  street?: string;
  estado?: string;
  stateCode?: string;
  suburbCode?: string;
  numeroExterior?: string;
  numeroInterior?: string;
  zip?: string;
}

interface Contrator {
  firstName: string;
  middleName: string;
  lastName: string;
  fechaNacimiento: Date | null | string;
  RFC: string;
  email: string;
  telefono: string;
  genero: string;
}

interface PolicyInfo {
  policyNumber: string;
  code: string;
  employee_id: string;
  no_poliza: string;
  url_poliza: string;
  order: string;
  url_recibo: string;
  stage: string;
  sub_stage: string;
  status_code: string;
  state_code: string;
  package: string;
  payment_option: string;
  package_anterior: string;
  payment_anterior: string;
  package_new: string;
  payment_new: string;
  package_label: string;
  payment_label: string;
  error: boolean;
  basico: boolean;
  Niv: string;
  plate: string;
  checks: {
    first: boolean;
    second: boolean;
    third: boolean;
    fourth: boolean;
  };
  bill: string;
  regimen: string;
  regimenFiscal: string;
  driver: Driver;
  dataPromotion?: {
    name: string;
    option: string;
    email?: string;
    telefono?: string;
  };
  contrator?: Contrator;
  street: string;
  numeroExterior: string;
  numeroInterior: string;
  colonia: string;
  colonia_name?: string;
  estado_name?: string;
  estado: string;
  selectorDriver: string;
  taxResidence: {
    zip: string;
    street: string;
    numeroExterior: string;
    numeroInterior: string;
    colonia_name?: string;
    estado_name?: string;
    colonia: string;
    estado: string;
    city?: string;
  };
}

interface Policy {
  policy: PolicyInfo;
  addPolicyInfo: (policy: PolicyInfo) => void;
}

const initialDriverData = {
  firstName: '',
  middleName: '',
  lastName: '',
  RFC: '',
};

export const usePolicyStore = create<Policy>(
  //@ts-ignore
  persist(
    (set) => ({
      policy: {
        policyNumber: '0001',
        code:'',
        employee_id:'',
        stage: '',
        sub_stage: '',
        status_code: '',
        state_code: '',
        order: 'Todas las opciones',
        no_poliza: '',
        url_poliza: '',
        url_recibo: '',
        package:'AMP',
        payment_option: 'A',
        package_anterior: 'AMP',
        payment_anterior: 'A',
        package_new: 'AMP',
        payment_new: 'A',
        package_label: 'Plan Amplio',
        payment_label: 'Anual',
        error: false,
        basico: true,
        Niv: '',
        plate: '',
        checks: {
          first: false,
          second: false,
          third: false,
          fourth: false,
        },
        regimenFiscal: 'Sin obligaciones fiscales',
        driver: initialDriverData,
        street: '',
        colonia_name: '',
        estado_name: '',
        numeroExterior: '',
        numeroInterior: '',
        colonia: '',
        estado: '',
        selectorDriver: '',
        taxResidence: {
          zip: '',
          street: '',
          numeroExterior: '',
          numeroInterior: '',
          colonia_name: '',
          estado_name: '',
          colonia: '',
          estado: '',
          city: '',
        },
      },
      addPolicyInfo: (policy) => {
        set({
          policy,
        });
      },
    }),
    {
      name: 'policy-storage',
      getStorage: () => sessionStorage,
    }
  )
);
