import React from 'react';
import { DetailHeader } from './DetailHeader';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { Quotas } from './Quotas';

export const DetailsConfirm = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  return (
    <Box
      style={{
        borderBottomStyle: 'dashed',
        borderBottom: '1px dashed #5E6A71',
      }}
      padding={`${isMobile ? '14px' : '0px'}`}
      paddingBottom={2}
    >
      <DetailHeader />
      <Quotas />
    </Box>
  );
};
