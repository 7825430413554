import React, { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  useTheme,
  useMediaQuery,
} from '@mui/material';

type PlanProps = {
  plan: {
    title: string;
    details: string[];
  };
};

export const Plan: React.FC<PlanProps> = ({ plan }) => {
  const { palette } = useTheme();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const [expanded, setExpanded] = useState<boolean>(
    plan.title === 'Amplia' || isDesktop ? true : false
  );

  return (
    <Grid item xs={12} md={4} display={'flex'}>
      <Accordion
        elevation={0}
        style={{ borderRadius: 8, width: '100%' }}
        expanded={isTablet ? expanded : true}
        onChange={() => isTablet && setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={isTablet ? <ExpandMore color={'primary'} /> : null}
          aria-controls={plan.title + '-content'}
          id={plan.title + '-header'}
          style={{cursor: isTablet ? "pointer" : "auto"}}
        >
          <Typography variant={'subtitle1'} fontWeight={'bold'}>
            {plan.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {plan.details.map((text, i) => (
            <div key={i} style={{ marginBottom: 10 }}>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <CheckCircleIcon style={{ color: palette.primary.main }} />
                <div style={{ marginRight: 10 }} />
                <Typography>{text}</Typography>
              </Box>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
