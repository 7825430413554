import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ExpandMore, Info } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
} from '@mui/material';

import { Header } from '@navigationMenu/Header';
import { CardComparation } from '@screens/comparation/card/CardComparation';
import { CardCompareMobile } from '@screens/comparation/card/CardCompareMobile';
import { DigicertField } from '@navigationMenu/DigicertField';
import circleBack1 from '@assets/images/Circleds.png';
import circleBack2 from '@assets/images/Circlesl.png';
import { usePlansStore } from '@store/plansStore';
import { Benefit } from '@models/ui/Benefit';
import { useChangePrice } from '@hooks/useChangePrice';

const useStyles = makeStyles({
  root: {
    background: '#f5f5f5',
  },
});

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    backgroundColor: '#EBF8FB',
    color: 'black',
    padding: '20px',
    fontSize: '14px',
  },
});

interface stateType {
  plan: string;
}

export const Comparation: React.FC = () => {
  const history = useHistory();
  const location = useLocation<stateType>();

  const { plans } = usePlansStore();

  const classes = useStyles();
  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery(breakpoints.down('md'));

  const [payWay, setPayWay] = useState<string>('De contado');
  const [coverage, setCoverage] = useState(location.state.plan);
  const [materialDamageInfo, setMaterialDamageInfo] = useState<boolean>(false);
  const [loseTotalInfo, setLoseTotalInfo] = useState<boolean>(false);
  const [civilR, setCivilR] = useState<boolean>(false);
  const [medicalExp, setMedicalExp] = useState<boolean>(false);
  const [legalDef, setLegalDef] = useState<boolean>(false);
  const [travelAssis, setTravelAssis] = useState<boolean>(false);

  const handleMaterialDamageInfo = () => {
    setMaterialDamageInfo(!materialDamageInfo);
    setLoseTotalInfo(false);
    setCivilR(false);
    setMedicalExp(false);
    setLegalDef(false);
    setTravelAssis(false);
  };
  const handleLoseTotalInfo = () => {
    setLoseTotalInfo(!loseTotalInfo);
    setMaterialDamageInfo(false);
    setCivilR(false);
    setMedicalExp(false);
    setLegalDef(false);
    setTravelAssis(false);
  };
  const handleCivilR = () => {
    setCivilR(!civilR);
    setLoseTotalInfo(false);
    setMaterialDamageInfo(false);
    setMedicalExp(false);
    setLegalDef(false);
    setTravelAssis(false);
  };
  const handleMedicalExp = () => {
    setMedicalExp(!medicalExp);
    setCivilR(false);
    setLoseTotalInfo(false);
    setMaterialDamageInfo(false);
    setLegalDef(false);
    setTravelAssis(false);
  };
  const handleLegalDef = () => {
    setLegalDef(!legalDef);
    setMedicalExp(false);
    setCivilR(false);
    setLoseTotalInfo(false);
    setMaterialDamageInfo(false);
    setTravelAssis(false);
  };
  const handleTravelAssis = () => {
    setTravelAssis(!travelAssis);
    setLegalDef(false);
    setMedicalExp(false);
    setCivilR(false);
    setLoseTotalInfo(false);
    setMaterialDamageInfo(false);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useChangePrice();

  return (
    <div className={classes.root}>
      <Box
        component={'img'}
        src={circleBack1}
        right={0}
        sx={{
          width: '20%',
        }}
        position={'absolute'}
        top={200}
        alt={'Circle Background'}
      />
      <Box
        component={'img'}
        src={circleBack2}
        sx={{
          width: '25%',
        }}
        position={'absolute'}
        top={700}
        alt={'Circle Background'}
      />
      <Container maxWidth={'lg'}>
        <Box marginTop={isTablet ? 15 : 10} paddingBottom={10}>
          <Header title={'¡Ahora te toca elegir el plan que más te guste!'} />

          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={isTablet ? 12 : 3}>
              {isTablet ? (
                <Box>
                  <Button
                    variant={'text'}
                    startIcon={<ArrowBackIcon />}
                    style={{
                      textTransform: 'none',
                      color: '#039ECC',
                      textDecoration: 'underline',
                      outline: 'none',
                    }}
                  >
                    Regresar al cotizador
                  </Button>
                  <Box marginTop={2}>
                    <FormControl fullWidth>
                      <InputLabel>Modalidad de pago</InputLabel>
                      <Select
                        value={payWay}
                        label="Modalidad de pago"
                        onChange={(e) => setPayWay(e.target.value)}
                        className={classes.root}
                        style={{ borderRadius: 8 }}
                        IconComponent={(props) => (
                          <ExpandMore style={{ color: '#039ECC' }} {...props} />
                        )}
                      >
                        {[
                          'De contado',
                          'Semestral',
                          'Trimestral',
                          'Mensual',
                        ].map((value, i) => (
                          <MenuItem key={i} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box marginTop={2}>
                    <FormControl fullWidth>
                      <InputLabel>Plan</InputLabel>
                      <Select
                        value={coverage}
                        label="Plan"
                        onChange={(e) => setCoverage(e.target.value)}
                        className={classes.root}
                        style={{ borderRadius: 8 }}
                        IconComponent={(props) => (
                          <ExpandMore style={{ color: '#039ECC' }} {...props} />
                        )}
                      >
                        {['Plan amplio', 'Plan limitado', 'Plan básico'].map(
                          (value, i) => (
                            <MenuItem key={i} value={value}>
                              {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Button
                    onClick={() => history.push('/plans')}
                    variant={'text'}
                    startIcon={<ArrowBackIcon />}
                    style={{
                      textTransform: 'none',
                      color: '#039ECC',
                      textDecoration: 'underline',
                    }}
                  >
                    Regresar al cotizador
                  </Button>
                  <Box marginTop={20}>
                    <FormControl fullWidth>
                      <InputLabel>Modalidad de pago</InputLabel>
                      <Select
                        value={payWay}
                        label="Modalidad de pago"
                        onChange={(e) => setPayWay(e.target.value)}
                        className={classes.root}
                        style={{ borderRadius: 8 }}
                        IconComponent={(props) => (
                          <ExpandMore style={{ color: '#039ECC' }} {...props} />
                        )}
                      >
                        {[
                          'De contado',
                          'Semestral',
                          'Trimestral',
                          'Mensual',
                        ].map((value, i) => (
                          <MenuItem key={i} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box marginTop={3}>
                    <FormControl fullWidth>
                      <InputLabel>Plan</InputLabel>
                      <Select
                        value={coverage}
                        label="Plan"
                        onChange={(e) => setCoverage(e.target.value)}
                        className={classes.root}
                        style={{ borderRadius: 8 }}
                        IconComponent={(props) => (
                          <ExpandMore style={{ color: '#039ECC' }} {...props} />
                        )}
                      >
                        {['Plan amplio', 'Plan limitado', 'Plan básico'].map(
                          (value, i) => (
                            <MenuItem key={i} value={value}>
                              {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    marginTop={3}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      width={'100%'}
                      padding={1}
                    >
                      <Typography
                        textAlign={'center'}
                        variant={'caption'}
                        fontSize={'16px'}
                        fontWeight={'bold'}
                        style={{ color: '#424242' }}
                        marginLeft={2}
                      >
                        {'Daños materiales'}
                      </Typography>
                      <CustomWidthTooltip
                        placement="right"
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setMaterialDamageInfo(false)}
                        open={materialDamageInfo}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <Box>
                            <Box>
                              <Typography fontSize={'14px'} fontWeight={'bold'}>
                                {'Daños materiales'}
                              </Typography>
                            </Box>
                            <Box marginTop={1}>
                              <Typography
                                fontSize={'13px'}
                                fontWeight={'normal'}
                              >
                                {
                                  '¿Tuviste un accidente vial, pasaste por un disturbio social o hubo un desastre natural que dañó tu vehículo? No te preocupes, tu seguro lo cubre.'
                                }
                              </Typography>
                            </Box>
                          </Box>
                        }
                      >
                        <Info
                          onClick={handleMaterialDamageInfo}
                          style={{ color: '#039ECC', cursor: 'pointer' }}
                        />
                      </CustomWidthTooltip>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    marginTop={3}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      width={'100%'}
                      padding={1}
                    >
                      <Typography
                        textAlign={'center'}
                        variant={'caption'}
                        fontSize={'16px'}
                        fontWeight={'bold'}
                        style={{ color: '#424242' }}
                        marginLeft={2}
                      >
                        {'Robo total'}
                      </Typography>
                      <CustomWidthTooltip
                        placement="right"
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setLoseTotalInfo(false)}
                        open={loseTotalInfo}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <Box>
                            <Box>
                              <Typography fontSize={'14px'} fontWeight={'bold'}>
                                {'Robo total'}
                              </Typography>
                            </Box>
                            <Box marginTop={1}>
                              <Typography
                                fontSize={'13px'}
                                fontWeight={'normal'}
                              >
                                {
                                  'La peor sorpresa, ¿¡te robaron tu vehículo!? No te preocupes, tu seguro te cubre su valor comercial o de la factura dependiendo de su antigüedad.'
                                }
                              </Typography>
                            </Box>
                          </Box>
                        }
                      >
                        <Info
                          onClick={handleLoseTotalInfo}
                          style={{ color: '#039ECC', cursor: 'pointer' }}
                        />
                      </CustomWidthTooltip>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    marginTop={3}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      width={'100%'}
                      padding={1}
                    >
                      <Typography
                        textAlign={'center'}
                        variant={'caption'}
                        fontSize={'16px'}
                        fontWeight={'bold'}
                        style={{ color: '#424242' }}
                        marginLeft={2}
                      >
                        {'Responsabilidad civil'}
                      </Typography>
                      <CustomWidthTooltip
                        placement="right"
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setCivilR(false)}
                        open={civilR}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <Box>
                            <Box>
                              <Typography fontSize={'14px'} fontWeight={'bold'}>
                                {'Responsabilidad civil'}
                              </Typography>
                            </Box>
                            <Box marginTop={1}>
                              <Typography
                                fontSize={'13px'}
                                fontWeight={'normal'}
                              >
                                {
                                  '¿Hiciste algún daño con tu vehículo a alguien más o sus bienes en un accidente vial? Sucede, y tu seguro lo cubre.'
                                }
                              </Typography>
                            </Box>
                          </Box>
                        }
                      >
                        <Info
                          onClick={handleCivilR}
                          style={{ color: '#039ECC', cursor: 'pointer' }}
                        />
                      </CustomWidthTooltip>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    marginTop={3}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      width={'100%'}
                      padding={1}
                    >
                      <Typography
                        textAlign={'center'}
                        variant={'caption'}
                        fontSize={'16px'}
                        fontWeight={'bold'}
                        style={{ color: '#424242' }}
                        marginLeft={2}
                      >
                        {'Gastos médicos'}
                      </Typography>
                      <CustomWidthTooltip
                        placement="right"
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setMedicalExp(false)}
                        open={medicalExp}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <Box>
                            <Box>
                              <Typography fontSize={'14px'} fontWeight={'bold'}>
                                {'Gastos médicos'}
                              </Typography>
                            </Box>
                            <Box marginTop={1}>
                              <Typography
                                fontSize={'13px'}
                                fontWeight={'normal'}
                              >
                                {
                                  '¿Tuviste un accidente vial o te asaltaron y necesitas asistencia médica? No pasa nada, tu seguro cubre el gasto.'
                                }
                              </Typography>
                            </Box>
                          </Box>
                        }
                      >
                        <Info
                          onClick={handleMedicalExp}
                          style={{ color: '#039ECC', cursor: 'pointer' }}
                        />
                      </CustomWidthTooltip>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    marginTop={3}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      width={'100%'}
                      padding={1}
                    >
                      <Typography
                        textAlign={'center'}
                        variant={'caption'}
                        fontSize={'16px'}
                        fontWeight={'bold'}
                        style={{ color: '#424242' }}
                        marginLeft={2}
                      >
                        {'Defensa legal'}
                      </Typography>
                      <CustomWidthTooltip
                        placement="right"
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setLegalDef(false)}
                        open={legalDef}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <Box>
                            <Box>
                              <Typography fontSize={'14px'} fontWeight={'bold'}>
                                {'Defensa legal'}
                              </Typography>
                            </Box>
                            <Box marginTop={1}>
                              <Typography
                                fontSize={'13px'}
                                fontWeight={'normal'}
                              >
                                {
                                  '¿Tuviste que llamar abogados? Tu seguro te cubre los gastos legales.'
                                }
                              </Typography>
                            </Box>
                          </Box>
                        }
                      >
                        <Info
                          onClick={handleLegalDef}
                          style={{ color: '#039ECC', cursor: 'pointer' }}
                        />
                      </CustomWidthTooltip>
                    </Box>
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    marginTop={3}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      width={'100%'}
                      padding={1}
                    >
                      <Typography
                        textAlign={'center'}
                        variant={'caption'}
                        fontSize={'16px'}
                        fontWeight={'bold'}
                        style={{ color: '#424242' }}
                        marginLeft={2}
                      >
                        {'Asistencia en viajes'}
                      </Typography>
                      <CustomWidthTooltip
                        placement="right"
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setTravelAssis(false)}
                        open={travelAssis}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                          <Box>
                            <Box>
                              <Typography fontSize={'14px'} fontWeight={'bold'}>
                                {'Asistencia en viajes'}
                              </Typography>
                            </Box>
                            <Box marginTop={1}>
                              <Typography
                                fontSize={'13px'}
                                fontWeight={'normal'}
                              >
                                {
                                  'Puedes viajar tranquilamente, porque pase lo que pase, tu seguro te cubre los gastos de asistencia vial.'
                                }
                              </Typography>
                            </Box>
                          </Box>
                        }
                      >
                        <Info
                          onClick={handleTravelAssis}
                          style={{ color: '#039ECC', cursor: 'pointer' }}
                        />
                      </CustomWidthTooltip>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
            {!isTablet ? (
              plans.map((i: Benefit) => (
                <Grid item md={3} lg={3} style={{ zIndex: 1 }}>
                  <CardComparation
                    cardPlan={i}
                    pay={payWay}
                    coverage={coverage}
                  />
                </Grid>
              ))
            ) : isTablet && plans.length < 3 ? (
              <Grid
                item
                xs={12}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <CardCompareMobile data={plans} pay={payWay} />
              </Grid>
            ) : (
              history.push('/plans')
            )}
          </Grid>
        </Box>
      </Container>
      {isTablet ? <DigicertField /> : null}
    </div>
  );
};
