import create from 'zustand';
import { persist } from 'zustand/middleware';

interface UrlIdStore {
  id: string;
  setId: (id: string) => void;
}

export const useIdStore = create<UrlIdStore>(
  persist(
    (set) => ({
        id: '',
        setId: (id: string) => {
        set({ id });
      },
    }),
    {
      name: 'url-id-storage',
      getStorage: () => sessionStorage,
    }
  )
);
