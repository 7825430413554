import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Theme, useMediaQuery } from '@mui/material';

import { Benefit } from '@screens/benefits/options/Benefit';
import { Promotion } from '@screens/benefits/options/Promotion';
import { Empty } from '@screens/benefits/compare/Empty';
import { BenefitMini } from '@screens/benefits/options/BenefitMini';
import { Compare } from '@screens/benefits/compare/Compare';
import { Coverage } from '@screens/benefits/compare/Coverage';
import { BenefitDetails } from '@screens/benefits/options/BenefitDetails';
import { BenefitsActions } from '@screens/benefits/options/BenefitsActions';
import { CompareMini } from '@screens/benefits/compare/CompareMini';
import { benefitsData } from '@api/benefits';
import { Benefit as BenefitModel } from '@models/ui/Benefit';

type BenefitsProps = {
  step: number;
  setStep: (value: number) => void;
};

export const Benefits: React.FC<BenefitsProps> = ({ step, setStep }) => {
  const history = useHistory();
  const [state, setState] = useState<{
    items: BenefitModel[];
    selected: BenefitModel[];
  }>({
    items: [],
    selected: [],
  });
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const { items, selected } = state;

  useEffect(() => {
    if (isTablet && items.length === 3) {
      setState((prev) => ({
        ...prev,
        items: items.filter((_, index) => index < items.length - 1),
      }));
    }
    if (isTablet && selected.length === 3) {
      setState((prev) => ({
        ...prev,
        selected: selected.filter((_, index) => index < selected.length - 1),
      }));
    }
  }, [isTablet, items, selected]);

  const addItem = (item: BenefitModel): void => {
    if (!isTablet) {
      if (items.length <= 2 && !items.includes(item)) {
        setState({ ...state, items: [...items, item] });
      } else {
        remove(item);
      }
    } else {
      if (items.length <= 1 && !items.includes(item)) {
        setState({ ...state, items: [...items, item] });
      } else {
        remove(item);
      }
    }
  };

  const remove = (item: BenefitModel): void => {
    setState({
      ...state,
      items: items.filter((el) => el.title !== item.title),
    });
  };

  const save = (): void => {
    if (step === 0) {
      setStep(1);
      setState({ ...state, selected: items });
    } else if (step === 1) {
      setStep(2);
    }
  };

  const onPrev = (): void => {
    if (step === 1) {
      setStep(0);
      setState({ ...state, selected: [] });
    } else if (step === 2) {
      setStep(1);
    } else {
      history.push('/plans');
    }
  };

  const reset = (item: BenefitModel): void => {
    setStep(0);
    setState({
      ...state,
      selected: [],
      items: items.filter((el) => el.title !== item.title),
    });
  };

  if (step !== 2) {
    return (
      <Grid item lg={8} md={12} xs={12}>
        <Grid
          container
          paddingY={2}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {selected.length !== 0
            ? selected.map((item, i) => (
                <Benefit
                  key={i}
                  benefit={item}
                  onClick={() => null}
                  selected={items.includes(item)}
                />
              ))
            : benefitsData.map((item, i) => (
                <Benefit
                  key={i}
                  benefit={item}
                  onClick={() => addItem(item)}
                  selected={items.includes(item)}
                />
              ))}
        </Grid>
        <BenefitsActions
          step={step}
          onNext={() => save()}
          onPrev={() => onPrev()}
          disabled={items.length >= 2}
        />
      </Grid>
    );
  }

  if (!isTablet) {
    return (
      <Grid item xs={12}>
        <Grid container paddingTop={2}>
          <Empty />
          {selected.map((item, i) => (
            <BenefitDetails
              key={i}
              benefit={item}
              reset={() => reset(item)}
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopRightRadius: i === selected.length - 1 ? 8 : 0,
              }}
            />
          ))}
        </Grid>
        <Grid container paddingBottom={2}>
          <Coverage />
          {selected.map((item, i) => (
            <Compare
              key={i}
              benefit={item}
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: i === selected.length - 1 ? 8 : 0,
                borderTopRightRadius: 0,
              }}
            />
          ))}
        </Grid>
        <BenefitsActions
          step={step}
          onNext={() => save()}
          onPrev={() => onPrev()}
          disabled={items.length >= 2}
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Grid container paddingY={2}>
        {selected.map((item, i) => (
          <BenefitMini
            key={i}
            benefit={item}
            reset={() => reset(item)}
            alignItems={i === 0 ? 'flex-start' : 'flex-end'}
          />
        ))}
        <Grid item xs={12}>
          {selected.every(
            (el) => el.promotion.trim() === selected[0].promotion.trim()
          ) && <Promotion promotion={selected[0].promotion} />}
        </Grid>
        <CompareMini selected={selected} />
      </Grid>
      <BenefitsActions
        step={step}
        onNext={() => save()}
        onPrev={() => onPrev()}
        disabled={items.length >= 2}
      />
    </Grid>
  );
};
