import React from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

type CardCalculatorProps = {
  logo: string;
  value: number;
};

export const CardCalculator: React.FC<CardCalculatorProps> = ({
  logo,
  value,
}) => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  return (
    <Box width={isDesktop ? '410px' : '100%'}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box
          component={'img'}
          src={logo ? logo : 'NO DATA'}
          sx={{
            width: 130,
          }}
          alt={logo}
          style={{ objectFit: 'contain' }}
        />
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'flex-end'}
        >
          <Typography
            textAlign={'end'}
            variant={'caption'}
            style={{ fontWeight: 'normal', color: '#424242' }}
          >
            {'Costo anual: '}
          </Typography>
          <Typography
            variant={'subtitle2'}
            style={{ fontWeight: 'bold', color: '#424242' }}
            textAlign={'start'}
          >
            {value ? `$ ${value}` : 'NO DATA'}
          </Typography>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Button
          variant={'contained'}
          disableElevation
          size={'small'}
          style={{
            backgroundColor: '#e0f7fa',
            textTransform: 'none',
            color: '#2979ff',
          }}
        >
          Amplia
        </Button>
        <Typography
          variant={'caption'}
          style={{ fontWeight: 'normal' }}
          textAlign={'start'}
        >
          {'meses sin intereses'}
        </Typography>
      </Box>
      <Box marginTop={3}></Box>
    </Box>
  );
};
