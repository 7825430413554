import React from 'react';
import { Box, Typography } from '@mui/material';

export const Promotion = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      marginTop={3}
      style={{
        background: 'rgba(3, 158, 204, 0.08)',
        marginBottom: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingRight: '16px',
        paddingLeft: '16px',
        borderRadius: '6px',
      }}
      width={'200px'}
    >
      <Typography
        textAlign={'start'}
        variant={'caption'}
        fontSize={'13px'}
        style={{ color: '#757575' }}
      >
        {'Promoción'}
      </Typography>
      <Typography
        textAlign={'start'}
        variant={'caption'}
        fontSize={'13px'}
        fontWeight={'bold'}
        style={{ color: '#212121' }}
      >
        {'Meses sin intereses con tar...'}
      </Typography>
    </Box>
  );
};
