import React from 'react';
import { Grid, Theme, useMediaQuery } from '@mui/material';

import { UserForm } from '@screens/result/details/form/UserForm';
import { CarInfo } from '@screens/result/details/CarInfo';

export const ResultLayout: React.FC = () => {
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <Grid item>
      <Grid container paddingY={2} spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} lg={8} md={8}>
          <UserForm />
        </Grid>
        {!isTablet && (
          <Grid item xs={12} lg={4} md={4}>
            <CarInfo />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
