import create from 'zustand';
import { persist } from 'zustand/middleware';

interface QuotePayment {
  Packages: {
    value: string;
    label: string;
  };
  payment_option: {
    value: string;
    label: string;
  };
  setPackage: (label: string, value: string) => void;
  setPaymentOption: (label: string, value: string) => void;
}
const initialData = {
  package: {
    value: 'AMP',
    label: 'Plan Amplio',
  },
  payment_option: {
    value: 'A',
    label: 'Anual',
  },
};

export const useGetQuotePayment = create<QuotePayment>(
  persist(
    (set, get) => ({
      Packages: initialData.package,
      payment_option: initialData.payment_option,
      setPackage: (label: string, value: string) =>
        set({
          Packages: {
            value,
            label,
          },
        }),
      setPaymentOption: (label: string, value: string) =>
        set({
          payment_option: {
            value,
            label,
          },
        }),
      getPackage: () => get().Packages,
      getPaymentOption: () => get().payment_option,
    }),
    {
      name: 'quotePayment-storage',
      getStorage: () => sessionStorage,
    }
  )
);
