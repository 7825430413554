import React, { useState } from 'react';
import { Benefit } from '@models/ui/Benefit';
import { Grid } from '@mui/material';

//import component
import CarSelectInfo from './CarSelectInfo';
import DriverSelectInfo from './DriverSelectInfo';
import PlanSelect from './PlanSelect';
import SelectWrapperDesktop from './SelectWrapperDesktop';
import QuoteWrapper from './QuoteWrapper';
import QuoteHeader from './QuoteHeader';
import QuoteContainer from './QuoteContainer';

interface Props {
  coverage: string;
  setCoverage: React.Dispatch<React.SetStateAction<string>>;
}

export const CoverageForm = ({ coverage, setCoverage }: Props) => {
  const [options, setOptions] = useState<string>('Todas las opciones');

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 2, lg: 3 }}>
      <SelectWrapperDesktop>
        <CarSelectInfo />
        <PlanSelect
          setCoverage={setCoverage}
          options={options}
          setOptions={setOptions}
        />
        <DriverSelectInfo />
      </SelectWrapperDesktop>
      <QuoteWrapper>
        <QuoteHeader options={options} />
        <QuoteContainer />
      </QuoteWrapper>
    </Grid>
  );
};
