import React from 'react';
import { Edit } from '@mui/icons-material';
import { Paper, Box, Typography, IconButton } from '@mui/material';
import { useCarStore } from '@store/carInfoStore';

import BannerVehicle from '@assets/images/image_55.png';

export const CarInfo: React.FC = () => {
  const { car } = useCarStore();
  return (
    <Paper elevation={0} style={{ borderRadius: 8 }}>
      <Box padding={2}>
        <Box
          marginBottom={3}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography fontSize={16} fontWeight={'600'} textAlign={'start'}>
            {'Vehículo'}
          </Typography>
          <IconButton>
            <Edit />
          </IconButton>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography
            variant={'subtitle2'}
            fontWeight={'400'}
            textAlign={'start'}
            fontSize={14}
          >
            {'Marca/Modelo:'}
          </Typography>
          <Typography
            variant={'subtitle2'}
            fontWeight={'600'}
            textAlign={'end'}
            fontSize={14}
          >
            {`${car.assembler}`}
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Typography textAlign={'end'} fontSize={12}>
            {`${car.description}`}
          </Typography>
        </Box>
        <Box
          paddingY={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography fontSize={14} fontWeight={'400'}>
            {'Año:'}
          </Typography>
          <Typography fontSize={14} fontWeight={'600'}>
            {'2021'}
          </Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          marginTop={4}
        >
          <Box
            component={'img'}
            src={BannerVehicle}
            sx={{
              width: 220,
            }}
            alt={BannerVehicle}
            style={{ objectFit: 'contain' }}
          />
        </Box>
      </Box>
    </Paper>
  );
};
