import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';

type CompareRowProps = {
  title: string;
  subtitle: string;
  hasIcons?: boolean;
  leftValue: string | boolean;
  rightValue: string | boolean;
};

export const CompareRow: React.FC<CompareRowProps> = ({
  title,
  subtitle,
  leftValue,
  rightValue,
  hasIcons = false,
}) => {
  const { palette } = useTheme();

  return (
    <Box
      padding={2}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      {!hasIcons ? (
        <Typography
          paddingRight={0.2}
          fontWeight={'600'}
          fontSize={12}
          textAlign={'start'}
        >
          {leftValue}
        </Typography>
      ) : rightValue ? (
        <CheckCircle
          sx={{ fontSize: 33 }}
          style={{ color: palette.success.main }}
        />
      ) : (
        <Cancel sx={{ fontSize: 33 }} style={{ color: palette.error.main }} />
      )}
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'40%'}
        paddingX={0.2}
      >
        <Typography
          textAlign={'center'}
          fontWeight={'600'}
          fontSize={14}
          marginBottom={0.4}
        >
          {title}
        </Typography>
        <Typography textAlign={'center'} fontWeight={'400'} fontSize={12}>
          {subtitle}
        </Typography>
      </Box>
      {!hasIcons ? (
        <Typography
          paddingLeft={0.2}
          fontWeight={'600'}
          fontSize={12}
          textAlign={'end'}
        >
          {rightValue}
        </Typography>
      ) : rightValue ? (
        <CheckCircle
          sx={{ fontSize: 33 }}
          style={{ color: palette.success.main }}
        />
      ) : (
        <Cancel sx={{ fontSize: 33 }} style={{ color: palette.error.main }} />
      )}
    </Box>
  );
};
