import { InterInstance } from '@api/config';
import { insurance } from '@utils/constants';

const business = "coppel-hdi"

export const getCPById = async (id: string, token: string) => {
  try {
    // /:business/:insurance/:zip
    const { data } = await InterInstance.get(`/catalogs/location/${business}/${insurance}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data.data;
  } catch (e) {
    return e;
  }
};

export const getCPByInsurance = async (
  id: string,
  insurance: string,
  token: string
) => {
  try {
    const { data } = await InterInstance.get(
      `/catalogs/location/${business}/${insurance}/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return data.data;
  } catch (e) {
    return e;
  }
};
