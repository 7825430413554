export const tConvert = (time: any) => {
  // Check correct time format and split into components
  let timeCheck = time
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (timeCheck.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
};

export const transformTime = (time: any) => {
  return time?.toString().split(' ')[4].split(':').slice(0, 2).join(':');
};

export const transformDate = (date: any) => {
  return date?.toISOString().split('T')[0].split('-').reverse().join('/');
};
