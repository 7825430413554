import React, { useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { useSelectedQuoteStore } from '@store/QuoteStore';
import { useGetQuotePayment } from '@store/QuoteData';
import { usePolicyStore } from '@store/policyStore';
import { Toats } from '@components/alerts/Toats';

interface Props {
  isMobile: boolean;
  isDesktop: boolean;
  icon: string;
  name: string;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
}
const paymentOptionsHDI = [
  {
    value: 'Mensual',
    label: 'M',
  },
  {
    value: 'Trimestral',
    label: '3M',
  },
  {
    value: 'Semestral',
    label: '6M',
  },
  {
    value: 'Anual',
    label: 'A',
  },
];
const paymentOptionsAll = [
  {
    value: 'Mensual',
    label: 'M',
  },
  {
    value: 'Trimestral',
    label: '3M',
  },
  {
    value: 'Semestral',
    label: '6M',
  },
  {
    value: 'Anual',
    label: 'A',
  },
];
export const OrderSelect: React.FC<Props> = ({
  isMobile,
  isDesktop,
  setShowToast,
  name,
}) => {
  const { selectedQuote } = useSelectedQuoteStore();
  const { Packages, payment_option, setPackage, setPaymentOption } =
    useGetQuotePayment();

  const [payment, setPayment] = React.useState(payment_option.value);
  const [packageId, setPackageId] = React.useState(Packages.value);
  const { policy, addPolicyInfo } = usePolicyStore();

  const changePayment = (e: any) => {
    setShowToast(true);
    if (!policy.error) {
      policy.payment_anterior = payment_option.value;
    }
    policy.payment_new = e.target.value;
    addPolicyInfo({ ...policy });
    if (e.target.value === 'M') {
      setPaymentOption('Mensual', 'M');
      return;
    } else if (e.target.value === '3M') {
      setPaymentOption('Trimestral', '3M');
      return;
    } else if (e.target.value === '6M') {
      setPaymentOption('Semestral', '6M');
      return;
    } else if (e.target.value === 'A') {
      setPaymentOption('Anual', 'A');
      return;
    }
  };

  const changePackage = (e: any) => {
    if (!policy.error) {
      policy.package_anterior = Packages.value;
    }
    policy.package_new = e.target.value;
    addPolicyInfo({ ...policy });
    if (e.target.value === 'AMP') {
      setPackage('Plan Amplio', 'AMP');
      return;
    } else if (e.target.value === 'RC') {
      setPackage('Plan Basico', 'RC');
      return;
    } else if (e.target.value === 'LIMIT') {
      setPackage('Plan Limitado', 'LIMIT');
      return;
    }
  };

  useEffect(() => {
    (async () => {
      policy.package_anterior = Packages.value;
      policy.package_new = Packages.value;
      policy.payment_anterior = payment_option.value;
      policy.payment_new = payment_option.value;
      addPolicyInfo({ ...policy });
    })();
  }, []);

  return (
    <Box
      alignItems="center"
      display={!isMobile ? 'flex' : 'block'}
      marginTop="10px"
    >
      <Box
        component="img"
        sx={{
          width: '100px',
        }}
        alt={name}
        style={{ borderRadius: 8, margin: '14px 30px' }}
        src={selectedQuote.company_logo}
      />

      <Box
        display={`${isMobile ? 'block' : 'flex'}`}
        width="100%"
        justifyContent="end"
      >
        <FormControl
          style={{
            width: `${isDesktop ? '55%' : '90%'}`,
            margin: `${isMobile ? '0px 15px 10px 15px' : '0px 20px'}`,
          }}
        >
          <InputLabel id="demo-simple-select-label">
            Modalidad de pago
          </InputLabel>
          <Select
            style={{ color: 'black' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select-label"
            value={policy.payment_new}
            label="Modalidad de pago"
            onChange={changePayment}
          >
            {selectedQuote.insurance == 'hdi' &&
              paymentOptionsHDI.map((value: any, i: number) => (
                <MenuItem key={i} value={value.label}>
                  {value.value}
                </MenuItem>
              ))}
            {selectedQuote.insurance != 'hdi' &&
              paymentOptionsAll.map((value: any, i: number) => (
                <MenuItem key={i} value={value.label}>
                  {value.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          style={{
            width: `${isDesktop ? '55%' : '90%'}`,
            margin: `${isMobile ? '0px 15px 10px 15px' : '0px 20px'}`,
          }}
        >
          <InputLabel id="demo-simple-select-label">Plan</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={policy.package_new}
            label="Modalidad de pago"
            onChange={changePackage}
          >
            {[
              { label: 'Amplio', value: 'AMP' },
              { label: 'Limitado', value: 'LIMIT' },
              {
                label: 'Responsabilidad Civil',
                value: 'RC',
              },
            ].map((value, i) => (
              <MenuItem key={i} value={value.value}>
                {value.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
