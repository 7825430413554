import { InterInstance } from '@api/config';

export const getVIN = async (id: string, token: string) => {
  try {
    const { data } = await InterInstance({
      method: 'get',
      url: '/emision/check-vin/' + id,
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (e) {
    return e;
  }
};
