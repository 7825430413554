import { useState } from 'react';
import { Box, useMediaQuery, useTheme, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { useStyles } from './useStyle';
import { NewQuotation } from '@components/Tab/NewQuotation';
import { AlreadyQuotation } from '@components/Tab/AlreadyQuotation';
import { useLocation } from 'react-router-dom';

interface Props {
  padding?: number;
  elevation?: number;
  isAbsolute: boolean;
  showInfo: Function;
  changeQuotation: Function;
}

const InfoContact = ({
  padding = 16,
  elevation = 12,
  isAbsolute = false,
  showInfo,
  changeQuotation
}: Props) => {
  // <--Style-->
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const classes = useStyles({ isMobile, isTablet, isDesktop, isAbsolute });
  const location = useLocation();

  const [value, setValue] = useState(
    location.pathname === '/quotation' ? '2' : '1'
  );

  const handleChange = (e: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    changeQuotation(newValue);
  };

  return (
    <Box className={classes.root}>
      <Box>
        <TabContext value={value}>
          <Box>
            <TabList
              aria-label="Tabs example"
              onChange={handleChange}
              indicatorColor="secondary"
              sx={{
                backgroundColor: '#F4F4F4',
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            >
              <Tab
                label="Nueva cotización"
                value="1"
                sx={{
                  backgroundColor: value === '1' ? '#FFFFFF' : '#F4F4F4',
                  textTransform: 'inherit',
                  width: '50%',
                  fontSize: 16,
                  padding: 0,
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: value === '1' ? 8 : 0,
                  color: '#8F8F8F',
                }}
              />
              <Tab
                label="Ya tengo una cotización"
                value="2"
                sx={{
                  backgroundColor: value === '2' ? '#FFFFFF' : '#F4F4F4',
                  textTransform: 'inherit',
                  width: '50%',
                  fontSize: 16,
                  padding: 0,
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  color: '#8F8F8F',
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: 0 }}>
            <NewQuotation
              padding={padding}
              elevation={elevation}
              isAbsolute={isAbsolute}
              showInfo={showInfo}
            />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 0 }}>
            <AlreadyQuotation
              padding={padding}
              elevation={elevation}
              isAbsolute={isAbsolute}
              showInfo={showInfo}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default InfoContact;
