import { Box } from '@mui/material';
import { CoverageForm } from '@screens/plans/form/CoverageForm';
import { HeaderPlans } from '@screens/plans/HeaderPlans';
import { Toats } from '@components/alerts/Toats';
import { useChangePrice } from '@hooks/useChangePrice';

import React, { useState, useEffect } from 'react';
import { Wrapper } from '@screens/plans/Wrapper';
import { useDriverStore } from '@store/driverInfoStore';
import { useHistory } from 'react-router-dom';
import { useCPStore } from '@store/cpStore';
import { useTokenStore } from '@store/TokenStore';

export const Plans: React.FC = () => {
  const [coverage, setCoverage] = useState<string>('Plan amplio');
  const [showToast, setShowToast] = useState(true);
  const { searchCP } = useCPStore();
  const history = useHistory();
  const { token } = useTokenStore();

  const { driver } = useDriverStore();
  const [zip, setZip] = useState<string>(driver.codigoPostal || '');

  useEffect(() => {
    window.scroll(0, 0);
    searchCP(zip, token);
  }, []);
  //useQuoteList();
  useChangePrice();

  if (driver.codigoPostal === '') {
    history.push('/options');
  }

  return (
    <Wrapper>
      <Toats
        showToast={showToast}
        setShowToast={setShowToast}
        Text="¡Ojo! Estos precios podrían cambiar. ¡Pero no te preocupes! Te avisaremos siempre que las aseguradoras hagan modificaciones."
        isWarning
      />
      <HeaderPlans />
      <Box marginTop={3}>
        <CoverageForm coverage={coverage} setCoverage={setCoverage} />
      </Box>
    </Wrapper>
  );
};
