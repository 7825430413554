import React, { Fragment } from 'react';

import Carousel from 'react-material-ui-carousel';
import { useMediaQuery, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { useStyles } from './SlideShow.styles';

import { HeroAction } from '../hero/HeroAction';
import { SlideShowItem } from '@models/ui/SlideShowItem';
import { SlideItem } from './SlideItem';

type SlideShowProps = {
  items: SlideShowItem[];
};

export const SlideShow: React.FC<SlideShowProps> = ({ items }) => {
  const classes = useStyles();

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  return (
    <div className={classes.root}>
      <div className={classes.background} />
      <div className={classes.firstOverlay} />
      <div className={classes.secondOverlay} />
      <Carousel
        swipe
        fullHeightHover
        indicators={false}
        className={classes.slideshow}
        indicatorContainerProps={{
          style: {
            bottom: isMobile ? 180 : isTablet ? 160 : 60,
            textAlign: 'left',
            marginLeft: isMobile ? '6.5vw' : '10vw',
            position: 'absolute',
            zIndex: 3,
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: '#FFF',
          },
        }}
        indicatorIconButtonProps={{
          style: {
            margin: '10px',
            color: 'transparent',
            border: '1px solid #FFF',
            padding: '0px',
          },
        }}
        NextIcon={<ChevronRight htmlColor={'#FFFFFF'} />}
        PrevIcon={<ChevronLeft htmlColor={'#FFFFFF'} />}
      >
        {items.map((item, i) => (
          <Fragment key={i}>
            <SlideItem item={item} />
            {!isMobile && (
              <HeroAction
                isAlt
                isAbsolute
                padding={16}
                elevation={12}
                title={'¿Cómo te llamas?'}
              />
            )}
          </Fragment>
        ))}
      </Carousel>
      {(isMobile || isTablet) && (
        <HeroAction
          isAlt
          isAbsolute
          padding={16}
          elevation={12}
          title={'¿Cómo te llamas?'}
        />
      )}
    </div>
  );
};
