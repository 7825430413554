import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Modal, Box, Paper, Backdrop, Fade } from '@mui/material';

import { useStyles } from './ModalPay.styles';

import { HeaderModalPay } from './HeaderModalPay';
import { PaymentMethods } from './PaymentMethods';
import { ButtonModalPay } from './ButtonModalPay';

type ModalPayProps = {
  onClose: () => void;
};

export const ModalPay: React.FC<ModalPayProps> = ({ onClose }) => {
  const [payWay, setPayWay] = useState<string>('');

  const history = useHistory();
  const classes = useStyles();

  const handleGoPayment = () => {
    onClose();
    history.push('/payment');
  };

  const isValidForm = () => {
    if (payWay) {
      return true;
    }
  };

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={2}>
            <HeaderModalPay onClose={onClose} />
            <PaymentMethods payWay={payWay} setPayWay={setPayWay} />
            <ButtonModalPay
              handleGoPayment={handleGoPayment}
              isValidForm={isValidForm}
            />
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
