import { useEffect, useContext } from 'react';

import { ZustandContext } from '@store/modalStore';
import { ModalTime } from '@modals/ModalHome/ModalTime';

export const useChangePrice = () => {
  const { show, hide } = useContext(ZustandContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      show(ModalTime, { onClose: hide });
    }, 60000 * 10);

    return () => clearTimeout(timer);
  });
};
