import { Box, Button, Typography } from '@mui/material';
import { useQuoteStore } from '@store/QuoteStore';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface PropsHeader {
  options: string;
}

const QuoteHeader: React.FC<PropsHeader> = ({ options }) => {
  const history = useHistory();
  const { quoteList } = useQuoteStore();
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      marginTop={3}
      padding={1}
      style={{
        marginInline: 'auto',
        borderBottom: '1px solid #8F8F8F',
      }}
    >
      <Typography
        textAlign={'start'}
        fontWeight={'bold'}
        fontSize={16}
        letterSpacing={'0.25px'}
      >
        {options}
        <span
          style={{ fontWeight: 'normal', marginLeft: '10px' }}
        >{`(${quoteList.length})`}</span>
      </Typography>
      <Button
        onClick={() => {
          history.push('/home');
        }}
        variant="outlined"
        style={{
          textTransform: 'none',
          marginRight: '15px',
          backgroundColor: '#FFFFFF',
          padding: '14px 19px 14px 19px',
          borderRadius: '10px',
        }}
      >
        Nueva cotización
      </Button>
    </Box>
  );
};

export default QuoteHeader;
