import React, { useContext } from 'react';
import { Paper, Box, Typography, Divider } from '@mui/material';

import { ModalBenefit } from '@modals/ModalBenefit';
import { CustomButton } from '@buttons/CustomButton';
import { ZustandContext } from '@store/modalStore';

export const CardTotalPrice: React.FC = () => {
  const { show, hide } = useContext(ZustandContext);
  return (
    <Paper elevation={0} style={{ borderRadius: 8, marginBottom: '20px' }}>
      <Box padding={2}>
        <Box>
          <Typography
            variant={'subtitle1'}
            style={{ fontWeight: 'bold' }}
            textAlign={'start'}
          >
            {'Total a pagar'}
          </Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginTop={2}
          marginBottom={2}
        >
          <Typography
            variant={'caption'}
            style={{ fontWeight: 'lighter' }}
            textAlign={'start'}
          >
            {'Póliza amplia'}
          </Typography>
          <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
            {'$12,163.00'}
          </Typography>
        </Box>
        <Divider />
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginTop={2}
        >
          <Typography
            variant={'caption'}
            style={{ fontWeight: 'lighter' }}
            textAlign={'start'}
          >
            {'Póliza amplia'}
          </Typography>
          <Typography variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
            {'$12,163.00'}
          </Typography>
        </Box>
        <Box marginTop={2}>
          <CustomButton
            text={'Contratar'}
            variant={'contained'}
            color={'primary'}
            onClick={() => show(ModalBenefit, { onClose: hide })}
            style={{ textTransform: 'none', width: '100%', height: 56 }}
          />
        </Box>
      </Box>
    </Paper>
  );
};
