import React from "react";
import {useParams} from "react-router-dom";
import {Box, Toolbar} from "@mui/material";
import {RenewPolicy} from "@screens/renovations/RenewPolicy";
import {UpdatePolicyRenewal} from "@screens/renovations/UpdatePolicyRenewal";
import {NotRenewPolicy} from "@screens/renovations/NotRenewPolicy";
import {ApplicationInProcess} from "@screens/renovations/ApplicationInProcess";

type UrlParams = {
    status:string;
}
export const Renovations: React.FC = () => {
    const {status:statusRenovaciones} = useParams<UrlParams>()


    const ValidateViewPage = (status:string) => {
        switch (status) {
            case "1":
                return <RenewPolicy/>
                break;
            case "2":
                return <NotRenewPolicy/>
                break;
            case "3":
                return <UpdatePolicyRenewal/>
                break;
            case "4":
                return <ApplicationInProcess/>
                break;
            default:
                return <ApplicationInProcess/>
        }
    }



    return (
        <Box sx={{
            marginTop: {xs: "75px", md: '80px'},
            marginBottom: {xs: "0px", md: '30px'},
            width: '100%', height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',

        }}>
            <Box sx={{
                padding: {xs: '0px 0px 0px 0px', md: '41px 150px 41px 150px'},
                flexDirection: 'column',
                display: 'flex', width: '100%'
            }}>
                {
                    ValidateViewPage(statusRenovaciones)
                }
            </Box>

        </Box>

    )
}
