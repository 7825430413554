import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { getQuoteCreated } from '@api/getQuoteCreated';
import { useCarStore } from '@store/carInfoStore';
import { useCPStore } from '@store/cpStore';
import { useDriverStore } from '@store/driverInfoStore';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useQuoteStore } from '@store/QuoteStore';
import { usePolicyStore } from '@store/policyStore';

export const useQuoteList = () => {
  const { setQuoteList, setIsFetching, quoteList } = useQuoteStore();
  const { driver } = useDriverStore();
  const { token } = useTokenStore();
  const {
    tracking: { id },
  } = useTrackingStore();

  const { car: carData } = useCarStore();
  const { cpData } = useCPStore();
  const { policy } = usePolicyStore();

  const fetchData = async (aseguradora: string) => {
    let data
    const tmpData = await getQuoteCreated(
      token,
      id,
      aseguradora,
      driver,
      carData,
      cpData[0],
      "AMP",
      policy.payment_option
    );
    if(tmpData && tmpData.data){
      data = tmpData.data.data
    }
    return [data];
  };

  const {
    data: hdi,
    isLoading: isLoadingHdi,
  } = useQuery(['quoteHdi', carData.vehicle_id], () => fetchData('hdi'), {
    staleTime: 600000, // 10 minute
    refetchInterval: 600000,
    refetchIntervalInBackground: true,
    enabled: !!carData.vehicle_id,
  });




  useEffect(() => {
    if (hdi) {
      const newQuote = quoteList.filter(
        //@ts-ignore
        (item) => item.insurance !== 'hdi'
      );

      if(hdi[0].available_packages[0].Descripcion === policy.package){
        hdi[0].insurance_premium.total = parseFloat(hdi[0]?.available_packages[0]?.net_premium)  + parseFloat(hdi[0]?.available_packages[0]?.right) + parseFloat(hdi[0]?.available_packages[0]?.tax)  + parseFloat(hdi[0]?.available_packages[0]?.discount);
      }

      if(hdi[0].available_packages[1].Descripcion === policy.package){
        hdi[0].insurance_premium.total = parseFloat(hdi[0]?.available_packages[1]?.net_premium) + parseFloat(hdi[0]?.available_packages[1]?.right) + parseFloat(hdi[0]?.available_packages[1]?.tax)  + parseFloat(hdi[0]?.available_packages[1]?.discount);
      }

      if(hdi[0].available_packages[2].Descripcion === policy.package){
        hdi[0].insurance_premium.total = parseFloat(hdi[0]?.available_packages[2]?.net_premium) + parseFloat(hdi[0]?.available_packages[2]?.right) + parseFloat(hdi[0]?.available_packages[2]?.tax)  + parseFloat(hdi[0]?.available_packages[2]?.discount);
      }
      setQuoteList([...newQuote, ...hdi]);
    }
  }, [hdi]);

  useEffect(() => {
    setIsFetching(
        isLoadingHdi
    );
  }, [
    isLoadingHdi
  ]);
};
