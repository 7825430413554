import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Box, Paper, Typography, Button } from '@mui/material';

import { Benefit } from '@models/ui/Benefit';
import { CustomButton } from '@buttons/CustomButton';

type BenefitDetailsProps = {
  benefit: Benefit;
  reset: () => void;
  style?: React.CSSProperties | undefined;
};

export const BenefitDetails: React.FC<BenefitDetailsProps> = ({
  reset,
  benefit,
  style = undefined,
}) => {
  const history = useHistory();
  return (
    <Grid item xs={12} md={3}>
      <Paper style={style} elevation={0}>
        <Box
          paddingY={2}
          paddingX={2.6}
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Box
            component={'img'}
            src={benefit.image}
            sx={{
              width: 124,
              height: 32,
            }}
            style={{ objectFit: 'contain' }}
            alt={benefit.title}
          />
          <Box paddingY={2}>
            <Button
              color={'primary'}
              variant={'text'}
              style={{
                padding: 0,
                fontSize: 14,
                textTransform: 'none',
                textDecoration: 'underline',
              }}
              onClick={reset}
            >
              {'Cambiar'}
            </Button>
          </Box>
          <Box
            style={{
              height: 1,
              opacity: 0.4,
              width: '100%',
              backgroundColor: '#C7C7C7',
            }}
          />
          <Box paddingY={2}>
            <Typography
              textAlign={'center'}
              variant={'body2'}
              marginBottom={0.4}
            >
              {'Costo anual:'}
            </Typography>
            <Typography textAlign={'center'} variant={'h4'} fontWeight={'bold'}>
              {benefit.totalPrice}
            </Typography>
          </Box>
          <CustomButton
            color={'primary'}
            text={'Personalizar'}
            variant={'contained'}
            onClick={() => history.push('/details')}
            style={{ width: '100%', height: 56, textTransform: 'none' }}
          />
          <Box paddingY={2} width={'100%'}>
            <Box
              padding={1.2}
              display={'flex'}
              flexDirection={'column'}
              style={{
                borderRadius: 8,
                backgroundColor: 'rgba(3, 158, 204, 0.1)',
              }}
            >
              <Typography variant={'caption'} marginBottom={0.4}>
                {'Promoción:'}
              </Typography>
              <Box style={{ width: 'auto' }}>
                <Typography noWrap fontWeight={'600'} variant={'body2'}>
                  {benefit.promotion}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};
