import React from 'react';
import { PaymentMethod } from './PaymentMethod';
import mastercard from '@assets/images/mastercard.png';
import visa from '@assets/images/visa.png';
import { Typography } from '@mui/material';

interface PaymentMethodsProps {
  payWay: string;
  setPayWay: Function;
}

export const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  payWay,
  setPayWay,
}) => {
  return (
    <>
      {/* <div style={{marginBottom: "22px", backgroundColor: '#BDCDD6', padding: '8px'}}>
        <Typography 
          variant='subtitle1' 
          style={{
            textAlign: 'center', 
            fontWeight: '700', 
            lineHeight: '1.5'
          }}
        >
          Por el momento sólo tenemos disponible pago con tarjeta de crédito. Próximamente habilitaremos el pago con tarjeta de débito.
        </Typography>
      </div> */}
      <div style={{marginBottom: "25px"}}>
        <PaymentMethod
          text="Tarjeta de débito"
          images={[mastercard, visa]}
          payWay={payWay}
          setPayWay={setPayWay}
          id="td"
        />
      </div>
      <PaymentMethod
        text="Tarjeta de crédito"
        images={[mastercard, visa]}
        payWay={payWay}
        setPayWay={setPayWay}
        id="tc"
      />
    </>
  );
};
