import React from 'react';

import { Box } from '@mui/material';
import { DataPrice } from './DataPrice';

interface FeaturesProps {
  isMobile: boolean;
  isTablet: boolean;
  logo: string;
  title: string;
  descripcion: string;
  titleTicket: string;
  numberTicket: string;
  feature: any;
}

export const Features: React.FC<FeaturesProps> = ({
  isMobile,
  isTablet,
  logo,
  title,
  descripcion,
  titleTicket,
  numberTicket,
  feature,
}) => {
  return (
    <Box>
      <DataPrice
        logo={logo}
        title={title}
        desc={descripcion}
        titleTicket={titleTicket}
        numberTicket={numberTicket}
        feature={feature}
      />
    </Box>
  );
};
