import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import norenovacion from "@assets/images/renovacion/norenovacion.png";

export const NotRenewPolicy: React.FC = () => {

    return (
        <Grid container columns={12} sx={{
            display:'flex',
            height:'100%',
            width:'100%',
            alignItems: 'center', justifyContent: 'center'}}>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex', width: '100%', height: {xs:'200px',md:'395px'},
                    flexDirection: 'column',
                }}>
                    <Box
                        component={'img'}
                        src={norenovacion}
                        sx={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                        }}
                        alt="norenovacion"
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex', width: '100%', height: {xs: '100%',sm:'500px',md:'500px'},
                    flexDirection: 'column',
                    marginBottom: {xs:0,md:10,lg:0},
                    backgroundColor: "#0043A5"
                }}>
                    <Box sx={{
                        flex: 1,
                        marginTop:{xs:5,md:0},
                        height:'100%',
                        display: 'flex', width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: {xs:5,sm:0},
                        flexDirection: {xs: 'column', sm: 'row'}
                    }}>
                        <Box sx={{
                            flex: 1, width: '100%',
                            justifyContent: 'center',
                            display: 'flex',
                            gap:5,
                            alignItems: 'center'
                        }}>
                            <Box component={'span'} sx={{
                                width: '80%',
                                alignItems: 'flex-start',
                                textAlign:'left',
                                color: "var(--White, #FFF)",
                                fontSize: "40px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "114%"
                            }}>
                                {"Lamentamos mucho que ya no renueves con nosotros"}
                            </Box>
                        </Box>

                        <Box  sx={{
                            flex: {xs: 0, sm: 1, md: 1},
                            padding: {xs: '30px 38px 30px 38px', sm: '40px 58px 40px 58px'},
                            width: '80%',

                        }}
                        >
                            <Typography
                                sx={{
                                    alignItems:'center',
                                    display:'flex',
                                    width:'100%',
                                    textAlign:{xs:"justify",sm:'left'}
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: `<div style="width:100%"><span style="color: white; font-size: 20px; font-weight: 400; line-height: 30px; word-wrap: break-word">Tu póliza de AutoProtección Coppel te cubrirá hasta el día </span><span style="color: white; font-size: 20px; font-weight: 700; line-height: 30px; word-wrap: break-word">21 de marzo del año en curso</span><span style="color: white; font-size: 20px; font-weight: 400; line-height: 30px; word-wrap: break-word">, después de esta fecha ya no será válida para siniestros o reclamaciones. Recuerda que si deseas asegurar otro vehículo, lo puedes hacer desde </span><span style="color: white; font-size: 20px; font-weight: 400; text-decoration: underline; line-height: 30px; word-wrap: break-word">https://aplicaciones.coppel.com</span><span style="color: white; font-size: 20px; font-weight: 400; line-height: 30px; word-wrap: break-word"> Opción: Seguros Colaboradores</span></div>`
                                }}/>

                        </Box>

                    </Box>


                    <Box sx={{
                        display:'flex',
                        backgroundColor:'#0043A5',
                        alignItems:'center',
                        justifyContent:'center',

                        padding: {xs: '30px 38px 80px 38px', sm: '40px 58px 60px 58px',md: '0px 58px 60px 58px',lg: '0px 58px 40px 58px'},
                    }}>
                        <Typography
                            sx={{
                                textAlign: {xs:'left',lg:'left',xl:'center'},
                                width: {xs:'100%',md:'80%',lg:'90%',xl:'100%'},
                            }}
                            dangerouslySetInnerHTML={{
                                __html: `<div style="width: 100%"><span style="color: white; font-size: 14px;  font-weight: 400; line-height: 21px; word-wrap: break-word">Si tienes alguna duda comunícate por teléfono al: </span><span style="color: white; font-size: 14px;  font-weight: 700; line-height: 21px; word-wrap: break-word">55 8979 0980</span><span style="color: white; font-size: 14px;  font-weight: 400; line-height: 21px; word-wrap: break-word"> o si lo prefieres escríbenos al correo: </span><span style="color: white; font-size: 14px;  font-weight: 700; line-height: 21px; word-wrap: break-word">solicitudescoppel@inter.mx</span><span style="color: white; font-size: 14px; font-weight: 400; line-height: 21px; word-wrap: break-word">. </span></div>`
                            }}/>
                    </Box>

                </Box>

            </Grid>

        </Grid>)
}
