import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';
import welcomeCheckGreen from '@assets/images/WelcomeCheckGreen.svg';
import { ZurichWelcome } from '../payment/ZurichWelcome';
import { usePolicyStore } from '@store/policyStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';
import React from 'react';

interface Props {
  isZurich?: boolean;
}

export const GoodWelcome = ({ isZurich }: Props) => {
  const { breakpoints, palette } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const { policy } = usePolicyStore();
  const { selectedQuote } = useSelectedQuoteStore();

  const handleRecibo = () => {
    if (policy.url_poliza) {
      window.open(policy.url_poliza, '_blank');
    }
  };

  if (isZurich) {
    return <ZurichWelcome />;
  }

  return (
    <Box
      padding={`${isMobile ? '0px 20px' : '30px'}`}
      marginTop={isTablet ? 2 : 0}
      marginBottom="45px"
      width={isTablet ? 'auto' : '60%'}
      style={{
        backgroundColor: 'white',
        borderRadius: '10px',
        margin: `${isMobile ? '0px 0px 60px 0px' : '30px auto 50px auto'}`,
      }}
      textAlign="center"
    >
      <Box
        component={'img'}
        src={welcomeCheckGreen}
        sx={{
          width: 80,
        }}
        alt="Bienvenido"
        style={{ objectFit: 'contain' }}
        margin="25px 0px 20px 0px"
      />
      <Typography
        style={{ fontWeight: 700 }}
        fontSize={`${isDesktop ? '28px' : '20px'}`}
      >
        Tu vehículo ya está asegurado
      </Typography>
      <Typography
        style={{
          fontWeight: 700,
          maxWidth: '600px',
          wordSpacing: '2px',
        }}
        fontSize="16px"
        margin="20px auto "
      >
        ¡Descarga tú póliza!
      </Typography>
      <Typography
        fontSize={`${isDesktop ? '16px' : '16px'}`}
        margin="8px 0px 20px 0px"
        fontWeight="400"
      >
        Recuerda guardar tú póliza y compartirla con tus contactos de emergencia.
      </Typography>
      
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box marginTop="23px">
         
          <Typography
            fontSize="16px"
            style={{
              maxWidth: '500px',
            }}
          >
            ¿Tienes dudas? Nosotros te ayudamos.
            Llama al siguiente número 
            <a href="tel:+525544246837">
              <CustomButton
                text="55 4424 6837"
                onClick={() => null}
                size="small"
                color={'inherit'}
                variant={'text'}
                style={{
                  color: palette.primary.main,
                  textTransform: 'none',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                }}
              />
            </a>
          </Typography>
        </Box>
         {selectedQuote.insurance === 'hdi' && (
          <Button
            variant="contained"
            onClick={handleRecibo}
            style={
              isDesktop
                ? {
                    width: '40%',
                    textTransform: 'none',
                    height: '56px',
                    marginTop: '45px',
                  }
                : {
                    width: '100%',
                    textTransform: 'none',
                    marginTop: '20px',
                    height: '56px',
                  }
            }
          >
            <Typography fontSize="16px" fontWeight={600}>
              ¡Descargar  póliza!
            </Typography>
          </Button>
          )}
      </Box>
    </Box>
  );
};
