import React, { Fragment, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Carousel from 'react-material-ui-carousel';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { SlideItem } from './SlideItem';
import { HeroAction } from '@screens/home/hero/HeroAction';
import InfoContact from '@screens/home/hero/infoContact/InfoContact';
import { SlideShowItem } from '@models/ui/SlideShowItem';
import { ModalHome } from '@modals/ModalHome/ModalHome';

type SlideShowProps = {
  items: SlideShowItem[];
};

const linear = `linear-gradient(47.96deg, #004876 3.8%, rgba(3, 158, 204, 0) 56.81%)`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: linear,
    // position: "absolute",
    // zIndex: 9
  },
  firstOverlay: {
    background: 'rgba(33, 33, 33, 0.4)',
  },
  secondOverlay: {
    background: '#D37946',
    backgroundBlendMode: 'soft-light',
  },
  background: {
    background: '#F5F9FC',
  },
  slideshow: {
    height: 950,
    marginBottom: 25,
    [theme.breakpoints.down('md')]: {
      height: 480,
      marginBottom: ({typeQuotation}: any) => typeQuotation === "1" ? 630 : 330
    },
    [theme.breakpoints.down('sm')]: {
      height: 600,
      marginBottom: ({typeQuotation}: any) => typeQuotation === "1" ? 520 : 220
    },
    [theme.breakpoints.down(473)]: {
      height: 600,
      marginBottom: ({typeQuotation}: any) => typeQuotation === "1" ? 505 : 205
    },
    [theme.breakpoints.down(391)]: {
      height: 600,
      marginBottom: ({typeQuotation}: any) => typeQuotation === "1" ? 528 : 228
    },
  },
}));

export const SlideShow: React.FC<SlideShowProps> = ({ items }) => {
  const [typeQuotation, setTypeQuotation] = useState<string>("1");

  const classes = useStyles({typeQuotation});

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  const [showInfoContact, setShowInfoContact] = useState<boolean>(true);
  const [showSearchCar, setShowSearchCar] = useState<boolean>(false);

  const changeTypeQuotation = (value: string) => {
    setTypeQuotation(value)
  }
  return (
    <div className={classes.root}>
      <div className={classes.background} />
      <div className={classes.firstOverlay} />
      <div className={classes.secondOverlay} />
      <Carousel
        swipe
        fullHeightHover
        indicators={false}
        className={classes.slideshow}
        indicatorContainerProps={{
          style: {
            bottom: isMobile ? 180 : isTablet ? 160 : 60,
            textAlign: 'left',
            marginLeft: isMobile ? '6.5vw' : '10vw',
            position: 'absolute',
            zIndex: 3,
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: '#FFF',
          },
        }}
        indicatorIconButtonProps={{
          style: {
            margin: '10px',
            color: 'transparent',
            border: '1px solid #FFF',
            padding: '0px',
          },
        }}
        NextIcon={<ChevronRight htmlColor={'#FFFFFF'} />}
        PrevIcon={<ChevronLeft htmlColor={'#FFFFFF'} />}
        autoPlay={false}
      >
        {items.map((item, i) => (
          <Fragment key={i}>
            <SlideItem item={item} />
          </Fragment>
        ))}
      </Carousel>
      {!isMobile &&
        (showInfoContact ? (
          //Esta es la que se muestra en movil
          <InfoContact isAbsolute changeQuotation={changeTypeQuotation} showInfo={setShowInfoContact} />
        ) : showSearchCar ? (
          <ModalHome isHeroAction />
        ) : (
          <HeroAction
            isAbsolute
            padding={16}
            elevation={12}
            title={'Datos del vehículo a asegurar'}
            setShowSearchCar={setShowSearchCar}
          />
        ))}
      {(isMobile || isTablet) &&
        (showInfoContact ? (
          <InfoContact isAbsolute changeQuotation={changeTypeQuotation} showInfo={setShowInfoContact} />
        ) : showSearchCar ? (
          <ModalHome isHeroAction />
        ) : (
          <HeroAction
            isAbsolute
            padding={16}
            elevation={12}
            title={'Datos del vehículo a asegurar'}
            setShowSearchCar={setShowSearchCar}
          />
        ))}
    </div>
  );
};
