import React, { createContext } from 'react';
import create from 'zustand';
import { persist } from 'zustand/middleware';

export interface ModalState {
  props: Object;
  component: React.ElementType | null;
}

export const initialState: ModalState = {
  props: {},
  component: null,
};

type ZustandContextProps = {
  hide: () => void;
  state: ModalState;
  show: (component: React.ElementType, props: Object) => void;
};

export const useModalStore = create<ZustandContextProps>(
  //@ts-ignore
  persist(
    (set) => ({
      state: {
        props: {},
        component: null,
      },
      hide: () => {
        set({
          state: {
            props: {},
            component: null,
          },
        });
      },
      show: (component, props) => {
        set({
          state: { props, component },
        });
      },
    }),
    {
      name: 'modal-storage',
      getStorage: () => sessionStorage,
    }
  )
);

export const ZustandContext = createContext({} as ZustandContextProps);

export const ZustandProvider = ({ children }: { children: JSX.Element }) => {
  const { state, hide, show } = useModalStore();

  return (
    <ZustandContext.Provider value={{ state, hide, show }}>
      {children}
    </ZustandContext.Provider>
  );
};

export const ZustandConsumer = () => (
  <ZustandContext.Consumer>
    {({ state: { component: Component, props } }) =>
      // @ts-ignore
      Component ? <Component {...props} /> : null
    }
  </ZustandContext.Consumer>
);
