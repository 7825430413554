import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

type CustomButtonProps = {
  text: string;
  disabled?: boolean;
  onClick: () => void;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  disableElevation?: boolean;
  size?: 'small' | 'medium' | 'large';
  style?: React.CSSProperties | undefined;
  variant?: 'text' | 'contained' | 'outlined';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
};

const useStyles = makeStyles({
  root: {
    borderRadius: 10,
    textTransform: 'none',
  },
});

export const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  style,
  onClick,
  endIcon = null,
  size = 'medium',
  disabled = false,
  variant = 'text',
  color = 'inherit',
  startIcon = null,
  disableElevation = true,
}) => {
  const classes = useStyles();
  return (
    <Button
      size={size}
      style={{ ...style, fontWeight: 'bold', borderRadius: 8 }}
      color={color}
      onClick={onClick}
      variant={variant}
      endIcon={endIcon}
      disabled={disabled}
      startIcon={startIcon}
      className={classes.root}
      disableElevation={disableElevation}
    >
      {text}
    </Button>
  );
};
