import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { usePolicyStore } from '@store/policyStore';
import { useDriverStore } from '@store/driverInfoStore';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
} from '@mui/material';

import { ZustandContext } from '@store/modalStore';
import { CustomButton } from '@buttons/CustomButton';
import { email } from '@api/email';
import { useTokenStore } from '@store/TokenStore';
import { useCarStore } from '@store/carInfoStore';
import { useSelectedQuoteStore } from '@store/QuoteStore';

type ModalShareProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 499,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalNext: React.FC<ModalShareProps> = ({ onClose }) => {
  const classes = useStyles();
  const { policy } = usePolicyStore();
  const { car: Car } = useCarStore();
  const { driver } = useDriverStore();
  const { show, hide } = useContext(ZustandContext);
  const { token } = useTokenStore();
  const history = useHistory();
  const { selectedQuote } = useSelectedQuoteStore();

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={3} paddingTop={3}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
            ></Box>
            <Box width={'95%'} style={{ marginTop: '-20px' }}>
              <Typography
                variant={'h6'}
                fontSize={'20px'}
                align = {'justify'}
                fontWeight={'bold'}
                marginTop={3}
              >
                {
                   driver.name || policy?.contrator?.firstName + ' esperamos que regreses pronto'
                }
              </Typography>
            </Box>
            <Box width={'95%'} style={{ marginTop: '30px' }}>
              <Typography
                variant={'h6'}
                align = {'justify'}
                fontSize={'20px'}
                marginTop={3}
              >
                {
                  'Te compartiremos tu número de cotización por correo para que puedas terminar tu solicitud más tarde?'
                }
              </Typography>
            </Box>
            <Box marginTop={6}>
              <CustomButton
                text={'¡De acuerdo!'}
                onClick={() => {
                  let qdata = {
                    "user_email": driver?.email,
                    "template_data": {
                      "first_name":driver?.name,
                      "quote_holder":driver?.name,
                      "quote_number":policy.policyNumber,
                      "vehicle_description":Car?.description,
                      "go_to": 'https://' + window.location.hostname + '/quotation'
                    }
                  }
                  email(token, qdata);
                  history.push('/inicio');
                  onClose();
                }}
                color={'primary'}
                variant={'contained'}
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
