import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

interface LabelconfirmProps {
  title: string;
  value: string;
}

export const Labelconfirm: React.FC<LabelconfirmProps> = ({ title, value }) => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      marginTop={3}
    >
      <Typography
        variant="subtitle2"
        style={{ fontWeight: 'normal', color: '#424242' }}
        textAlign="start"
        fontSize={isTablet ? 13 : 14}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle2"
        style={{ fontWeight: 'bold', color: '#424242' }}
        textAlign="start"
        fontSize={isTablet ? 13 : 14}
      >
        {value}
      </Typography>
    </Box>
  );
};
