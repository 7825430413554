interface Plazo {
  pagoUnico: number;
  semestral: number;
  trimestral: number;
  mensual: number;
}

export interface Calcular {
  total: string;
  plazos: string[];
}

interface Porcentajes {
  semestral: number;
  trimestral: number;
  mensual: number;
}

export const plazos: Plazo = {
  pagoUnico: 1,
  semestral: 2,
  trimestral: 4,
  mensual: 12,
};

const porcentajes: Porcentajes = {
  semestral: 0.02,
  trimestral: 0.03,
  mensual: 0.04,
};

export const numberWithCommas = (x: number) => {
  return `$ ${x.toFixed(2).toString()}`;
};

const resultadoPlazo = (
  costo: number,
  plazo: number,
  porcentaje: number
): string => {
  return numberWithCommas((costo / plazo) * (1 + porcentaje));
};

const resultadoPlazosList = (
  costo: number,
  plazo: number,
  porcentaje: number
): string[] => {
  return Array.from({ length: plazo }, (_, i) =>
    resultadoPlazo(costo, plazo, porcentaje)
  );
};

const calcularMonto = (costo: number, plazo: number): Calcular => {
  switch (plazo) {
    case plazos.pagoUnico:
      return {
        total: numberWithCommas(costo),
        plazos: [numberWithCommas(costo)],
      };
    case plazos.semestral:
      return {
        total: numberWithCommas(costo),
        plazos: resultadoPlazosList(costo, plazo, porcentajes.semestral),
      };
    case plazos.trimestral:
      return {
        total: numberWithCommas(costo),
        plazos: resultadoPlazosList(costo, plazo, porcentajes.trimestral),
      };
    case plazos.mensual:
      return {
        total: numberWithCommas(costo),
        plazos: resultadoPlazosList(costo, plazo, porcentajes.mensual),
      };
    default:
      return { total: '$0', plazos: ['$0'] };
  }
};

export const comparation = (costo: number, plazo: number) =>
  calcularMonto(costo, plazo);
