import React from 'react';
import { Grid, Box, useTheme, useMediaQuery } from '@mui/material';

import { CarInfo } from '@screens/result/details/CarInfo';
import { CardCalculator } from '@screens/details/cards/CardCalculator';
import abaLogo from '@assets/images/aba.png';
import { DetailsForm } from '@screens/details/form/DetailsForm';
import { CardPriceDetails } from '@screens/details/cards/CardPriceDetails';
import { Header } from '@navigationMenu/Header';
import { ListCoverDetails } from '@screens/details/list/List';
import { CardTotalPrice } from '@screens/details/cards/CardTotalPrice';

export const DetailsLayout: React.FC = () => {
  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  return (
    <Grid item>
      <Grid container paddingY={2} spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} lg={8} md={8}>
          <Box
            padding={3}
            borderRadius={2}
            style={{ backgroundColor: 'white' }}
          >
            <CardCalculator logo={abaLogo} value={1234566545} />
            <DetailsForm />
            <CardPriceDetails />
          </Box>
          <Box marginTop={3}>
            <Header title="Aumenta tu cobertura" />
          </Box>
          <Box
            padding={2}
            marginTop={3}
            borderRadius={2}
            style={{ backgroundColor: 'white' }}
          >
            <ListCoverDetails />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} md={4}>
          <CardTotalPrice />
          {isDesktop ? <CarInfo /> : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
