import './Modal.css';
import { CircularProgress } from '@mui/material';
import Modal from '@mui/material/Modal';
import automovil from '@assets/images/icono-coche.svg';

export default function Loading(props) {
  return (
    <Modal size="lg" open={props.open} onClose={props.handleClose}>
      <div className="modal-contenedor">
        <div className="modal-loading">
          <img
            className="logoCoche"
            src={automovil}
            width="200px"
            height="110px"
            alt=""
          />
          <CircularProgress
            size={110}
            sx={{
              color: '#2196f3',
              position: 'absolute',
              top: 0,
              zIndex: 1,
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
