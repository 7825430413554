import { Box, Grid, Paper } from '@mui/material';
import { Plan } from '@screens/plans/plan/Plan';
import { useState } from 'react';

import { useQuoteStore } from '@store/QuoteStore';

export const PlansLayout = () => {
  const [showToast, setShowToast] = useState<boolean>(true);

  const { quoteList } = useQuoteStore();

  return (
    <Grid item marginBottom={'40px'}>
      <Grid container>
        <Grid item xs={12} lg={12} md={12}>
          {quoteList.map((plan, i) => (
            <Plan
              key={i}
              plan={plan}
              showToast={showToast}
              setShowToast={setShowToast}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
