import { Tab } from '@mui/material';
import TabList from '@mui/lab/TabList';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { CarListData } from '@models/store/carListStore';
import { useStyles } from '@modals/ModalHome/useStyle';
import { years } from '@modals/ModalHome/Data';

interface Props {
  carList: CarListData[];
  setNotFound: React.Dispatch<React.SetStateAction<boolean>>;
  setYearFilter: React.Dispatch<React.SetStateAction<boolean>>;
  yearFilter: boolean;
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
  setYearSelected: React.Dispatch<React.SetStateAction<string>>;
}

const YearTab = ({
  carList,
  setNotFound,
  setYearFilter,
  yearFilter,
  handleChange,
  setYearSelected,
}: Props) => {
  const classes = useStyles();

  return (
    <TabList
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{
        style: {
          display: 'none',
          background: 'red !important',
        },
      }}
    >
      {/* @ts-ignore */}
      {years.map((year: number, index: number) => (
        <Tab
          key={index}
          // disabled={carList[0].description !== '' ? false : true}
          onClick={() => {
            setNotFound(false);
            setYearFilter(true);
            setYearSelected(year.toString());
          }}
          label={year}
          value={`${index}`}
          className={yearFilter ? classes.tabText : classes.notSelected}
          classes={{
            wrapped: classes.wrapped,
            root: classes.tabItemRoot,
            selected: yearFilter ? classes.selected : '',
          }}
        />
      ))}
      <Tab
        label={
          <>
            <WarningAmberIcon />
          </>
        }
        classes={{
          wrapped: classes.wrapped,
          root: classes.tabItemRoot,
          selected: classes.selected,
        }}
        onClick={() => setNotFound(true)}
      />
    </TabList>
  );
};

export default YearTab;
