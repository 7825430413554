import create from 'zustand';
import { persist } from 'zustand/middleware';
import { getQuoteCreated } from '@api/getQuoteCreated';

interface Coverage {
  code: string;
  sum_assured: string;
  name: string;
  deductive: number;
}

interface Receipts {
  number: number;
  start_date: string;
  end_date: string;
  net_premium: string;
  total_premium: string;
  right: string;
  recharge: string;
  tax: string;
  commission: string;
}

interface PaymentOptions {
  value: string;
  label: string;
}

interface Quote {
  insurance_premium: {
    tax: string;
    commission: string;
    net_premium: string;
    right: string;
    surcharge: string;
  };
  coverages_array: Coverage[];
  quote_id: string;
  company_logo: string;
  receipts: Receipts[];
  payment_options_json: PaymentOptions[];
  police_id: string;
}

interface QuoteStore {
  quoteList: Quote[] | [];
  quoteListReactQuery: Quote[] | [];
  isFetching: boolean;
  setIsFetching: (isFetching: boolean) => void;
  setQuoteList: (data: any[]) => void;
  // setQuoteListReactQuery: (data: any[]) => void;
}

interface SelectedQuoteStore {
  selectedQuote: any;
  setSelectedQuote: (selectedQuote: Quote) => void;
}

export const defaultQuote = {
  insurance_premium: {
    tax: '',
    commission: '',
    net_premium: '',
    right: '',
    surcharge: '',
  },
  coverages_array: [{
    code: '',
    sum_assured: '',
    name: '',
    deductive: 0,
  }],
  quote_id: '',
  company_logo: '',
  receipts: [{
    number: 0,
    start_date: '',
    end_date: '',
    net_premium: '',
    total_premium: '',
    right: '',
    recharge: '',
    tax: '',
    commission: '',
  }],
  payment_options_json: [{
    value: '',
    label: '',
  }],
  police_id: '',
}

export const useQuoteStore = create<QuoteStore>((set, get) => ({
  quoteList: [],
  quoteListReactQuery: [],
  isFetching: false,
  setIsFetching: (isFetching: boolean) => {
    set({ isFetching });
  },
  setQuoteList: (data: any) => {
    set({ quoteList: data });
  },
  // setQuoteListReactQuery: (data: any) => {
  //   set({ quoteList: data });
  // },
}));
export const useSelectedQuoteStore = create<SelectedQuoteStore>(
  //@ts-ignore
  persist(
    (set) => ({
      selectedQuote: {},
      setSelectedQuote: (selectedQuote: Quote) => {
        set({ selectedQuote });
      },
    }),
    {
      name: 'quote-storage',
      getStorage: () => sessionStorage,
    }
  )
);
