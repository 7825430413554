import React, { useState, useEffect } from 'react';
import { Grid, Paper, Box, Button, Divider, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useHistory } from 'react-router-dom';

import { usePlansDataStore } from '@store/plansStore';
import { Benefit } from '@models/ui/Benefit';
import { comparation, plazos, Calcular } from '@utils/comparationUtils';
import { Promotion } from '../item/Promotion';

type cardProps = {
  cardPlan: Benefit;
  pay: string;
  coverage: string;
};
export const CardComparation: React.FC<cardProps> = ({
  cardPlan,
  pay,
  coverage,
}) => {
  const history = useHistory();
  const { setSelectedPlan } = usePlansDataStore();

  const [comertialValue, setComertialValue] = useState<boolean>(false);
  const [payResults, setPayResults] = useState<Calcular>({
    total: '$0',
    plazos: ['$0'],
  });

  useEffect(() => {
    if (pay === 'De contado') {
      setPayResults(
        comparation(
          +cardPlan.totalPrice.replace(',', '').replace('$', ''),
          plazos.pagoUnico
        )
      );
    }
    if (pay === 'Semestral') {
      setPayResults(
        comparation(
          +cardPlan.totalPrice.replace(',', '').replace('$', ''),
          plazos.semestral
        )
      );
    }
    if (pay === 'Trimestral') {
      setPayResults(
        comparation(
          +cardPlan.totalPrice.replace(',', '').replace('$', ''),
          plazos.trimestral
        )
      );
    }
    if (pay === 'Mensual') {
      setPayResults(
        comparation(
          +cardPlan.totalPrice.replace(',', '').replace('$', ''),
          plazos.mensual
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pay]);

  const personalizar = () => {
    let newCoverage: 'Plan amplio' | 'Plan limitado' | 'Plan básico' =
      coverage === 'Plan amplio'
        ? 'Plan amplio'
        : coverage === 'Plan limitado'
        ? 'Plan limitado'
        : 'Plan básico';
    const newCardPlan = { ...cardPlan, plan: newCoverage };
    setSelectedPlan(newCardPlan);
    history.push('/insurer-details');
  };

  return (
    <>
      <Paper
        style={{
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            lg={12}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Box>
              <Box
                component={'img'}
                src={cardPlan.image}
                sx={{
                  height: 32,
                  width: 100,
                }}
                style={{ objectFit: 'contain' }}
                alt={cardPlan.title}
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'} width={'100%'}>
              <Button
                onClick={() => history.push('/plans')}
                variant={'text'}
                style={{
                  textDecoration: 'underline',
                  textTransform: 'none',
                }}
              >
                Cambiar
              </Button>
              <Divider />
            </Box>
            <Box
              marginTop={2}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Typography
                textAlign={'center'}
                variant={'caption'}
                fontSize={'13px'}
                style={{ color: '#757575' }}
              >
                {pay !== 'De contado' ? 'Primer pago de:' : 'Costo anual:'}
              </Typography>
              <Typography
                textAlign={'center'}
                variant={'caption'}
                fontSize={'28px'}
                fontWeight={'bold'}
              >
                {payResults.plazos[0]}
              </Typography>
              {pay !== 'De contado' ? (
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography
                    textAlign={'center'}
                    variant={'caption'}
                    fontSize={'13px'}
                    style={{ color: '#757575' }}
                  >
                    {pay === 'Semestral'
                      ? 'Más un pago de:'
                      : `Más ${payResults.plazos.length - 1} pagos de:`}
                  </Typography>
                  <Typography
                    textAlign={'center'}
                    variant={'caption'}
                    fontSize={'20px'}
                    fontWeight={'bold'}
                  >
                    {payResults.plazos[0]}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Box marginTop={2} width={'100%'}>
              <Button
                onClick={personalizar}
                fullWidth
                variant={'contained'}
                color={'primary'}
                style={{ textTransform: 'uppercase', height: '50px' }}
              >
                ¡Lo quiero!
              </Button>
            </Box>
            {pay !== 'De contado' ? null : <Promotion />}

            <Box display={'flex'} flexDirection={'column'} width={'100%'}>
              <Divider style={{ marginBottom: '16px' }} />
            </Box>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography
                textAlign={'center'}
                variant={'caption'}
                fontSize={'16px'}
                fontWeight={'bold'}
                style={{ color: '#212121' }}
              >
                {'Valor comercial'}
              </Typography>
              <InfoIcon
                onClick={() => setComertialValue(!comertialValue)}
                style={{
                  color: '#039ECC',
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
              />
            </Box>
            {comertialValue ? (
              <Box
                borderRadius={2}
                padding={2}
                width={'100%'}
                position={'relative'}
                style={{
                  backgroundColor: '#EBF8FB',
                }}
              >
                <Typography fontSize={'16px'} fontWeight={'bold'}>
                  {'Valor comercial'}
                </Typography>
                <Typography fontSize={'13px'} fontWeight={'normal'}>
                  {
                    'Cuando tu vehículo cumple un año de antigüedad, su valor cambia según la guía EBC (Libro Azul). Y con este nuevo valor es que se calculan los deducibles.'
                  }
                </Typography>
              </Box>
            ) : null}
            <Box>
              <Box marginTop={3}>
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontSize={'16px'}
                  fontWeight={'bold'}
                  style={{ color: '#424242' }}
                >
                  {'Deducible 5%'}
                </Typography>
              </Box>
              <Box marginTop={3}>
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontSize={'16px'}
                  fontWeight={'bold'}
                  style={{ color: '#424242' }}
                >
                  {'Deducible 5%'}
                </Typography>
              </Box>
            </Box>
            <Box marginTop={3}>
              <Typography
                textAlign={'center'}
                variant={'caption'}
                fontSize={'16px'}
                fontWeight={'bold'}
                style={{ color: '#424242' }}
              >
                {'$3,000,000'}
              </Typography>
            </Box>
            <Box marginTop={3}>
              <Typography
                textAlign={'center'}
                variant={'caption'}
                fontSize={'16px'}
                fontWeight={'bold'}
                style={{ color: '#424242' }}
              >
                {'$3,000,000'}
              </Typography>
            </Box>
            <Box
              marginTop={3}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <CheckCircleIcon
                style={{
                  color: 'rgba(90, 181, 47, 1)',
                  width: '33.33px',
                  height: '33.33px',
                }}
              />
            </Box>
            <Box marginTop={3} marginBottom={5}>
              <Typography
                textAlign={'center'}
                variant={'caption'}
                fontSize={'16px'}
                fontWeight={'bold'}
                style={{ color: '#424242' }}
              >
                {'3 Eventos al año'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {/* Se comenta esta parte ya que no aparece en figma pero no se elimina por si mas adelante se necesita */}
      {/* <Paper
        style={{
          padding: '10px',
          borderRadius: '10px',
          marginTop: '20px',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          padding={1}
        >
          <Typography
            textAlign={'center'}
            variant={'caption'}
            fontSize={'16px'}
            fontWeight={'bold'}
            style={{ color: '#424242' }}
          >
            {'Coberturas extras'}
          </Typography>
          <InfoIcon
            onClick={() => setExtraCoverage(!extraCoverage)}
            style={{ color: '#039ECC', cursor: 'pointer' }}
          />
        </Box>
        {extraCoverage ? (
          <Box
            padding={2}
            borderRadius={2}
            style={{ backgroundColor: '#EBF8FB' }}
          >
            <Typography fontSize={'16px'} fontWeight={'bold'}>
              {'Cobertura extra'}
            </Typography>
            <Typography fontSize={'13px'} fontWeight={'normal'}>
              {
                'Son coberturas adicionales a tu seguro que cubren circunstancias específicas con un precio especial.'
              }
            </Typography>
          </Box>
        ) : null}
        <Box>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                  style={{ color: '#424242' }}
                >
                  {'Autopartes'}
                </Typography>
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                  style={{ color: '#424242' }}
                >
                  {'$1,964.00'}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                textAlign={'center'}
                variant={'caption'}
                fontSize={'12px'}
                fontWeight={'normal'}
                style={{ color: '#424242' }}
              >
                {
                  'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto.'
                }
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                  style={{ color: '#424242' }}
                >
                  {'Rines'}
                </Typography>
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                  style={{ color: '#424242' }}
                >
                  {'$1,964.00'}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                textAlign={'center'}
                variant={'caption'}
                fontSize={'12px'}
                fontWeight={'normal'}
                style={{ color: '#424242' }}
              >
                {
                  'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto.'
                }
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                  style={{ color: '#424242' }}
                >
                  {'Vidrios'}
                </Typography>
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                  style={{ color: '#424242' }}
                >
                  {'$1,964.00'}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                textAlign={'center'}
                variant={'caption'}
                fontSize={'12px'}
                fontWeight={'normal'}
                style={{ color: '#424242' }}
              >
                {
                  'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto.'
                }
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                  style={{ color: '#424242' }}
                >
                  {'Auto sustituto'}
                </Typography>
                <Typography
                  textAlign={'center'}
                  variant={'caption'}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                  style={{ color: '#424242' }}
                >
                  {'$1,964.00'}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                textAlign={'center'}
                variant={'caption'}
                fontSize={'12px'}
                fontWeight={'normal'}
                style={{ color: '#424242' }}
              >
                {
                  'Cubre el daño provocado a vidrios a causa de fenomenos naturales, robo y/o asalto.'
                }
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper> */}
    </>
  );
};
