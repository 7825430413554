import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';

interface PropsWrapper {
  children: ReactNode[];
  // any props that come into the component
}

const QuoteWrapper: React.FC<PropsWrapper> = ({ children }) => {
  return (
    <Grid item xs={12}>
      {children}
    </Grid>
  );
};

export default QuoteWrapper;
