import React from 'react';
import { Grid, Paper, Box } from '@mui/material';

export const Empty: React.FC = () => {
  return (
    <Grid item xs={12} md={3}>
      <Paper
        elevation={0}
        style={{
          minHeight: '100%',
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Box sx={{ height: 'auto' }} />
      </Paper>
    </Grid>
  );
};
