import React from 'react';
import { Grid, Paper, Box, Typography } from '@mui/material';

export const Coverage: React.FC = () => {
  return (
    <Grid item xs={12} md={3}>
      <Paper
        elevation={0}
        style={{
          minHeight: '100%',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 8,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <Box sx={{ height: 'auto' }}>
          <Box
            height={64}
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            style={{ backgroundColor: 'rgba(94, 106, 113, 0.1)' }}
          >
            <Typography
              marginLeft={2.4}
              textAlign={'center'}
              variant={'h5'}
              fontWeight={'bold'}
            >
              {'Cobertura'}
            </Typography>
          </Box>
          <Box
            paddingY={2}
            paddingX={2.6}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
          >
            <Box paddingY={2}>
              <Typography fontWeight={'bold'} variant={'subtitle2'}>
                {'Daños materiales'}
              </Typography>
            </Box>
            <Box paddingY={2}>
              <Typography fontWeight={'bold'} variant={'subtitle2'}>
                {'Robo total'}
              </Typography>
            </Box>
            <Box paddingY={2}>
              <Typography fontWeight={'bold'} variant={'subtitle2'}>
                {'Responsabilidad civil'}
              </Typography>
            </Box>
            <Box paddingY={2}>
              <Typography fontWeight={'bold'} variant={'subtitle2'}>
                {'Gastos médicos'}
              </Typography>
            </Box>
            <Box paddingY={2.8}>
              <Typography fontWeight={'bold'} variant={'subtitle2'}>
                {'Defensa legal'}
              </Typography>
            </Box>
            <Box paddingY={2}>
              <Typography fontWeight={'bold'} variant={'subtitle2'}>
                {'Asistencia de viajes'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};
