import React from 'react';
import {
  Box,
  Container,
  useTheme,
  useMediaQuery,
  Typography,
  Button,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';
import Banner from '@assets/images/Banners_amazon_diciembre_coche_1140x300.jpg';
import BannerMobile from '@assets/images/banners_amazon_diciembre_coche_360x300.jpg';

export const PartnerBanner: React.FC = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  if (isTablet) {
    return (
      <Container maxWidth={'lg'}>
      <Box
        height={600}
        borderRadius={2}
        style={{
          backgroundImage: `url(${BannerMobile})`,
          backgroundSize: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
      </Box>
    </Container>
    );
  }
  return (
    <Container maxWidth={'lg'}>
      <Box
        height={300}
        borderRadius={2}
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
      </Box>
    </Container>
  );
};
