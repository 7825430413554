import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { useStyles } from './SlideItem.styles';

import { SlideShowItem } from '@models/ui/SlideShowItem';

type SlideItemProps = {
  item: SlideShowItem;
};

export const SlideItem: React.FC<SlideItemProps> = ({ item }) => {
  const { breakpoints, palette } = useTheme();
  const isMini = useMediaQuery(breakpoints.down('xs'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const classes = useStyles({ isMini, isMobile, isTablet, isDesktop });
  return (
    <Box className={classes.root}>
      <Box
        component={'img'}
        src={item.image}
        sx={{
          width: '100%',
        }}
        alt={item.title}
        className={classes.root}
        style={{ objectFit: 'cover' }}
      />
      <Box className={classes.info}>
        <Typography
          sx={{
            fontSize: {
              xl: 56,
              lg: 50,
              md: 42,
              sm: 34,
              xs: 30,
            },
          }}
          fontWeight={'bold'}
          color={palette.common.white}
        >
          {item.title}
          <span className={classes.complement}>{item.complement}</span>
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xl: 42,
              lg: 40,
              md: 32,
              sm: 22,
              xs: 16,
            },
          }}
          fontWeight={'normal'}
          color={palette.common.white}
        >
          {item.subtitle}
        </Typography>
      </Box>
    </Box>
  );
};
