import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { usePlansDataStore } from '@store/plansStore';
import { Calcular } from '@utils/comparationUtils';
import {
  useQuoteStore,
  useSelectedQuoteStore,
} from '../../../store/QuoteStore';

export const CodePrice = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  const { paymentMode } = usePlansDataStore();
  const { selectedQuote } = useSelectedQuoteStore();
  const [totalPayment, setTotalPayment] = useState<string>('');

  let position = 0
  if(selectedQuote.package === "AMP"){
    position = 0
  }
  if(selectedQuote.package === "LIMIT"){
    position = 1
  }
  if(selectedQuote.package === "RC"){
    position = 2
  }
  
  useEffect(() => {
    let totalNumber = 0;
    selectedQuote.available_packages[position].receipts.map((item: any) => {
      const number = Number(item?.total_premium);
      totalNumber += number;
    });
    setTotalPayment(totalNumber.toString());
  }, []);

  return (
    <Box
      style={{
        borderBottomStyle: 'dashed',
        borderBottom: '1px dashed #5E6A71',
      }}
      padding={2}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 'bold' }}
          textAlign="start"
          fontSize={16}
        >
          Detalle
        </Typography>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 'bold' }}
          textAlign="start"
          fontSize={isTablet ? 14 : 15}
        ></Typography>
      </Box>
      <Box
        marginTop={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 'normal' }}
          textAlign="start"
          fontSize={isTablet ? 14 : 15}
        >
          Total
        </Typography>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 'bold' }}
          textAlign="start"
          fontSize={isTablet ? 14 : 15}
        >
          $
          {parseFloat(
            parseFloat(totalPayment).toFixed(2)
          ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginTop={1}
      >
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 'normal' }}
          textAlign="start"
          fontSize={isTablet ? 14 : 15}
        >
          Modalidad de pago
        </Typography>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Typography
            variant="caption"
            style={{ fontWeight: 'bold' }}
            fontSize={isTablet ? 14 : 15}
          >
            {selectedQuote.payment_option === "M" ? "Mensual" : selectedQuote.payment_option === "3M" ? "Trimestral" : selectedQuote.payment_option === "6M" ? "Semestral" : "Contado"}
          </Typography>
        </Box>
      </Box>
      {selectedQuote.payment_option !== 'A' ? (
        <Payment
          paymentMode={paymentMode.type}
          payResults={{ total: paymentMode.total, plazos: paymentMode.plazos }}
        />
      ) : null}
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box
          width={30}
          height={30}
          borderRadius={100}
          left={isMobile ? -20 : isTablet ? 0 : '54%'}
          top={isMobile ? '77.3%' : isTablet ? '76.3%' : '58.3%'}
          position={'absolute'}
          style={{ backgroundColor: '#f5f5f5' }}
        />
        <Box
          width={30}
          height={30}
          borderRadius={100}
          left={isMobile ? '97%' : isTablet ? '96%' : '100.3%'}
          top={isMobile ? '77.3%' : isTablet ? '76.3%' : '58.3%'}
          position={'absolute'}
          style={{ backgroundColor: '#f5f5f5' }}
        />
      </Box>
    </Box>
  );
};

interface Props {
  paymentMode: string;
  payResults: Calcular;
}

export const Payment = ({ paymentMode, payResults }: Props) => {
  const { selectedQuote } = useSelectedQuoteStore();

  let position = 0
  
  if(selectedQuote.package === "AMP"){
    position = 0
  }
  if(selectedQuote.package === "LIMIT"){
    position = 1
  }
  if(selectedQuote.package === "RC"){
    position = 2
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      margin="10px 10px 20px 0px"
    >
      <Typography fontWeight="400" fontSize="14px">
        Primer pago de
        <ul
          style={{
            margin: 0,
            marginTop: '10px',
          }}
        >
          <li style={{ color: '#8F8F8F', fontWeight: 400 }}>
            {selectedQuote.payment_option === '6M'
              ? 'Más un pago de'
              : `Más ${ (selectedQuote.available_packages[position].receipts.length - 1) } pagos de`}
          </li>
        </ul>
      </Typography>
      <Typography fontWeight="600" fontSize="16px">
        <span style={{ marginLeft: '19px' }}>+ {`$${parseFloat(
          parseFloat(selectedQuote.available_packages[position].receipts[0].total_premium).toFixed(2)
        ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}`}</span>
        <ul
          style={{
            margin: 0,
            marginTop: '10px',
          }}
        >
          <li
            style={{
              color: '#8F8F8F',
              fontWeight: 400,
              fontSize: 14,
            }}
          >
            + {`$${parseFloat(
          parseFloat(selectedQuote.available_packages[position].receipts[1].total_premium).toFixed(2)
        ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}`}
          </li>
        </ul>
      </Typography>
    </Box>
  );
};
