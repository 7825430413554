import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Paper,
  Box,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import google from '@assets/images/GooglePlayLogo.svg';
import apple from '@assets/images/AppStoreLogo.svg';

type BenefitCardProps = {
  hasDivider?: boolean;
  style?: React.CSSProperties | undefined;
  item: {
    title: string;
    subtitle: string;
    image: string;
    extras: number;
  };
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ({ isTablet }: any) => (isTablet ? 'start' : 'center'),
    flexDirection: ({ isTablet }: any) => (isTablet ? 'row' : 'column'),
  },
});

export const BenefitCard: React.FC<BenefitCardProps> = ({
  item,
  style = undefined,
  hasDivider = false,
}) => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));

  const classes = useStyles({ isTablet });

  return (
    <Grid item xs={12} md={8} display={!isTablet ? 'flex' : ''}>
      <Paper
        elevation={0}
        style={
          isTablet
            ? {
                padding: isTablet ? 24 : 32,
                width: !isTablet ? '100%' : '',
                ...style,
              }
            : {
                padding: isTablet ? 24 : 32,
                borderRadius: 8,
                width: !isTablet ? '100%' : '',
              }
        }
      >
        <div className={classes.root}>
          <Box
            component={'img'}
            src={item.image}
            sx={{
              width: 64,
              height: 64,
            }}
            alt={item.title}
          />
          <div style={{ marginBottom: 10 }} />
          <div style={{ marginRight: isTablet ? 16 : 0 }} />
          <div
            style={{
              flexDirection: 'column',
              justifyContent: isTablet ? 'start' : 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xl: 30,
                  lg: 28,
                  md: 26,
                  sm: 24,
                  xs: 20,
                },
              }}
              fontWeight={'bold'}
              textAlign={isTablet ? 'start' : 'center'}
            >
              {item.title}
            </Typography>
            <div style={{ margin: 10 }} />
            <Typography
              maxWidth={item.extras === 1 ? '480px' : 'none'}
              sx={{
                fontSize: {
                  xl: 18,
                  lg: 16,
                  md: 16,
                  sm: 16,
                  xs: 16,
                },
              }}
              fontWeight={'400'}
              textAlign={isTablet ? 'start' : 'center'}
            >
              {item.subtitle}
            </Typography>
            {item.extras === 1 && (
              <Box
                display={'flex'}
                marginTop={2}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'center'}
                style={{ gap: '16px' }}
              >
                <Box
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=grut.inter.mx', '_blank')}
                  component={'img'}
                  src={google}
                  sx={{
                    width: '100px',
                  }}
                  alt={'Google play'}
                  style={{ objectFit: 'cover', borderRadius: 8, cursor: 'pointer' }}
                />
                <Box
                  onClick={() => window.open('https://apps.apple.com/mx/app/intermx/id1548367767?l=en', '_blank')}
                  component={'img'}
                  src={apple}
                  sx={{
                    width: '100px',
                  }}
                  alt={'Appstore'}
                  style={{ objectFit: 'cover', borderRadius: 8, cursor: 'pointer' }}
                />
              </Box>
            )}
          </div>
        </div>
      </Paper>
      {hasDivider && (
        <Box marginX={1}>
          {' '}
          <Divider />{' '}
        </Box>
      )}
    </Grid>
  );
};
