import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Box, useMediaQuery, Theme } from '@mui/material';

import { Header } from '@navigationMenu/Header';
import { ResultLayout } from '@screens/result/layout/ResultLayout';
import { useChangePrice } from '@hooks/useChangePrice';

const useStyles = makeStyles({
  root: {
    background: '#f5f5f5',
    paddingBottom: '40px',
  },
});

export const Result: React.FC = () => {
  const classes = useStyles();

  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  useEffect(() => {
    window.scroll(0,0);
  }, []);
  useChangePrice();

  return (
    <div className={classes.root}>
      <Container maxWidth={'xl'}>
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            marginTop: isTablet ? 140 : 100,
            minHeight: '70vh',
            position: 'relative',
          }}
        >
          <Header
            title={'¡Excelente!'}
            subtitle={'Hemos encontrado 8 opciones para ti'}
          />
          <ResultLayout />
        </Box>
      </Container>
    </div>
  );
};
