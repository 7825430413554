import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  SelectChangeEvent,
  useTheme,
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { data1, data2 } from './data';
import { useQuoteStore } from '@store/QuoteStore';
import { useGetQuotePayment } from '@store/QuoteData';
import { ExpandMore, Info } from '@mui/icons-material';
import ReactTooltip from 'react-tooltip';
import { useTokenStore } from '@store/TokenStore';
import { useTrackingStore } from '@store/TrackingStore';
import { useCarStore } from '@store/carInfoStore';
import { useCPStore } from '@store/cpStore';
import { ZustandContext } from '@store/modalStore';
import { useDriverStore } from '@store/driverInfoStore';
import { usePlansDataStore } from '@store/plansStore';
import { usePolicyStore } from '@store/policyStore';
import getAllByPackets from '@api/getAllByPackets';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  aseguradotooltip: {
    width: '25%',
  },
  plantooltip: {
    width: '25%',
  },
}));

interface DataInfo {
  title: string;
  description: string;
}

const dataInfo: DataInfo[] = [
  {
    title: 'Tipos de planes',
    description:
      'Existen 3 tipos de planes (Amplio, limitado y básico). Cada una te ofrece diferentes beneficios y costos.',
  },
  {
    title: 'Conductor asegurado',
    description:
      'Los datos del conductor asegurado nos permiten encontrar las mejores opciones para ti. Si los modificas, podrían cambiar las opciones.',
  },
];

interface PropsSelect {
  setCoverage: (coverage: string) => void;
  options?: string;
  setOptions: (options: string) => void;
}

const PlanSelect: React.FC<PropsSelect> = ({
  setCoverage,
  options,
  setOptions,
}) => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const classes = useStyles();
  const { Packages, setPackage } = useGetQuotePayment();
  const [packageId, setPackageId] = useState(Packages.label);

  const [Details, setDetails] = useState({
    Package: '',
    payment_option: 'A',
  });

  const [firstSelect] = useState(data1);
  const [secondSelect] = useState(data2);

  const { setQuoteList, setIsFetching, quoteList, isFetching } =
    useQuoteStore();
  const { token } = useTokenStore();
  const {
    tracking: { id },
  } = useTrackingStore();
  const {
    car: carData,
    car: { insurers },
  } = useCarStore();
  const { cpData } = useCPStore();
  const { show, hide } = useContext(ZustandContext);
  const { driver } = useDriverStore();
  const { car: carInfo } = useCarStore();
  const { plansData } = usePlansDataStore();
  const { policy, addPolicyInfo } = usePolicyStore();

  useEffect(() => {
    (async () => {
      setQuoteList([]);
      setIsFetching(true);
      var actualYear = new Date().getFullYear();
      if((actualYear - parseInt(carData.model)) > 20 && policy.basico){
        setPackage('Básico', 'RC');
        policy.package = 'RC';
        policy.payment_option = 'A';
        addPolicyInfo({...policy});
      }else {
        if(policy.package === 'AMP'){
          setPackage('Amplio', 'AMP');
        }else if(policy.package === 'LIMIT'){
          setPackage('Limitado', 'LIMIT');
        }else {
          setPackage('Básico', 'RC');
        }
      }
      const dataFilter = await getAllByPackets(
        token,
        id,
        driver,
        carData,
        cpData,
        policy.package,
        policy.payment_option
      );
      setQuoteList(dataFilter);
      setIsFetching(false);
    })();
  }, [Details]);

  const handleChange = async (event: SelectChangeEvent) => {

    setCoverage(event.target.value as string);
    const plan = event.target.value;
    if (plan === 'Amplio') {
      setDetails({
        Package: 'AMP',
        payment_option: 'A',
      });
      policy.basico =  false;
      policy.package = 'AMP';
      policy.payment_option = 'A';
      addPolicyInfo({...policy});
      setPackage('Amplio', 'AMP');
    }
    if (plan === 'Limitado') {
      setDetails({
        Package: 'LIMIT',
        payment_option: 'A',
      });
      policy.basico =  false;
      policy.package = 'LIMIT';
      policy.payment_option = 'A';
      addPolicyInfo({...policy});
      setPackage('Limitado', 'LIMIT');
    }
    if (plan === 'Básico') {
      setDetails({
        Package: 'RC',
        payment_option: 'A',
      });
      policy.basico =  false;
      policy.package = 'RC';
      policy.payment_option = 'A';
      addPolicyInfo({...policy});
      setPackage('Básico', 'RC');
    }
  };
  const handleChange2 = (event: SelectChangeEvent) => {
      policy.order = event.target.value as string;
      policy.stage = '1. Registro (Coche)';
      policy.sub_stage = '2. Datos Cotización';
      policy.state_code = 'Borrador';
      policy.status_code = 'En revisión';
      addPolicyInfo({...policy});
    setOptions(event.target.value as string);
    if (
      event.target.value === 'Todas las opciones' ||
      event.target.value === 'Las opciones más económicas'
    ) {
      quoteList.sort(
        (a, b) =>
          // @ts-ignore
          parseFloat(a.insurance_premium.total) -
          // @ts-ignore
          parseFloat(b.insurance_premium.total)
      );
    }
    if (event.target.value === 'Las opciones más costosas') {
      quoteList.sort(
        (a, b) =>
          // @ts-ignore
          parseFloat(b.insurance_premium.total) -
          // @ts-ignore
          parseFloat(a.insurance_premium.total)
      );
    }
  };

  return (
    <Grid
      item
      xl={3}
      style={{
        width: `${isTablet ? '100%' : '33%'}`,
        maxWidth: '364px',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        marginTop={0}
        justifyContent={'space-between'}
      >
        <Typography fontSize={14} fontWeight={'600'}>
          {'Estás viendo los planes en'}
        </Typography>
        <Info
          color="primary"
          data-for="test2"
          data-tip=""
          data-iscapture="true"
          sx={{ cursor: 'pointer' }}
        />
      </Box>
      <ReactTooltip
        id="test2"
        type="light"
        place="bottom"
        backgroundColor="#EBF8FB"
        className={classes.plantooltip}
      >
        <Box
          padding={2}
          marginTop={2}
          borderRadius={2}
          style={{ backgroundColor: '#EBF8FB', zIndex: 2 }}
        >
          <Typography fontSize={16} fontWeight={'bold'}>
            {dataInfo[0].title}
          </Typography>
          <Typography fontSize={13} fontWeight={'normal'}>
            {dataInfo[0].description}
          </Typography>
        </Box>
      </ReactTooltip>
      <Box>
        <Box marginTop={2}>
          <FormControl fullWidth>
            <InputLabel id="plan">Plan</InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              labelId="plan"
              label="Plan"
              value={
                Packages.label === 'Básico'
                  ? 'Responsabilidad Civil'
                  : Packages.label === 'Limitado'
                  ? 'Limitado'
                  : 'Amplio'
              }
              onChange={handleChange}
              className={classes.root}
              style={{ borderRadius: 8 }}
              IconComponent={(props) => (
                <ExpandMore style={{ color: '#039ECC' }} {...props} />
              )}
            >
              {firstSelect.map((value, i) => (
                <MenuItem key={i} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box marginTop={1}>
          <FormControl fullWidth>
            <InputLabel id="opciones">Opciones</InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              labelId="opciones"
              label="Opciones"
              value={options}
              onChange={handleChange2}
              className={classes.root}
              style={{ borderRadius: 8 }}
              IconComponent={(props) => (
                <ExpandMore style={{ color: '#039ECC' }} {...props} />
              )}
            >
              {secondSelect.map((value, i) => (
                <MenuItem key={i} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Grid>
  );
};

export default PlanSelect;
