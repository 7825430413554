import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { ExpandMore } from '@mui/icons-material';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  useTheme,
  useMediaQuery,
  Theme,
} from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}));

export const DetailsForm: React.FC = () => {
  const classes = useStyles();
  const [coverage, setCoverage] = useState<string>('Amplia');
  const [coverage2, setCoverage2] = useState<string>('De contado');

  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const handleChange = (event: SelectChangeEvent) => {
    setCoverage(event.target.value as string);
  };

  const handleChange2 = (event: SelectChangeEvent) => {
    setCoverage2(event.target.value as string);
  };

  return (
    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={isMobile ? 12 : 7}>
        <FormControl fullWidth>
          <InputLabel>Modalidad de pago</InputLabel>
          <Select
            value={coverage2}
            label="Modalidad de pago"
            onChange={handleChange2}
            className={classes.root}
            style={{ borderRadius: '8px' }}
            IconComponent={(props) => (
              <ExpandMore style={{ color: '#039ECC' }} {...props} />
            )}
          >
            {['De contado'].map((value, i) => (
              <MenuItem key={i} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={isMobile ? 12 : 7}>
        <FormControl fullWidth>
          <InputLabel>Cobertura</InputLabel>
          <Select
            value={coverage}
            label="Cobertura"
            onChange={handleChange}
            className={classes.root}
            style={{ borderRadius: 8 }}
            IconComponent={(props) => (
              <ExpandMore style={{ color: '#039ECC' }} {...props} />
            )}
          >
            {['Amplia', 'Limitada'].map((value, i) => (
              <MenuItem key={i} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
