import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';

import { SlideShowItem } from '@models/ui/SlideShowItem';
import FirstSlide from '@assets/images/firstBlueSlide.jpg';
import SecondSlide from '@assets/images/secondBlueSlide.jpg';
import { Plans } from '@screens/home/benefits/Plans';
import { Benefits } from '@screens/home/benefits/Benefits';
import { SlideShow } from '@screens/home/slideshow/SlideShow';
import { PartnersList } from '@screens/home/partners/PartnersList';
import backgroundCircle from '@assets/images/circles.png';
import { useIdStore } from '../store/UrlIdStore';

import { useTrackingStore } from '../store/TrackingStore';
import { useTokenStore } from '../store/TokenStore';
import { useParams } from 'react-router-dom';

const items: SlideShowItem[] = [
  {
    title: 'Compara y contrata tu seguro',
    image: FirstSlide,
    complement: ' en un solo lugar',
    subtitle: 'Fácil, rápido y seguro',
  },
  {
    title: 'Encuentra un seguro de auto, moto y pickup',
    image: SecondSlide,
  },
];

type UrlParams = {
  id: string;
}

const useStyles = makeStyles({
  root: {
    paddingBottom: '40px',
    backgroundImage: `url(${backgroundCircle})`,
    backgroundSize: '20%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundPositionY: '35%',
  },
});

export const Home: React.FC = () => {
  const { id: tmpId } = useParams<UrlParams>()
  const classes = useStyles();
  const history = useHistory();
  const { tracking, setDataTracking } = useTrackingStore();
  const { setToken, token } = useTokenStore();
  const { setId } = useIdStore();

  //Datalayer
  useEffect(() => {
    window.localStorage.clear();
    window.scroll(0, 0);
    setToken(token);
    setDataTracking(tracking);
  }, []);

  

  useEffect(()=>{
    if(tmpId){
      setId(tmpId)
      history.replace({pathname: '/'})
    }
  }, [])

  return (
    <div className={classes.root}>
      <SlideShow items={items} />
      <PartnersList />
      <Benefits />
      <Plans />
    </div>
  );
};
