import { InterInstance } from '@api/config';
import { generateDate } from '@utils/generateDate';
import { business } from '@utils/constants';
import { tConvert, transformDate } from '@utils/timeUtils';

//hay que refactorizar italo
interface IContact {
  name: string;
  telefono: string;
  email: string;
  genero: string;
  fechaNacimiento?: Date | null | string;
  codigoPostal: string;
}

interface IVehicle {
  vehicle_id: string;
  description: string;
  assembler: string;
  model: string | number;
  type: string;
  insurers: string[];
}

interface ICP {
  zip_code: string;
  state_code: string;
  state_name: string;
  suburb_code: string;
  suburb_name: string;
  city_name: string;
  township_code: string;
  township_name: string;
}

export const getQuoteCreated = async (
  token: string,
  tracking_id: string,
  insurance: string,
  contact: IContact,
  vehicle: IVehicle,
  CP: ICP,
  Package: string,
  payment_option: string
) => {
  const { startDate } = generateDate();
  const contact_gender = contact.genero === 'Mujer' ? 'F' : 'M';

  let contact_birth_date;

  if (contact.fechaNacimiento === 'object') {
    const date = (contact_birth_date = transformDate(contact.fechaNacimiento)
      .split('/')
      .reverse()
      .join('-'));

    contact_birth_date = date;
  } else {
    contact_birth_date = contact.fechaNacimiento?.toString().slice(0, 10);
  }

  const Quote = {
    tracking_id,
    business,
    insurance,
    contact_name: contact.name,
    contact_method: 'EMAIL',
    contact_phone: contact.telefono,
    contact_email: contact.email,
    contact_gender,
    contact_birth_date,
    contact_state_code: CP.state_code,
    contact_township_code: CP.township_code,
    contact_suburb_code: CP.suburb_code,
    contact_zip_code: CP.zip_code,
    contact_now: true,
    vehicle_id: vehicle.vehicle_id,
    vehicle_service: 'PARTICULAR',
    vehicle_use: 'NORMAL',
    vehicle_type: vehicle.type.toUpperCase(),
    payment_option,
    package: "AMP",
    promotion_code: '',
    associate_id: '',
    quote_date: startDate,
    start_at: startDate,
  };

  const result = await InterInstance.post('/quote', Quote, {
    headers: { Authorization: `Bearer ${token}` },
  }).catch(error => {});
  if(!result)
    return result
  const res = { ...result };
  res.data.data.insurance = insurance;
  res.data.data.vehicle = vehicle;
  if (insurance === 'mapfre'){
    res.data.data.insurance_premium.total = parseFloat(res.data.data?.insurance_premium?.net_premium)  + parseFloat(res.data.data?.insurance_premium?.right) + parseFloat(res.data.data?.insurance_premium?.surcharge) + parseFloat(res.data.data?.insurance_premium?.tax);
  }else if (insurance === 'qualitas'){
    res.data.data.insurance_premium.total = parseFloat(res.data.data?.insurance_premium?.net_premium)  + parseFloat(res.data.data?.insurance_premium?.right) + parseFloat(res.data.data?.insurance_premium?.surcharge) + parseFloat(res.data.data?.insurance_premium?.tax);
  }else if (insurance === 'hdi'){
    if(res.data.data.available_packages[0].Descripcion === Package){
      res.data.data.insurance_premium.total = parseFloat(res.data.data?.available_packages[0]?.net_premium) + parseFloat(res.data.data?.available_packages[0]?.right) + parseFloat(res.data.data?.available_packages[0]?.tax) + parseFloat(res.data.data?.available_packages[0]?.discount);
    }

    if(res.data.data.available_packages[1].Descripcion === Package){
      res.data.data.insurance_premium.total = parseFloat(res.data.data?.available_packages[1].net_premium) + parseFloat(res.data.data?.available_packages[1].right) + parseFloat(res.data.data?.available_packages[1].tax) + parseFloat(res.data.data?.available_packages[1].discount);
    }

    if(res.data.data.available_packages[2].Descripcion === Package){
      res.data.data.insurance_premium.total = parseFloat(res.data.data?.available_packages[2]?.net_premium) + parseFloat(res.data.data?.available_packages[2]?.right) + parseFloat(res.data.data?.available_packages[2]?.tax) + parseFloat(res.data.data?.available_packages[2]?.discount);
    }
  }else if (insurance === 'aba'){
    res.data.data.insurance_premium.total = parseFloat(res.data.data?.insurance_premium?.net_premium) + parseFloat(res.data.data?.insurance_premium?.commission) + parseFloat(res.data.data?.insurance_premium?.right) + parseFloat(res.data.data?.insurance_premium?.surcharge) + parseFloat(res.data.data?.insurance_premium?.tax) - parseFloat(res.data.data?.insurance_premium?.discount);
  }else if (insurance === 'aig' && payment_option !='A' ){
    res.data.data.insurance_premium.total = parseFloat(res.data.data?.insurance_premium?.net_premium) + parseFloat(res.data.data?.insurance_premium?.commission)  + parseFloat(res.data.data?.insurance_premium?.tax);
  } else {
    res.data.data.insurance_premium.total = parseFloat(res.data.data?.insurance_premium?.net_premium) + parseFloat(res.data.data?.insurance_premium?.commission) + parseFloat(res.data.data?.insurance_premium?.right) + parseFloat(res.data.data?.insurance_premium?.surcharge) + parseFloat(res.data.data?.insurance_premium?.tax);
  }
  res.data.data.package = Package;
  res.data.data.payment_option = payment_option;
  return { ...res };
};
