import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Paper, Box, Button, Typography } from '@mui/material';

import { Benefit } from '@models/ui/Benefit';
import { CustomButton } from '@buttons/CustomButton';

type BenefitMiniProps = {
  benefit: Benefit;
  reset: () => void;
  alignItems?: 'flex-start' | 'flex-end';
};

export const BenefitMini: React.FC<BenefitMiniProps> = ({
  benefit,
  reset,
  alignItems = 'flex-start',
}) => {
  const history = useHistory();

  return (
    <Grid item xs={6}>
      <Paper elevation={0} style={{ borderRadius: 0 }}>
        <Box
          paddingY={2}
          display={'flex'}
          alignItems={alignItems}
          flexDirection={'column'}
          paddingLeft={alignItems === 'flex-start' ? 2 : 0}
          paddingRight={alignItems === 'flex-start' ? 0 : 2}
        >
          <Box
            component={'img'}
            src={benefit.image}
            sx={{
              width: 142,
              height: 32,
            }}
            style={{ objectFit: 'contain' }}
            alt={benefit.title}
          />
          <Box paddingY={2}>
            <Button
              color={'primary'}
              variant={'text'}
              style={{
                padding: 0,
                fontSize: 14,
                textTransform: 'none',
                textDecoration: 'underline',
              }}
              onClick={reset}
            >
              {'Cambiar'}
            </Button>
          </Box>
          <Box
            style={{
              height: 1,
              opacity: 0.4,
              width: '100%',
              backgroundColor: '#C7C7C7',
            }}
          />
          <Box paddingY={2}>
            <Typography
              variant={'body2'}
              marginBottom={0.4}
              textAlign={alignItems === 'flex-start' ? 'start' : 'end'}
            >
              {'Costo anual:'}
            </Typography>
            <Typography
              variant={'h4'}
              fontWeight={'bold'}
              textAlign={alignItems === 'flex-start' ? 'start' : 'end'}
            >
              {benefit.totalPrice}
            </Typography>
          </Box>
          <CustomButton
            color={'primary'}
            text={'Personalizar'}
            variant={'contained'}
            onClick={() => history.push('/details')}
            style={{ width: '90%', height: 56, textTransform: 'none' }}
          />
        </Box>
      </Paper>
    </Grid>
  );
};
