import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Grid,
  Box,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

const data = [
  {
    detail: 'Daños materiales',
    value: 'Deducible 5%',
  },
  {
    detail: 'Robo total',
    value: 'Deducible 10%',
  },
  {
    detail: 'Responsabilidad civil',
    value: '3,000,000',
  },
  {
    detail: 'Gastos médicos',
    value: '1,000,000',
  },
  {
    detail: 'Defensa Legales',
    value: '500,000',
  },
  {
    detail: 'Asistencia en viajes',
    value: '500,000',
  },
];

export const CardPriceDetails: React.FC = () => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={isMobile ? 12 : 7}>
        <Paper
          elevation={0}
          style={{
            backgroundColor: '#e0f2f1',
          }}
        >
          <Box padding={1} marginTop={2}>
            <Grid item xs={12}>
              {data.map((i) => (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  marginTop={1}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    <InfoIcon style={{ color: '#2196f3' }} />
                    <Typography
                      variant={'caption'}
                      marginLeft={1}
                      style={{ fontWeight: 'normal', color: '#424242' }}
                      textAlign={'start'}
                    >
                      {i.detail}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant={'caption'}
                      marginLeft={1}
                      style={{ fontWeight: 'bold', color: '#424242' }}
                      textAlign={'start'}
                    >
                      {i.value}
                    </Typography>
                  </Box>
                </Box>
              ))}
              <Box marginTop={2}>
                <Typography
                  variant={'caption'}
                  marginLeft={1}
                  style={{ fontWeight: 'normal', color: '#424242' }}
                  textAlign={'start'}
                >
                  {'*Sobre valor comercial'}
                </Typography>
              </Box>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
