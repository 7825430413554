import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { PriceTablet } from './PriceTablet';
import { PriceDesktop } from './PriceDesktop';

export const BanerPrices = () => {
  const { breakpoints } = useTheme();

  const isTablet = useMediaQuery(breakpoints.down('md'));
  return <>{isTablet ? <PriceTablet /> : <PriceDesktop />}</>;
};
