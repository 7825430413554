import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
      borderRadius: 0,
      transform: 'translate(0%, 0%)',
    },
    top: '50%',
    left: '50%',
    width: 560,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  tabsRoot: {
    width: '100%',
  },
  tabsIndicator: {
    display: 'none',
  },
  tabsCentered: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabItemRoot: {
    height: 36,
    transition: 'all .5s',
    [theme.breakpoints.up('md')]: {
      minWidth: 120,
    },
    '&$selected': {
      borderRadius: 8,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  selected: {
    '&.css-1dn0bvo-MuiButtonBase-root-MuiTab-root.Mui-selected': {
      fontWeight: 700,
      color: theme.palette.common.white,
    },
    '&.css-l9elfg.Mui-selected': {
      fontWeight: 700,
      color: theme.palette.common.white,
    },
  },
  notSelected: {
    '&.css-1dn0bvo-MuiButtonBase-root-MuiTab-root.Mui-selected': {
      color: 'black',
      fontWeight: 500,
    },
  },
  wrapped: {
    lineHeight: '24px',
    textTransform: 'uppercase',
    fontSize: '12px',
    position: 'relative',
    display: 'block',
  },
  container: {
    [theme.breakpoints.down('md')]: {
      height: 500,
    },
    backgroundColor: '#F5F5F5',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  overflow: {
    // height: 348,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      // height: 560,
    },
  },
  delegate: {
    paddingTop: 16,
    paddingRight: 16,
    paddingLeft: 16,
  },
  listItem: {
    backgroundColor: 'white',
    borderRadius: '10px',
    marginTop: '10px',
  },
  tabText: {
    '&.MuiTab-textColorPrimary': {
      color: 'black !important',
    },
    '&.Mui-selected': {
      color: 'white !important',
    },
  },
  rootHeroAction: {
    [theme.breakpoints.down('md')]: {
      top: 250,
      left: 25,
      right: 0,
      width: '90%',
      height: 800,
    },
    zIndex: 1,
    top: 128,
    right: '10vw',
    width: 460,
    position: 'absolute',
  },
}));
