import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
const linear =
  'linear-gradient(47.96deg, #039ECC 3.8%, rgba(3, 158, 204, 0) 56.81%)';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: linear,
  },
  firstOverlay: {
    background: 'rgba(33, 33, 33, 0.4)',
  },
  secondOverlay: {
    background: '#D37946',
    backgroundBlendMode: 'soft-light',
  },
  background: {
    background: '#F5F9FC',
  },
  slideshow: {
    [theme.breakpoints.down('sm')]: {
      height: 600,
    },
    [theme.breakpoints.down('md')]: {
      height: 480,
    },
    height: 771,
  },
}));
