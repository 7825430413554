import { Box, Typography } from '@mui/material';

import alertSeachIcon from '@assets/images/AlertSearch.svg';

const NotFoundText = () => (
  <Box textAlign="center">
    <Box
      component="img"
      sx={{
        width: '50px',
      }}
      alt="Sin resultados"
      style={{
        borderRadius: 8,
      }}
      src={alertSeachIcon}
      margin="40px 0px 0px 0px"
    />
    <Typography fontSize="16px" fontWeight="700" margin="20px 0px 0px 0px">
      ¡Lo sentimos!
    </Typography>
    <Typography
      fontSize="14px"
      fontWeight="400"
      margin="10px 0px 0px 0px"
      padding="0px 10px"
    >
      Nuestra búsqueda no contempla autos con más de 20 años de antigüedad
    </Typography>
  </Box>
);

export default NotFoundText;
