import axios from 'axios';

const baseURL = 'https://api.inter.mx/v1';

export const InterInstance = axios.create({
  baseURL,
  headers: {
    AccessControlAllowOrigin: '*',
    AccessControlAllowMethods: 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});
