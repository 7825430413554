import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { useCarStore } from '@store/carInfoStore';
import { getVehicleType } from '@utils/vehicleType';
import { filterInsurances } from '@utils/filterInsurances';
import { useInsuranceStore } from '@store/insuranceStore';

interface BrandProps {
  isMobile: boolean;
  isTablet: boolean;
}

export const Brand: React.FC<BrandProps> = ({ isMobile, isTablet }) => {
  const { car } = useCarStore();
  const { setInsurances } = useInsuranceStore();
  const [insurancesFilter] = useState(filterInsurances(car));

  useEffect(() => {
    setInsurances(insurancesFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurancesFilter]);

  const vehicletype = car.type ? car.type : '';

  return (
    <div>
      <Box display="flex" justifyContent="center" marginBottom="10px">
        {!isMobile && (
          <Box
            padding={`${isMobile ? '8px 15px' : '20px 15px'}`}
            marginTop={isTablet ? 2 : 0}
            width={isTablet ? '100%' : '31%'}
            display="flex"
            justifyContent="space-between"
            flexDirection={'row'}
            alignItems={'center'}
            style={{
              maxWidth: '380px',
              boxSizing: 'border-box',
              backgroundColor: 'white',
              borderRadius: '10px',
              margin: `${isMobile ? '0px 0px 0px 0px' : '0px 0px 0px 0px'}`,
            }}
          >
            <Box
              component={'img'}
              src={getVehicleType(vehicletype)}
              marginLeft="21px"
              marginBottom="15px"
              marginTop="15px"
              marginRight="15px"
              sx={{
                width: 110,
                height: 44.15,
              }}
              alt="Coche"
              style={{ objectFit: 'contain' }}
            />
            <Box marginLeft="10px" textAlign="left" width={'100%'}>
              <Typography
                variant={'h6'}
                fontWeight={'bold'}
                style={{ fontSize: '14px' }}
              >
                {`${car.assembler}`}
              </Typography>
              <Typography
                fontSize={`${isMobile ? '12px' : '13px'}`}
                textAlign={'left'}
              >
                {`${car.description}, ${car.model}`}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
};
