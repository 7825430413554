import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: ({ isAbsolute, isMobile, isTablet, isDesktop }: any) => {
      return isAbsolute
        ? isMobile
          ? ''
          : isTablet
          ? ''
          : isDesktop
          ? 461
          : 461
        : '';
    },
    position: ({ isAbsolute }: any) => {
      return isAbsolute ? 'absolute' : 'relative';
    },
    left: ({ isMobile, isTablet, isDesktop, isAbsolute }: any) => {
      return isAbsolute
        ? isMobile
          ? '3vw'
          : isTablet
          ? '8vw'
          : isDesktop
          ? ''
          : ''
        : '';
    },
    top: ({ isMobile, isTablet, isDesktop, isAbsolute }: any) => {
      return isAbsolute
        ? isMobile
          ? 344
          : isTablet
          ? 334
          : isDesktop
          ? 108
          : 110
        : '';
    },
    right: ({ isMobile, isTablet, isAbsolute }: any) => {
      return isAbsolute ? (isMobile ? '3vw' : isTablet ? '8vw' : '10vw') : '';
    },
    zIndex: 3,
  },
  firstText: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 20,
    letterSpacing: -0.05,
  },
  title: {
    color: '#fff',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '22px',
    letterSpacing: '1px',
    paddingInlineStart: '16px',
    paddingInlineEnd: '25px',
  },
  blockSpace: {
    marginBlockStart: '31px',
    marginBlockEnd: '27px',
  },
  label: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#424242',
  },
  emailLabel: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#424242',
    marginTop: '20px',
  },
  labelSelect: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#424242',
    marginBlockEnd: '12px',
  },
});
