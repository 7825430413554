import create from 'zustand';
import { persist } from 'zustand/middleware';

export interface InsuranceState {
  mapfreId: string;
  qualitasId: string;
  gnpId: string;
  chubbId: string;
  axaId: string;
  primeroSegurosId: string;
  zurichId: string;
  hdiId: string;
}

interface Insurances {
  insurances: InsuranceState;
  setInsurances: (insurances: InsuranceState) => void;
}

export const initialState = {
  mapfreId: '',
  qualitasId: '',
  gnpId: '',
  chubbId: '',
  axaId: '',
  primeroSegurosId: '',
  zurichId: '',
  hdiId: '',
};

export const useInsuranceStore = create<Insurances>(
  persist(
    (set) => ({
      insurances: initialState,
      setInsurances: (insurances) => {
        set({ insurances });
      },
    }),
    {
      name: 'insurance-storage',
      getStorage: () => sessionStorage,
    }
  )
);
