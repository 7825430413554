import React from 'react';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import {
  Fade,
  Modal,
  Paper,
  Box,
  Backdrop,
  Theme,
  Typography,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';

type ModalShareProps = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    top: '50%',
    left: '50%',
    width: 499,
    borderRadius: 8,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ModalTime: React.FC<ModalShareProps> = ({ onClose }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Modal
      open
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <Paper className={classes.root}>
          <Box padding={3} paddingTop={3}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
            ></Box>
            <Box width={'95%'} style={{ marginTop: '-20px' }}>
              <Typography
                variant={'h6'}
                fontSize={'16px'}
                fontWeight={'bold'}
                marginTop={3}
                textAlign="center"
              >
                {
                  '¿Aún te interesa este plan o quieres ver otras opciones?'
                }
              </Typography>
            </Box>
            <Box marginTop={2}>
              <CustomButton
                text={'Continuar'}
                onClick={() => {
                  onClose();
                }}
                color={'primary'}
                variant={'contained'}
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              />
            </Box>
            <Box marginTop={2}>
              <CustomButton
                text={'Ver más opciones'}
                onClick={() => {
                  history.push('/insurer-details');
                  onClose();
                }}
                color={'primary'}
                variant={'outlined'}
                style={{ height: 56, width: '100%', textTransform: 'none' }}
              />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
