import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Grid,
} from '@mui/material';

import { CustomButton } from '@buttons/CustomButton';
import { ZustandContext } from '@store/modalStore';
import { ModalHome } from '@modals/ModalHome/ModalHome';

type HeroActionProps = {
  title?: string;
  padding?: number;
  elevation?: number;
  isAbsolute?: boolean;
};

const useStyles = makeStyles({
  root: {
    width: ({ isAbsolute, isMobile, isTablet, isDesktop }: any) => {
      return isAbsolute
        ? isMobile
          ? ''
          : isTablet
          ? ''
          : isDesktop
          ? 364
          : 320
        : '';
    },
    position: ({ isAbsolute }: any) => {
      return isAbsolute ? 'absolute' : 'relative';
    },
    left: ({ isMobile, isTablet, isDesktop, isAbsolute }: any) => {
      return isAbsolute
        ? isMobile
          ? '3vw'
          : isTablet
          ? '8vw'
          : isDesktop
          ? ''
          : ''
        : '';
    },
    top: ({ isMobile, isTablet, isDesktop, isAbsolute }: any) => {
      return isAbsolute
        ? isMobile
          ? 530
          : isTablet
          ? 520
          : isDesktop
          ? 148
          : 128
        : '';
    },
    right: ({ isMobile, isTablet, isAbsolute }: any) => {
      return isAbsolute ? (isMobile ? '3vw' : isTablet ? '8vw' : '10vw') : '';
    },
    zIndex: 3,
  },
});

export const HeroActionSimple: React.FC<HeroActionProps> = ({
  title = '',
  padding = 0,
  elevation = 0,
  isAbsolute = false,
}) => {
  const { breakpoints } = useTheme();
  const { show, hide } = useContext(ZustandContext);
  const isDesktop = useMediaQuery(breakpoints.up('lg'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const classes = useStyles({ isMobile, isTablet, isDesktop, isAbsolute });

  return (
    <Box className={classes.root}>
      <Paper elevation={elevation} style={{ borderRadius: 8 }}>
        <Box style={{ padding: padding }}>
          {title !== '' && (
            <Box marginBottom={3}>
              <Typography
                fontWeight={'bold'}
                sx={{
                  fontSize: {
                    xl: 16,
                    lg: 16,
                    md: 14,
                    sm: 24,
                    xs: 16,
                  },
                }}
              >
                {title}
              </Typography>
            </Box>
          )}
          <Box style={{ marginBottom: 10 }} />
          <Grid container spacing={0}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                label="Marca/Modelo"
                variant="outlined"
                defaultValue={'e.g. Mazda, Nissan'}
                onClick={() => show(ModalHome, { onClose: hide })}
                InputProps={{
                  style: {
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Año"
                variant="outlined"
                defaultValue={'2021'}
                onClick={() => show(ModalHome, { onClose: hide })}
                InputProps={{
                  style: {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: 8,
                    borderBottomRightRadius: 8,
                  },
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Box style={{ marginBottom: 16 }} />
          <Box style={{ marginBottom: 16 }} />
          <CustomButton
            text={'Buscar'}
            color={'primary'}
            variant={'contained'}
            endIcon={<ArrowForward />}
            style={{ width: '100%', height: 56, textTransform: 'none' }}
            onClick={() => show(ModalHome, { onClose: hide })}
          />
        </Box>
      </Paper>
    </Box>
  );
};
