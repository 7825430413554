import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography, TextField, Theme } from '@mui/material';
import { ExpandMore, Info, MailOutline, Edit } from '@mui/icons-material';

//import Assets
import warning from '@assets/images/warning.svg';
import { usePolicyStore } from '@store/policyStore';
import ReactTooltip from 'react-tooltip';

const useStyles = makeStyles((theme: Theme) => ({
  aseguradotooltip: {
    width: '25%',
    ['@media (max-width:780px)']: {
      width: '80%'
    }
  },
  plantooltip: {
    width: '25%',
    ['@media (max-width:780px)']: {
      width: '80%'
    }
  },
}));

export const PolicyNumber = () => {
  const [isShow, setIsShow] = useState(false);
  const { policy, addPolicyInfo } = usePolicyStore();
  const classes = useStyles();

  return (
    <Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent="space-between"
        // style={{ marginBottom: '5px' }}
      >
        <Typography fontWeight={'bold'} fontSize={'14px'} lineHeight={'24px'}>
          Número de cotización
        </Typography>
        <Info
          color="primary"
          data-for='noCoti'
          data-tip=""
          data-iscapture="true"
          sx={{ cursor: 'pointer' }}
        />
        <ReactTooltip id='noCoti' type="light" place="left" backgroundColor='#EBF8FB' className={classes.aseguradotooltip}>
          <Box
            padding={2}
            marginTop={2}
            borderRadius={2}
            style={{ backgroundColor: '#EBF8FB' }}
          >
            <Typography
              textAlign={'start'}
              fontWeight={'bold'}
              fontSize={16}
            >
            Número de cotización
            </Typography>
            <Typography
              textAlign={'start'}
              fontWeight={'normal'}
              fontSize={13}
            >
            ¡No pierdas este número! Te servirá para que, cuando quieras seguir tu proceso de contratación, no tengas que llenar todos tus datos otra vez.
            </Typography>
          </Box>
        </ReactTooltip>
      </Box>

      <TextField
        disabled
        fullWidth
        size="small"
        style={{ borderRadius: '10px ' }}
        value={policy.policyNumber}
        onChange={({ target: { value } }) =>
          addPolicyInfo({ ...policy, policyNumber: value })
        }
      />
    </Box>
  );
};
