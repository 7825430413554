export function generateDate() {
  const now = new Date();
  const year = now.getFullYear();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  const startDate = now.toISOString().split('T')[0];
  now.setFullYear(year + 1);
  const endDate = now.toISOString().split('T')[0];

  return { startDate, endDate };
}
