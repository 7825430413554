import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { getVehicleType } from '@utils/vehicleType';
import { ModalChangeVehicle } from '@modals/modalChangeVehicle';
import { useCarStore } from '@store/carInfoStore';
import { ZustandContext } from '@store/modalStore';
import React, { useContext } from 'react';

interface FullWidth {
  fullWidth?: boolean;
}

function CarSelectInfo(props: FullWidth) {
  const { breakpoints } = useTheme();
  const { car } = useCarStore();
  const { show, hide } = useContext(ZustandContext);
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const vehicletype = car.type ? car.type : '';

  return isTablet ? (
    <></>
  ) : (
    <Grid
      item
      lg={4}
      xl={4}
      style={{
        width: props.fullWidth ? '95%' : `33%`,
        maxWidth: props.fullWidth ? 'none' : '364px',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography fontSize={14} fontWeight={'600'}>
          {'Vehículo'}
        </Typography>
        <Edit
          color="primary"
          onClick={() => show(ModalChangeVehicle, { onClose: hide })}
          sx={{ cursor: 'pointer' }}
        />
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
        gap={2}
        marginTop={2}
        borderRadius={2}
        height={'80px'}
        padding={2.5}
        style={{ backgroundColor: 'white' }}
      >
        <Box
          component={'img'}
          src={getVehicleType(vehicletype)}
          sx={{
            width: '106px',
          }}
          alt={'Circle Background'}
          style={{ objectFit: 'cover', borderRadius: 8 }}
        />
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            variant={'caption'}
            fontSize={'14px'}
            style={{
              fontWeight: 'bold',
              color: '#424242',
            }}
            textAlign={'start'}
          >
            {`${car.assembler}`}
          </Typography>
          <Typography
            variant={'caption'}
            fontSize={'13px'}
            style={{
              fontWeight: 'normal',
              color: '#424242',
            }}
            textAlign={'start'}
          >
            {`${car.description}, ${car.model}`}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export default CarSelectInfo;
