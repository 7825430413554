import { Box, Typography } from '@mui/material';

import sadIcon from '@assets/images/SadIcon.svg';

const SearchNotFound = () => (
  <Box textAlign="center" margin="30px 0px">
    <Box
      component="img"
      sx={{
        width: '50px',
      }}
      alt="Sin resultados"
      style={{
        borderRadius: 8,
      }}
      src={sadIcon}
    />
    <Typography fontWeight="700" fontSize="16px" margin="20px 0px 0px 0px">
      Sin resultados
    </Typography>
  </Box>
);

export default SearchNotFound;
