import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

interface PropsWrapper {
  children: ReactNode[];
  // any props that come into the component
}

const SelectWrapperDesktop: React.FC<PropsWrapper> = ({ children }) => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  return (
    <Box
      display="flex"
      flexDirection={isTablet ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
      gap={isTablet ? 5 : 3}
      position="relative"
    >
      {children}
    </Box>
  );
};

export default SelectWrapperDesktop;
