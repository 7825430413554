import { CarListData } from '@models/store/carListStore';
import { InsuranceState, initialState } from '@store/insuranceStore';

export const filterInsurances = (car: CarListData) => {
  let insurances: InsuranceState = initialState;
  Object.entries(car).forEach(([key, value]) => {
    if (key.toLowerCase().includes('id')) {
      if (
        key !== 'id' &&
        key !== 'modelId' &&
        key !== 'assemblerId' &&
        key !== 'vehicletypeId'
      ) {
        insurances = { ...insurances, [key]: value };
      }
    }
  });
  return insurances;
};
