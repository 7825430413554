import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelectedQuoteStore} from '@store/QuoteStore';
import { usePolicyStore } from '@store/policyStore';

export const Coupons = () => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const { selectedQuote } = useSelectedQuoteStore();
  const [totalPayment, setTotalPayment] = useState<string>('');
  const { policy } = usePolicyStore();

  let position = 0
  
  if(selectedQuote.package === "AMP"){
    position = 0
  }
  if(selectedQuote.package === "LIMIT"){
    position = 1
  }
  if(selectedQuote.package === "RC"){
    position = 2
  }

  useEffect(() => {
    let totalNumber = 0;
    selectedQuote.available_packages[position].receipts.map((item: any) => {
      const number = Number(item?.total_premium);
      totalNumber += number;
    });
    setTotalPayment(totalNumber.toString());
  }, []);
  return (
    <Box padding={2}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 'bold' }}
          textAlign="start"
          fontSize={16}
        >
          Cupones
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop="14px"
        marginBottom="8px"
      >
        <Typography variant="subtitle1" textAlign="start" fontSize={16}>
        {policy.code}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop="14px"
        marginBottom="8px"
      >
        <Typography variant="subtitle1" textAlign="start" fontSize={16}>
          Total
        </Typography>
        <Typography
          variant="subtitle2"
          textAlign="start"
          style={{ fontWeight: 'bold' }}
          fontSize={isTablet ? 14 : 15}
        >
          $
          {parseFloat(
            parseFloat(totalPayment).toFixed(2)
          ).toLocaleString('es-MX', { minimumFractionDigits: 2 })}
        </Typography>
      </Box>
    </Box>
  );
};
