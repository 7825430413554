import React from 'react';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import { useHistory } from 'react-router';

import { CustomButton } from '@buttons/CustomButton';
import driver from '@assets/images/driver.png';
import womanDriver from '@assets/images/womanBlueDriver.png';
import { ZustandContext } from '@store/modalStore';
import { ModalHelp } from '@modals/ModalNeedHelp/ModalHelp';
import envelop from '@assets/images/Envelop.svg';
import phone from '@assets/images/phone.svg';
import whatsapp from '@assets/images/whatsapp.svg';

export const ErrorWelcome: React.FC = () => {
  const { breakpoints, palette } = useTheme();
  const { show, hide } = React.useContext(ZustandContext);

  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  const history = useHistory();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          paddingTop: 7,
          flexDirection: isTablet ? 'column' : 'row',
        }}
      >
        <Box
          component={'img'}
          src={womanDriver}
          sx={{
            width: isTablet ? 350 : 458,
          }}
          alt="Error"
          style={{ objectFit: 'contain' }}
        />
        <Box sx={{ width: 364, marginLeft: isTablet ? 0 : 4 }}>
          <Typography
            sx={{ fontWeight: 700, fontSize: 32, textAlign: 'center' }}
          >
            ¡Oops! Tuvimos un problema
          </Typography>
          <Typography
            sx={{
              fontSize: 20,
              textAlign: 'center',
              marginTop: 5,
              marginBottom: 7,
            }}
          >
            Lo sentimos tuvimos un problema con la conexión hacia la aseguradora
          </Typography>
          <CustomButton
            text={'INTENTÉMOSLO DE NUEVO'}
            onClick={() => {
              history.push('/confirm');
            }}
            size={isDesktop ? 'medium' : 'small'}
            variant={'contained'}
            color={'primary'}
            style={{
              textTransform: 'capitalize',
              fontSize: '13px',
              fontWeight: 600,
              padding: '13px 18.5px 13px 18.5px',
              width: '100%',
            }}
          />
        </Box>
      </Box>
      <Box style={{marginTop: '39.89px'}} />
      {/* <Typography
        sx={{
          textAlign: 'center',
          width: isTablet ? '350px' : '594px',
          marginTop: '39.89px',
        }}
      >
        ¿Necesitas que te echemos una manita o no sabes qué onda? Nosotros te
        ayudamos. Escríbenos o échanos un telefonazo de lunes a domingo de 8
        a.m. a 8 p.m.
      </Typography> */}
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ flexDirection: isTablet ? 'column' : 'row' }}
      >
        {/* <CustomButton
          text="55 8979 0980"
          onClick={() => null}
          size={isDesktop ? 'medium' : 'small'}
          variant={'text'}
          startIcon={
            <Box
              component={'img'}
              src={whatsapp}
              sx={{
                width: '24px',
                height: '24px',
              }}
              alt="Whatsapp"
            />
          }
          style={{
            color: palette.primary.main,
            fontSize: isMobile ? '0.64rem' : '15px',
            textTransform: 'none',
            marginBottom: '24px',
            marginTop: '24px',
          }}
        /> */}
        <CustomButton
          text="55 8979 0980"
          onClick={() => null}
          size={isDesktop ? 'medium' : 'small'}
          variant={'text'}
          startIcon={
            <Box
              component={'img'}
              src={phone}
              sx={{
                width: '24px',
                height: '24px',
              }}
              alt="Phone"
            />
          }
          style={{
            color: palette.primary.main,
            fontSize: isMobile ? '0.64rem' : '15px',
            textTransform: 'none',
            margin: '24px 81px',
          }}
        />
        <CustomButton
          text="solicitudescoppel@inter.mx"
          onClick={() => null}
          size={isDesktop ? 'medium' : 'small'}
          variant={'text'}
          startIcon={
            <Box
              component={'img'}
              src={envelop}
              sx={{
                width: '24px',
                height: '24px',
              }}
              alt="Phone"
            />
          }
          style={{
            color: palette.primary.main,
            fontSize: isMobile ? '0.64rem' : '15px',
            textTransform: 'none',
            marginBottom: '24px',
            marginTop: '24px',
          }}
        />
      </Box>
    </Box>
  );
};
