import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

const QuoteLoading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
      <Typography sx={{ marginLeft: 2 }}>
        Estamos buscando las mejores opciones
      </Typography>
    </Box>
  );
};

export default QuoteLoading;
