import React, { useState } from 'react';
import { CustomButton } from '@buttons/CustomButton';
import { ArrowForward } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledComponent } from '@emotion/styled';

type ModalAdditionalConfigProps = {
  openModal: boolean;
  setOpenModal: Function;
  setExpanded: Function;
  setAdditionalConfiguration: Function;
  setActive: Function;
};

const CustomSwitch: StyledComponent<SwitchProps> = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 54,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(28px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#039ECC',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#C4C4C4',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ModalAdditionalConfig: React.FC<ModalAdditionalConfigProps> = ({
  openModal,
  setOpenModal,
  setExpanded,
  setAdditionalConfiguration,
  setActive,
}) => {
  const [shouldBill, setShouldBill] = useState<boolean>(false);
  const [isDriverSameContractor, setIsDriverSameContractor] =
    useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenModal(false);
  };

  return (
    <Dialog
      open={openModal}
      maxWidth={'xs'}
      onClose={(e, reason) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick')
          handleCloseDialog();
      }}
    >
      <DialogTitle
        sx={{
          marginBottom: '1.5rem',
        }}
      >
        <Typography
          variant="h1"
          style={{ fontSize: '20px', fontWeight: '700' }}
        >
          Antes de terminar la info del contratante, ayúdanos con estas
          preguntas
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography
            style={{
              fontSize: '14px',
              fontWeight: '400',
              marginBottom: '0.6rem',
            }}
          >
            ¿Deseas facturar esta compra?
          </Typography>
        </DialogContentText>
        <Stack direction="row" spacing={1} alignItems="center" marginBottom={3}>
          <Typography style={{ fontSize: '16px', fontWeight: '400' }}>
            No
          </Typography>
          <CustomSwitch
            sx={{ m: 1 }}
            value={shouldBill}
            onChange={() => setShouldBill(!shouldBill)}
          />
          <Typography style={{ fontSize: '16px', fontWeight: '400' }}>
            Sí
          </Typography>
        </Stack>
        <DialogContentText>
          <Typography
            style={{
              fontSize: '14px',
              fontWeight: '400',
              marginBottom: '0.6rem',
            }}
          >
            ¿El conductor asegurado es la misma persona que el contratante?
          </Typography>
        </DialogContentText>
        <Stack direction="row" spacing={1} alignItems="center" marginBottom={3}>
          <Typography style={{ fontSize: '16px', fontWeight: '400' }}>
            No
          </Typography>
          <CustomSwitch
            sx={{ m: 1 }}
            value={isDriverSameContractor}
            onChange={() => setIsDriverSameContractor(!isDriverSameContractor)}
          />
          <Typography style={{ fontSize: '16px', fontWeight: '400' }}>
            Sí
          </Typography>
        </Stack>
        <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomButton
            color={'primary'}
            onClick={() => {
              setAdditionalConfiguration({
                shouldBill,
                isDriverSameContractor,
              });
              setActive({
                first: false,
                second: false,
                third: true,
              });
              setExpanded();
              handleCloseDialog();
            }}
            variant="contained"
            style={{
              textTransform: 'none',
              height: 56,
              width: '100%',
            }}
            text={'¡Listo!'}
            endIcon={<ArrowForward />}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAdditionalConfig;
