import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { CodeHeader } from './CodeHeader';
import { useCarStore } from '@store/carInfoStore';

export const CodeDetailts = () => {
  const { car } = useCarStore();

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));

  return (
    <Box
      style={{
        borderBottomStyle: 'dashed',
        borderBottom: '1px dashed #5E6A71',
      }}
      padding={1}
    >
      <CodeHeader />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="subtitle1"
          style={{ fontWeight: 600 }}
          textAlign="start"
          fontSize={isTablet ? 16 : 16}
          margin={`${!isMobile ? '25px 0px 0px 0px' : '0px 0px 0px 0px'}`}
        >
          Vehículo
        </Typography>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 'bold' }}
          textAlign="start"
          fontSize={isTablet ? 10 : 15}
        ></Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        margin={`${!isMobile ? '25px 0px 0px 0px' : '0px 0px 0px 0px'}`}
      >
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 'normal' }}
          textAlign="start"
          fontSize={isTablet ? 14 : 15}
          margin={0}
        >
          Marca/Modelo:
        </Typography>
        <Typography
          variant="subtitle2"
          style={{
            fontWeight: 'bold',
            width: '55%',
            maxWidth: '300px',
          }}
          textAlign="end"
          fontSize={isMobile ? 10 : 15}
        >
          {`${car.assembler}`}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" margin="0px 0px 15px">
        <Typography
          variant="caption"
          style={{ fontWeight: 'lighter', width: '55%', maxWidth: '300px' }}
          textAlign="end"
          fontSize={isMobile ? 10 : 15}
        >
          {`${car.description}`}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 'normal' }}
          textAlign="start"
          fontSize={isTablet ? 14 : 15}
        >
          Año:
        </Typography>
        <Typography
          variant="caption"
          style={{ fontWeight: 'bold' }}
          fontSize={isTablet ? 14 : 15}
        >
          {car.model}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box
          width={30}
          height={30}
          borderRadius={100}
          left={isMobile ? -20 : isTablet ? 0 : '54%'}
          top={isMobile ? '69.8%' : isTablet ? '68.8%' : '44.5%'}
          position={'absolute'}
          style={{ backgroundColor: '#f5f5f5' }}
        />
        <Box
          width={30}
          height={30}
          borderRadius={100}
          left={isMobile ? '97%' : isTablet ? '96%' : '100.3%'}
          top={isMobile ? '69.8%' : isTablet ? '68.8%' : '44.5%'}
          position={'absolute'}
          style={{ backgroundColor: '#f5f5f5' }}
        />
      </Box>
    </Box>
  );
};
