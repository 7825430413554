import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { BrandTablet } from './BrandTablet';
import { BrandDesktop } from './BrandDesktop';

export const BrandConfirm = () => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  return <>{isTablet ? <BrandTablet /> : <BrandDesktop />}</>;
};
