import create from 'zustand';
import { persist } from 'zustand/middleware';

import { getAssamblerList, getCarListApi } from '@api/getCar';
import { CarListData } from '@models/store/carListStore';

export const initialCarListData: CarListData = {
  vehicle_id: '',
  description: '',
  assembler: '',
  model: '',
  type: '',
  insurers: ['hdi'],
};

interface CarList {
  carList: CarListData[];
  assamblerList: string[];
  getCarList: (
    description: string,
    year: string,
    assembler: string,
    token: string
  ) => void;
  getAssemblerList: (token: string) => void;
  loading: boolean;
}

export const useCarListStore = create<CarList>(
  persist(
    (set, get) => ({
      carList: [initialCarListData],
      assamblerList: ['Toyota'],
      loading: true,
      getCarList: async (
        description: string,
        year: string,
        assembler: string,
        token: string
      ) => {
        const data: any = await getCarListApi(year, assembler, token);
        if(!data)
          return
        data.filter((item: any) => item.description.includes(description));
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const carList: CarListData[] =
          data.length === 0 ? [initialCarListData] : data;
        set({ carList });
      },
      getAssemblerList: async (token: string) => {
        const data: string[] = await getAssamblerList(token);
        const assamblerList: string[] = data as string[];

        set({ assamblerList });
      },
    }),
    {
      name: 'carList-storage',
      getStorage: () => sessionStorage,
    }
  )
);
